import React from "react";
import PropTypes from "prop-types";
import { useFilterLinkConfig } from "../../hooks/";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Event } from "../../components/Event";

import "./EventFilterGroup.scss";

const EventFilterGroup = ({
  eventList,
  eventExclude,
  groupConfig,
  favorites,
  tb_id,
  tb_user_plan,
  mode,
}) => {
  let filterConfigParams = useFilterLinkConfig(groupConfig.filters);
  const favoritesIds = favorites ? favorites : [];

  const checkFavorite = (eventId) => {
    return favoritesIds.includes(eventId);
  };
  const { t } = useTranslation();

  const filteredEventList = eventList
    ? eventList.filter((event) => {
        return event.id !== eventExclude;
      })
    : [];

  let groupStyle = "";
  groupStyle = mode === "featured" ? "featureCol" : groupStyle;
  groupStyle = mode === "bottomborder" ? "bottomBorder" : groupStyle;
  groupStyle = mode === "noborder" ? "noBorder" : groupStyle;

  let xsWidth = 12;
  let mdWidth = 6;
  let lgWidth = 6;
  let xlWidth = 3;

  if (groupConfig.countAcross && groupConfig.countAcross > 0) {
    switch (groupConfig.countAcross) {
      case 3:
        xlWidth = 4;
        break;
      default:
        break;
    }
  }

  const seeMoreTitle = groupConfig.seeAllTitle
    ? groupConfig.seeAllTitle
    : groupConfig.title;
  const cardStyle = groupConfig.eccCardType;
  let seeMoreLink = !groupConfig.jumplink;

  if (cardStyle === "eccondemandsessions") {
    seeMoreLink = !groupConfig.jumplink
      ? {
          pathname: "/events",
          search: filterConfigParams,
        }
      : {
          pathname: "/search",
          search: groupConfig.jumplink,
        };
  } else {
    seeMoreLink = !groupConfig.jumplink
      ? {
          pathname: "/events",
          search: filterConfigParams,
        }
      : {
          pathname: groupConfig.jumplink,
        };
  }

  const showEventDescription = groupConfig.showDescription;

  return (
    <div className="colPanel">
      <SkeletonTheme color="#ccc" highlightColor="#ddd">
        <div className={`filterGroup ${groupStyle}`}>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h3 className="float-left groupHeader">{t(groupConfig.title)}</h3>
              {(mode === "default" || groupConfig.jumplink) && (
                <div className="float-right groupLink">
                  <Link to={seeMoreLink} className="d-none d-md-block">
                    {cardStyle === "eccondemandsessions"
                      ? t(seeMoreTitle)
                      : t("SeeAllLink", { linkTitle: t(seeMoreTitle) })}
                  </Link>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {filteredEventList && filteredEventList.length > 0
              ? filteredEventList.slice(0, 4).map((event, index) => (
                  <Col
                    xs={xsWidth}
                    md={mdWidth}
                    lg={lgWidth}
                    xl={xlWidth}
                    key={event.id}
                  >
                    <Event
                      event={event}
                      key={event.id}
                      {...event}
                      tb_id={tb_id}
                      tb_user_plan={tb_user_plan}
                      cardStyle={cardStyle}
                      showDescription={showEventDescription}
                      isFavorite={() => checkFavorite(event.id)}
                    />
                  </Col>
                ))
              : [1, 2, 3, 4].map((event, index) => (
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    xl={4}
                    key={index}
                    className={index === 3 ? "d-lg-none" : ""}
                  >
                    <Skeleton
                      key={"skeleton-card-" + index}
                      className="skeletonCard"
                      height={260}
                      duration={3}
                    />
                  </Col>
                ))}
            <Col className="d-md-none">
              {(mode === "default" || groupConfig.jumplink) && (
                <Link to={seeMoreLink} className="btn btn-secondary">
                  {cardStyle === "eccondemandsessions"
                    ? t(seeMoreTitle)
                    : t("SeeAllLink", { linkTitle: t(seeMoreTitle) })}
                </Link>
              )}
            </Col>
          </Row>
        </div>
      </SkeletonTheme>
    </div>
  );
};

EventFilterGroup.propTypes = {
  eventList: PropTypes.array,
};

export default EventFilterGroup;
