import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import csEvent from "./event";
import csUser from "./user";
import csHub from "./hub";
import csOther from "./other";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    csEvent,
    csUser,
    csHub,
    csOther
  });
export default createRootReducer;
