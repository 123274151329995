/* eslint require-yield: 0 */
import { replace } from "connected-react-router";

import { put, takeEvery, takeLatest, select, delay } from "redux-saga/effects";
import { EventActions } from "../actions";
import csFetch from "./api";

const {
  LOAD_EVENTS_LIST,
  RENDER_EVENTS_LIST,
  UPDATE_EVENT_FILTERS,
  TOGGLE_PREMIER_EXCLUDE,
  LOAD_MORE_EVENTS,
  LOAD_MORE_EVENTS_SUCCEED,
  LOAD_EVENT_DETAIL,
  LOAD_EVENT_DETAIL_SUCCEED,
  LOAD_EVENT_DETAIL_NOTFOUND,
  LOAD_EVENT_FILTERGROUP,
  LOAD_EVENT_FILTERGROUP_SUCCEED,
  LOAD_PRODUCT_TOPICGROUP,
  LOAD_PRODUCT_TOPICGROUP_SUCCEED,
  LOAD_FEATUREDEVENTS,
  LOAD_UPCOMINGEVENTS,
  LOAD_UPCOMINGEVENTS_SUCCEED,
  LOAD_FEATUREDEVENTS_SUCCEED,
  LOAD_RECURRINGEVENTS,
  LOAD_RECURRINGEVENTS_SUCCEED,
  LOAD_PARENTEVENT,
  LOAD_PARENTEVENT_SUCCEED,
  LOAD_PARENTEVENT_NOTFOUND,
  LOAD_PARENTSESSIONS,
  LOAD_PARENTSESSIONS_SUCCEED,
  SET_REGISTRATION_EVENT_INFO,
  SET_REGISTRATION_EVENT_INFO_SUCCEED,
  SET_REGISTRATION_EVENT_INFO_FAILED,
  SET_REGISTRATION_EVENT_CANCEL,
  SET_REGISTRATION_EVENT_CANCEL_SUCCEED,
  LOAD_EVENT_DETAIL_USER_REGISTRATION,
  LOAD_EVENT_DETAIL_USER_REGISTRATION_SUCCEED,
  LOAD_ONDEMANDEVENTS,
  LOAD_ONDEMANDEVENTS_SUCCEED,
} = EventActions;

export const getEventFilters = (state) => state.csEvent.eventFilters;
export const getTotalPages = (state) => state.csEvent.totalPages;
export const getExcludePremier = (state) => state.csEvent.excludePremier;

export const getUserData = (state) => state.csUser.user;

export function* fetchEventsList() {
  // delay to allow fetchLatest / debouncing
  yield delay(300);

  let eventFilters = yield select(getEventFilters);

  let excludePremierFlag = yield select(getExcludePremier);

  let eventFilterPayload = {
    ...eventFilters,
    excludePremier: excludePremierFlag,
  };

  let filterUrl = "?filter=true";
  filterUrl +=
    "&product=" +
    (eventFilters.product ? encodeURIComponent(eventFilters.product) : "");
  filterUrl +=
    "&category=" +
    (eventFilters.category ? encodeURIComponent(eventFilters.category) : "");
  filterUrl +=
    "&region=" +
    (eventFilters.region ? encodeURIComponent(eventFilters.region) : "");
  filterUrl +=
    "&language=" +
    (eventFilters.language ? encodeURIComponent(eventFilters.language) : "");
  filterUrl +=
    "&type=" + (eventFilters.type ? encodeURIComponent(eventFilters.type) : "");
  filterUrl +=
    "&search=" +
    (eventFilters.search ? encodeURIComponent(eventFilters.search) : "");
  filterUrl +=
    "&startDate=" +
    (eventFilters.startDate ? eventFilters.startDate.unix() : "");
  filterUrl +=
    "&endDate=" + (eventFilters.endDate ? eventFilters.endDate.unix() : "");
  filterUrl += "&dateSpan=" + eventFilters.dateSpan;

  // replace history with bookmark link per filter set
  yield put(replace("/events" + filterUrl));
  const { status, data } = yield csFetch("events", "POST", eventFilterPayload);
  if (status === 200) {
    yield put({
      type: RENDER_EVENTS_LIST,
      eventList: data.events,
      eventListCurrent: "default",
      totalEvents: data.totalEvents,
      totalPages: data.totalPages,
    });
  }
}

export function* loadMoreEventsList() {
  let eventFilters = yield select(getEventFilters);
  let totalPages = yield select(getTotalPages);

  let excludePremierFlag = yield select(getExcludePremier);

  let eventFilterPayload = {
    ...eventFilters,
    excludePremier: excludePremierFlag,
  };

  if (totalPages >= eventFilters.page) {
    const { status, data } = yield csFetch(
      "events",
      "POST",
      eventFilterPayload
    );
    if (status === 200) {
      yield put({
        type: LOAD_MORE_EVENTS_SUCCEED,
        newEventList: data.events,
        totalEvents: data.totalEvents,
      });
    }
  }
}

export function* loadEventFilterGroup(action) {
  let userData = yield select(getUserData);
  let isPremier =
    userData &&
    (userData.success_plan === "premier" ||
      userData.success_plan === "signature")
      ? true
      : false;
  let actionFilters = {
    ...action.payload.filters,
    excludePremier: isPremier ? false : true,
    includeRecorded: true,
  };
  actionFilters["numPerPage"] = 5;
  const { status, data } = yield csFetch("events", "POST", actionFilters);
  if (status === 200) {
    yield put({
      type: LOAD_EVENT_FILTERGROUP_SUCCEED,
      groupEventList: data.events,
      groupKey: action.payload.groupkey,
    });
  }
}

export function* loadProductTopicGroup(action) {
  let actionData = {
    businessLine: action.payload.businessLine,
  };
  const { status, data } = yield csFetch("portraits", "POST", actionData);
  if (status === 200) {
    yield put({
      type: LOAD_PRODUCT_TOPICGROUP_SUCCEED,
      productTopicList: data.portraits,
      productKey: action.payload.businessLine,
    });
  }
}

export function* loadEventDetail(action) {
  const { status, data } = yield csFetch(`event/${action.payload.id}`, "GET");
  if (status === 200) {
    yield put({ type: LOAD_EVENT_DETAIL_SUCCEED, singleEvent: data });
  } else if (status === 404) {
    yield put({
      type: LOAD_EVENT_DETAIL_NOTFOUND,
      eventId: action.payload.id,
    });
  }
}

export function* loadParentEvent(action) {
  const { status, data } = yield csFetch(
    `event/${action.payload.parentEventId}`,
    "GET"
  );
  if (status === 200) {
    yield put({ type: LOAD_PARENTEVENT_SUCCEED, parentEvent: data });
  } else if (status === 404) {
    console.log("404");
    yield put({
      type: LOAD_PARENTEVENT_NOTFOUND,
      eventId: action.payload.parentEventId,
    });
  }
}

export function* loadFeaturedEvents() {
  let userData = yield select(getUserData);
  let isPremier =
    userData &&
    (userData.success_plan === "premier" ||
      userData.success_plan === "signature")
      ? true
      : false;
  let actionFilters = {
    excludePremier: isPremier ? false : true,
  };
  const { status, data } = yield csFetch(
    "featuredEvents",
    "POST",
    actionFilters
  );
  if (status === 200) {
    yield put({
      type: LOAD_FEATUREDEVENTS_SUCCEED,
      eventList: data.events,
    });
  }
}

export function* loadUpcomingEvents() {
  const { status, data } = yield csFetch("upcomingEvents", "POST");
  if (status === 200) {
    yield put({
      type: LOAD_UPCOMINGEVENTS_SUCCEED,
      eventList: data.events,
    });
  }
}

export function* loadRecurringEvents(action) {
  let recurringFilter = { parentEventId: action.payload.parentEventId };
  const { status, data } = yield csFetch(
    "recurringEvents",
    "POST",
    recurringFilter
  );
  if (status === 200) {
    yield put({
      type: LOAD_RECURRINGEVENTS_SUCCEED,
      eventList: data.events,
    });
  }
}

export function* loadParentSessions(action) {
  let parentFilter = { parentEventId: action.payload.parentEventId };
  const { status, data } = yield csFetch(
    "parentSessions",
    "POST",
    parentFilter
  );
  if (status === 200) {
    yield put({
      type: LOAD_PARENTSESSIONS_SUCCEED,
      eventList: data.events,
    });
  }
}

export function* setRegistrationEventInfo(action) {
  let registrationData = { ...action.payload.registrationData };
  // Specific test MC error case
  // TODO: remove if unnecessary
  if (
    registrationData.email === "mcregistrationerror@mcregistrationerror.com"
  ) {
    registrationData.simulate_mc_error = true;
  }
  let event_id = action.payload.id;
  const { status, data } = yield csFetch(
    `events/${event_id}/registration`,
    "POST",
    registrationData,
    true
  );
  if (status === 200) {
    yield put({
      type: SET_REGISTRATION_EVENT_INFO_SUCCEED,
      registrationEvent: event_id,
      registrationData: data,
    });
  }
  if (status === 500) {
    yield put({
      type: SET_REGISTRATION_EVENT_INFO_FAILED,
      failedEvent: event_id,
    });
  }
}

export function* cancelEventRegistration(action) {
  let tbidId = action.payload.tbidId;
  let eventId = action.payload.eventId;
  const { status, data } = yield csFetch(
    `users/${tbidId}/events/${eventId}/cancel`,
    "POST"
  );
  if (status === 200) {
    yield put({
      type: SET_REGISTRATION_EVENT_CANCEL_SUCCEED,
      canceledEvent: data,
    });
  }
}

export function* loadEventDetailUserRegistration(action) {
  let tbidId = action.payload.tbidId;
  let eventId = action.payload.eventId;
  try {
    const { status, data } = yield csFetch(
      `users/${tbidId}/registrations/?eventId=${eventId}`,
      "GET"
    );
    let registration_obj = {};
    if (data.items.length > 0) {
      registration_obj = data.items[0].registration;
    }
    if (status === 200) {
      yield put({
        type: LOAD_EVENT_DETAIL_USER_REGISTRATION_SUCCEED,
        registration: registration_obj,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* loadOnDemandEvents() {
  let userData = yield select(getUserData);
  let isPremier =
    userData &&
    (userData.success_plan === "premier" ||
      userData.success_plan === "signature")
      ? true
      : false;
  let actionFilters = {
    excludePremier: isPremier ? false : true,
    includeRecorded: true,
    type: ["video"],
    numPerPage: 3,
    language: ["en"]
  };
  const { status, data } = yield csFetch(
    "events",
    "POST",
    actionFilters
  );
  if (status === 200) {
    yield put({
      type: LOAD_ONDEMANDEVENTS_SUCCEED,
      eventList: data.events,
    });
  }
}


export function* loadEvent() {
  yield takeLatest(LOAD_EVENTS_LIST, fetchEventsList);
  yield takeEvery(LOAD_MORE_EVENTS, loadMoreEventsList);
  yield takeEvery(LOAD_EVENT_DETAIL, loadEventDetail);
  yield takeEvery(LOAD_EVENT_FILTERGROUP, loadEventFilterGroup);
  yield takeEvery(LOAD_PRODUCT_TOPICGROUP, loadProductTopicGroup);
  yield takeEvery(LOAD_FEATUREDEVENTS, loadFeaturedEvents);
  yield takeEvery(LOAD_UPCOMINGEVENTS, loadUpcomingEvents);
  yield takeEvery(LOAD_RECURRINGEVENTS, loadRecurringEvents);
  yield takeEvery(LOAD_PARENTSESSIONS, loadParentSessions);
  yield takeEvery(LOAD_PARENTEVENT, loadParentEvent);
  yield takeEvery(SET_REGISTRATION_EVENT_INFO, setRegistrationEventInfo);
  yield takeEvery(
    LOAD_EVENT_DETAIL_USER_REGISTRATION,
    loadEventDetailUserRegistration
  );
  yield takeEvery(SET_REGISTRATION_EVENT_CANCEL, cancelEventRegistration);
  yield takeLatest(UPDATE_EVENT_FILTERS, fetchEventsList);
  yield takeLatest(TOGGLE_PREMIER_EXCLUDE, fetchEventsList);
  yield takeEvery(LOAD_ONDEMANDEVENTS, loadOnDemandEvents);
}
