import moment from "moment-timezone";
import Moment from "react-moment";

export default function configureMoment() {
  // Sets the moment instance to use.
  //console.log("moment guess");
  let tzGuess = moment.tz.guess(true);
  //console.log(tzGuess);

  Moment.globalMoment = moment;
  // Set the locale for every react-moment instance to english
  Moment.globalLocale = "en-na";
  // Set the output format for every react-moment instance.
  Moment.globalFormat = "MMM Do YYYY, h:mm A";
  // Set the timezone for every instance.
  Moment.globalTimezone = tzGuess;
  // Set the output timezone for local for every instance.
  Moment.globalLocal = true;
}
