import { loadGenericAnalyticsActions } from "@coveo/headless";
import { headlessEngine } from "@/configureCoveo";

import { CLIENT_DOMAIN, COVEO_UA_ONLY_API_KEY } from "@/config/environment";

export function sendCoveoViewEvent() {
  // Send view events.
  if (window.coveoua) {
    window.coveoua("init", COVEO_UA_ONLY_API_KEY);
    window.coveoua("send", "pageview", {
      contentIdKey: "@clickableuri",
      contentIdValue: window.location.href,
      originLevel3: document.referrer,
    });
  }
}

export function sendCoveoCustomEvent(eventName, eventType, eventData) {
  const { logCustomEvent } = loadGenericAnalyticsActions(headlessEngine);

  // logging reg event to ua
  //console.log(`logging custom event${eventName}`);
  headlessEngine.dispatch(
    logCustomEvent({
      evt: eventType,
      type: eventName,
      meta: {
        c_contenttitle: eventData.title,
        c_contentid: eventData.id,
        c_contenturl: `https://${CLIENT_DOMAIN}/events/${eventData.id}`,
        c_successevent_region: eventData.region,
        c_successevent_tier: eventData.eventTier,
      },
    })
  );
}
