// ./src/containers/FilterBarContainer.js

import { connect } from "react-redux";
import { FilterBar } from "../pages/FilterBar";

const mapStateToProps = (state, ownProps) => {
  return {
    appConfig: state.csEvent.appConfig,
    eventFilters: state.csEvent.eventFilters,
    hubFilters: state.csHub.eventHubFilters,
    excludePremier: state.csEvent.excludePremier,
    language: state.csUser.language,
    locale: state.csUser.locale,
    timezone: state.csUser.timezone,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
  };
};

const FilterBarContainer = connect(mapStateToProps)(FilterBar);

export default FilterBarContainer;
