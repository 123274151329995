import React from "react";
import Form from "react-bootstrap/Form";

import "./SFInput.scss";

export const SFInput = ({
  name,
  label,
  type,
  value,
  defaultValue,
  placeholder,
  register,
  registerSet,
  required,
  isDirty,
  isTouched,
  isError,
  errorMessage,
  onChange,
}) => {
  return (
    <Form.Group controlId={name} className="sf-input-container">
      <Form.Label className="sf-input-label">{label}</Form.Label>
      <Form.Control
        as="input"
        className="sf-input-control"
        name={name}
        ref={register(registerSet ? registerSet : { required })}
        required={required}
        type={type || "text"}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        isValid={!isError && isDirty}
        isInvalid={
          (isError && isDirty) ||
          (isTouched && !isDirty && !value && !defaultValue)
        }
        onChange={onChange}
        autoComplete="nope"
      />
      <Form.Control.Feedback
        type="invalid"
        className="sf-input-validation-message"
      >
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
