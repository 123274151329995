import { buildSearchEngine } from "@coveo/headless";
import {
  COVEO_API_TOKEN,
  COVEO_ORG_ID,
  COVEO_SEARCH_HUB,
  COVEO_SEARCH_PIPELINE,
  COVEO_RECOMMENDATION_HUB,
  COVEO_RECOMMENDATION_PIPELINE,
} from "@/config/environment";

import { getSearchToken } from "@/utils/tokenServices";
import { load } from "@/utils/storage";
import { CS_USER_STORAGE_KEY } from "@/utils/storage/keys";
// import { buildRecommendationEngine } from "@coveo/headless/dist/definitions/app/recommendation-engine/recommendation-engine";
import { buildRecommendationEngine } from "@coveo/headless/recommendation";
const analyticsHook = (eventType, payload) => {
  //console.log("firing analytics hook, payload...");
  //console.log(payload);
  if (eventType === "click" && payload.actionCause === "documentOpen") {
    let clickResult = {};
    headlessEngine.state.search.results.forEach((resultObj) => {
      if (resultObj.raw.urihash === payload.documentUriHash) {
        clickResult = resultObj;
      }
    });
    // attach custom data to content clicks
    if (clickResult) {
      console.log("attaching click result data");
      // region data from event
      payload.customData.c_successevent_region =
        clickResult.raw.successeventregion;
      // tier data from event
      payload.customData.c_successevent_tier =
        clickResult.raw.successeventitemtier;
      // product data from event
      payload.customData.c_successevent_product =
        clickResult.raw.successeventproduct;
    }
  }

  return payload;
};

const getInitialSearchToken = () => {
  let searchToken = COVEO_API_TOKEN;
  if (!searchToken) {
    return "TOKEN-NA";
  }
  return searchToken;
};

async function getRenewSearchToken() {
  //console.log("calling coveo token refresh");
  const currentUser = load(CS_USER_STORAGE_KEY);
  const userEmail =
    currentUser && currentUser.tb_id ? currentUser.email : "anonymous";
  let token = await getSearchToken(userEmail);
  let searchToken = "";
  if (token && token.token) {
    searchToken = token.token;
    //console.log("succesful api refresh call");
    //console.log(searchToken);
  } else {
    //console.log("failed api refresh call, returning rendered anon token");
    searchToken = COVEO_API_TOKEN;
  }
  return searchToken;
}

export const headlessEngine = buildSearchEngine({
  configuration: {
    organizationId: COVEO_ORG_ID,
    accessToken: getInitialSearchToken(),
    renewAccessToken: getRenewSearchToken,
    name: "Coveo Search Engine",
    search: {
      searchHub: COVEO_SEARCH_HUB,
      pipeline: COVEO_SEARCH_PIPELINE,
    },
    analytics: {
      enabled: true,
      analyticsClientMiddleware: analyticsHook,
    },
  },
});

export const trendingEngine = buildRecommendationEngine({
  configuration: {
    organizationId: COVEO_ORG_ID,
    accessToken: getInitialSearchToken(),
    renewAccessToken: getRenewSearchToken,
    name: "Coveo Recommendation Engine",
    search: {
      searchHub: COVEO_RECOMMENDATION_HUB,
      pipeline: COVEO_RECOMMENDATION_PIPELINE,
    },
    analytics: {
      enabled: true,
      analyticsClientMiddleware: analyticsHook,
    },
  },
})