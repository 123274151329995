// ./src/containers/DashboardContainer.js

import { connect } from "react-redux";
import { HubFilterList } from "../pages/HubFilterList";

const mapStateToProps = (state, ownProps) => {
  const listMode = ownProps.businessLineId ? "product" : "topic";
  const hubId = ownProps.businessLineId
    ? ownProps.businessLineId
    : ownProps.match.params.id;
  const hubDetail = ownProps.hubDetail
    ? ownProps.hubDetail
    : state.csHub.hubDetail;
  const numPerPage = ownProps.numPerPage ? ownProps.numPerPage : 0;

  return {
    hubFilters: state.csHub.eventHubFilters[hubId],
    hubConfig: state.csHub.eventHubConfig[hubId],
    listInit: state.csEvent.listInit,
    listCurrent: state.csEvent.eventListCurrent,
    timezone: state.csUser.timezone,
    language: state.csUser.language,
    hubDetail: hubDetail,
    hubFeaturedEvent: state.csHub.eventHubFeatured,
    notFoundId: state.csHub.hubDetailNotFound,
    hubId: hubId,
    isLoading: state.csHub.isLoading,
    criticalError: state.csOther.criticalError,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    user: state.csUser.user,
    favorites: state.csUser.favoritesAll,
    appConfig: state.csEvent.appConfig,
    listMode: listMode,
    numPerPage: numPerPage,
  };
};

const HubFilterListContainer = connect(mapStateToProps)(HubFilterList);

export default HubFilterListContainer;
