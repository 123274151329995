import { UserActions } from "../../store/actions";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEventTranslation, useDurationFormat } from "../../hooks/";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import { ErrorPage } from "../ErrorPage";
import "./FavoritedEvents.scss";
import moment from "moment-timezone";
import Moment from "react-moment";
import Button from "react-bootstrap/Button";

const { getUserFavorites } = UserActions;

const FavoritedEvents = ({
  criticalError,
  user,
  favorites,
  favoritesTotalPages,
  currentFavoritesTotal,
}) => {
  const dispatch = useDispatch();

  const favoritedEvents = favorites
    ? favorites.map((item) => {
        const eventTypeKey = item.eventType ? item.eventType[0] : null;

        return {
          id: item.id,
          type: eventTypeKey,
          title: item.title,
          time: item.startDateTime,
          groupType: item.eventGroupType,
          endTime: item.endDateTime,
          sessionLength: item.sessionLength,
        };
      })
    : [];
  const { t } = useTranslation();

  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  const loadFavorited = () => {
    dispatch(
      getUserFavorites({
        tb_id: user.tb_id,
        pageNumber: currentFavoritesTotal,
        currentCounter: currentFavoritesTotal + 1,
      })
    );
  };

  const FavoritedEvent = ({ event }) => {
    const eventTypeTranslated = useEventTranslation("eventType", event.type);
    const durationStr = useDurationFormat(event.sessionLength);

    // bucket parent handling
    const sameDayFlag = moment(event.time).isSame(
      moment(event.endDateTime),
      "day"
    );
    const isBucketParent = event.groupType === "Bucket Parent";

    const startDateFormat = isBucketParent
      ? sameDayFlag
        ? "MMMM D, YYYY"
        : "MMMM D"
      : "ddd, MMMM D, YYYY @ h:mm A z";

    return (
      <Link
        to={{
          pathname: "/events/" + event.id,
        }}
      >
        <div className="favorited-event_wrapper">
          <div className="favorited-event_type">
            {eventTypeTranslated.toUpperCase()}
          </div>
          <div className="favorited-event_title">{event.title}</div>
          <div className="favorited-event_time">
            {event.type !== "video" ? (
              <Moment date={event.time} format={startDateFormat} />
            ) : (
              `${durationStr}`
            )}
            {!sameDayFlag && isBucketParent && "-" && (
              <>
                {" - "}
                <Moment
                  date={event.endDateTime}
                  format={startDateFormat + ", YYYY"}
                />
              </>
            )}
            {/*}
            {event.type !== "video" ? (
              <Moment date={event.time} format="ddd, MMMM D, YYYY @ h:mm A z" />
            ) : (
              `${durationStr}`
            )}*/}
          </div>
        </div>
      </Link>
    );
  };

  const RenderNoFavoritedEvents = () => {
    return (
      <div className="no-favorited-event_wrapper">
        <div className="no-favorited-event_title">
          {t("User dashboard No Favorited Events")}
        </div>
        <div className="no-favorited-event_divider" />
        <div className="no-favorited-event_description">
          {t("User dashboard Favorite Instructions")}
        </div>
      </div>
    );
  };

  const RenderFavoritedEvents = () => {
    return (
      <div>
        <div className="favorited-events_container">
          <div className="favorited-events_header">
            <h2 className="groupHeader">
              {t("User dashboard Your Favorited Events")}
            </h2>
          </div>

          <div>
            <div className="favorited-events_body">
              {favoritedEvents.length ? (
                favoritedEvents.map((item, index) => {
                  return (
                    <Col xs={12} md={6} lg={4} xl={3} key={index}>
                      <FavoritedEvent event={item} />
                    </Col>
                  );
                })
              ) : (
                <RenderNoFavoritedEvents />
              )}
            </div>
            <div
              className={
                favoritesTotalPages > currentFavoritesTotal
                  ? "favorited-events_load-more"
                  : "favorited-events_load-more hide"
              }
            >
              <Button className="loadMoreButton" onClick={loadFavorited}>
                {t("Load More Events")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <RenderFavoritedEvents />;
};

export default FavoritedEvents;
