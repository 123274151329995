import React from 'react';
import { Row, Col } from "react-bootstrap";

import bgImg from '../../assets/images/login/background.png';
import bgImg2x from '../../assets/images/login/background@2x.png';
import astroMeditateImg from '../../assets/images/login/astro-meditate.png';
import astroMeditateImg2x from '../../assets/images/login/astro-meditate@2x.png';
import shadowImg from '../../assets/images/login/shadow.png';
import shadowImg2x from '../../assets/images/login/shadow@2x.png';

import './styles.scss';

const LoginBanner = (props) => {
  const { children } = props;

  return (
    <div className="heroBanner loginPage">
      <Row className="loginRow">
        <Col xs={12} lg={6} className="loginContentCol">
          <div className="heroContainer loginChildContainer">
            {children}
          </div>
        </Col>
        <Col xs={12} lg={6} className="loginBgCol">
          <div className="loginBgContainer">
            <img
              className="loginBgImg"
              src={bgImg}
              srcSet={`${bgImg2x} 2x`}
              alt={"Loading..."}
            />

            <img
              className="loginShadowImg loginShadowAnim"
              src={shadowImg}
              srcSet={`${shadowImg2x} 2x`}
              alt="shadow"
            />

            <img
              className="loginAstroImg loginFloatingAnim"
              src={astroMeditateImg}
              srcSet={`${astroMeditateImg2x} 2x`}
              alt="Astro"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

LoginBanner.propTypes = {};

export default LoginBanner;