// ./src/containers/DashboardContainer.js

import { connect } from "react-redux";
import { Dashboard } from "../pages/Dashboard";

const mapStateToProps = (state) => {
  return {
    user: state.csUser.user,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    criticalError: state.csOther.criticalError,
  };
};

const DashboardContainer = connect(mapStateToProps)(Dashboard);

export default DashboardContainer;
