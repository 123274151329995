// ./src/containers/HomeContainer.js

import { connect } from "react-redux";
import { Home } from "../pages/Home";

const mapStateToProps = (state) => {
  return {
    filterGroups: state.csEvent.appConfig.filterGroups,
    criticalError: state.csOther.criticalError,
    user: state.csUser.user,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    appConfig: state.csEvent.appConfig,
  };
};

const HomeContainer = connect(mapStateToProps)(Home);

export default HomeContainer;
