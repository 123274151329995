import React, { useState, useCallback } from "react";
import Select from "react-select";
import PropTypes from "prop-types";

import { ReactComponent as AlertIcon } from "@/assets/images/icon_alert.svg";
import { ReactComponent as ValidIcon } from "@/assets/images/icon_valid.svg";
import { ReactComponent as DropdownIcon } from "@/assets/images/icon_dropdown.svg";

import "./FormSelect.scss";

const FormSelect = ({
  options,
  selected,
  name,
  onChange,
  placeholder,
  errorMessage,
  isError,
  title,
}) => {
  const [isTouched, setIsTouched] = useState(false);

  const handleChange = useCallback((item) => onChange(item.value), [onChange]);
  const handleOnBlur = useCallback(() => {
    setIsTouched(true);
  }, [setIsTouched]);

  const DropdownIndicator = ({ innerProps }) => (
    <div {...innerProps} className="select-dropdown">
      <div className="select-dropdown-icon_container">
        {selected ? (
          <ValidIcon />
        ) : isError || (isTouched && selected === "") ? (
          <AlertIcon />
        ) : null}
      </div>
      <DropdownIcon />
    </div>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "44px",
      border: state.isFocused
        ? `1px solid ${isError ? "#C23934" : "#979797"}`
        : `1px solid #979797`,
      fontSize: window.orientation > -1 ? "16px" : "20px",
      fontFamily: "salesforcesans-light",
      fontWeight: "normal",
      backgroundColor: state.selectProps.value ? "#eaf5fe" : "#fff",
      boxShadow: state.isFocused
        ? `0px 0px 5px 1px ${isError ? "#C23934" : "#00a1e0"}`
        : 0,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: window.orientation > -1 ? "16px" : "20px",
      fontFamily: "salesforcesans-light",
      fontWeight: "normal",
      padding: window.orientation > -1 ? "1px" : "7px",
    }),
  };

  return (
    <div className="select-container">
      {title && (
        <label htmlFor={`select-${name}_id`} className="select-title">
          {title}
        </label>
      )}
      <Select
        inputId={`select-${name}_id`}
        isError={isError}
        styles={customStyles}
        className={`select-${name}`}
        options={options}
        placeholder={placeholder}
        components={{
          DropdownIndicator,
          IndicatorSeparator: null,
        }}
        value={selected}
        defaultValue={selected}
        onChange={handleChange}
        tabSelectsValue={false}
        isSearchable={true}
        inputProps={{ autoComplete: "nope" }}
        onBlur={handleOnBlur}
        theme={(theme) => {
          theme.boxShadow = "0px 0px 3px 1px #C23934";
          return {
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#00a1e0",
            },
          };
        }}
        autoComplete="nope"
      />
      {(isError || (isTouched && selected === "")) && (
        <span className="select-error">{errorMessage}</span>
      )}
    </div>
  );
};

FormSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  title: PropTypes.string,
};

export default FormSelect;
