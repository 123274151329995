import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment-timezone";
import Moment from "react-moment";

const FilterDateRange = ({
  title,
  selectedStartDate,
  selectedEndDate,
  expanded,
  changeCallback,
  language,
  timezone,
  locale,
  isMobile,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    let momentStart = selectedStartDate ? moment(selectedStartDate) : null;
    setStartDate(momentStart);
    let momentEnd = selectedEndDate ? moment(selectedEndDate) : null;
    setEndDate(momentEnd);
    if (expanded || selectedStartDate || selectedEndDate) {
      setIsExpanded(true);
    }
  }, [selectedStartDate, selectedEndDate, expanded]);

  const dateSpanCallback = (span) => {
    let currentTz = timezone ? timezone : Moment.globalTimezone;
    changeCallback("dateSpan", span);
    switch (span) {
      case "today": {
        let start = moment().tz(currentTz).startOf("day");
        let end = start.clone().endOf("day");
        changeCallback("startDate", start);
        changeCallback("endDate", end);
        break;
      }
      case "this-week": {
        let start = moment().tz(currentTz).startOf("week");
        let end = start.clone().endOf("week");
        changeCallback("startDate", start);
        changeCallback("endDate", end);
        break;
      }
      case "next-week": {
        let start = moment().tz(currentTz).add(1, "weeks").startOf("week");
        let end = start.clone().endOf("week");
        changeCallback("startDate", start);
        changeCallback("endDate", end);
        break;
      }
      default: {
        break;
      }
    }
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [focusedInput, setFocusedInput] = useState();

  const activeKey =
    expanded || selectedStartDate || selectedEndDate ? "1" : "0";

  let start_date_id = "start_date_id";
  let end_date_id = "end_date_id";

  const [isExpanded, setIsExpanded] = useState(activeKey !== "0");

  const decoratedOnClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion
      defaultActiveKey="0"
      activeKey={isExpanded ? "1" : "0"}
      className={isExpanded ? "expanded" : "collapsed"}
    >
      <Card className="filterCard">
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="1"
            onClick={decoratedOnClick}
          >
            {t(title)}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <DateRangePicker
              startDatePlaceholderText={t("Start Date")}
              endDatePlaceholderText={t("End Date")}
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId={start_date_id} // PropTypes.string.isRequired,
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId={end_date_id} // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  let currentTz = timezone ? timezone : Moment.globalTimezone;
                  startDate = startDate.tz(currentTz).clone().startOf("day");
                  endDate = endDate.tz(currentTz).clone().endOf("day");
                }

                setStartDate(startDate);
                setEndDate(endDate);

                if (startDate && endDate) {
                  changeCallback("dateSpan", "custom");
                  changeCallback("startDate", startDate);
                  changeCallback("endDate", endDate);
                }
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
              minimumNights={0}
              numberOfMonths={1}
              openDirection={isMobile ? "down" : "up"}
              renderMonthElement={({ month }) =>
                moment(month).locale(locale).format("MMMM YYYY")
              }
            />
            <div className="dateSpanLinks">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dateSpanCallback("today");
                }}
                variant="link"
                className="inlineLink"
              >
                {t("Today")}
              </Button>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dateSpanCallback("this-week");
                }}
                variant="link"
                className="inlineLink"
              >
                {t("This Week")}
              </Button>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dateSpanCallback("next-week");
                }}
                variant="link"
                className="inlineLink"
              >
                {t("Next Week")}
              </Button>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default FilterDateRange;
