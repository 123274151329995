import React, { useEffect, useState } from "react";

import Pagination from 'react-bootstrap/Pagination';

import { ReactComponent as RightArrowIcon } from "../../../assets/images/icon_arrow_right.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assets/images/icon_arrow_left.svg";

import "./Pager.scss";

export const Pager = (props) => {
  const { controller, element } = props;
  const [state, setState] = useState(controller.state);

  useEffect(
    () => controller.subscribe(() => setState(controller.state)),
    [controller]
  );

  const pageLoad = () => {
    if (element) {
      const divElement = document.getElementById(element);
      divElement.scrollIntoView({ behavior: 'smooth'});
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <nav className="coveo--pager">
      <Pagination className="justify-content-center justify-content-sm-start">
        {state.hasPreviousPage && (
          <Pagination.Item 
            onClick={() => {
              pageLoad();
              controller.previousPage();
            }}          
          >
            <LeftArrowIcon />
          </Pagination.Item>
        )}
        {state.currentPages.map((page) => (
          <Pagination.Item
            key={page}
            disabled={controller.isCurrentPage(page)}
            active={controller.isCurrentPage(page)}
            onClick={() => {
              pageLoad();
              controller.selectPage(page);
            }}
          >
            {page}
          </Pagination.Item>
        ))}
        {state.hasNextPage && (
          <Pagination.Next
            onClick={() => {
              pageLoad();
              controller.nextPage();
            }}
          >
            <RightArrowIcon />
          </Pagination.Next>
        )}
      </Pagination>
    </nav>
  );
};
