// ./src/reducers/index.js

import { EventActions, HubActions, UserActions } from "../actions";
import AppConfig from "../../config/config";

const {
  LOAD_EVENTS_LIST,
  RENDER_EVENTS_LIST,
  UPDATE_EVENT_FILTERS,
  UPDATE_EVENT_FILTERS_SILENT,
  TOGGLE_PREMIER_EXCLUDE,
  LOAD_MORE_EVENTS,
  LOAD_MORE_EVENTS_SUCCEED,
  LOAD_MORE_EVENTS_FAILED,
  LOAD_EVENT_DETAIL,
  LOAD_EVENT_DETAIL_SUCCEED,
  LOAD_EVENT_DETAIL_NOTFOUND,
  LOAD_PARENTEVENT_SUCCEED,
  LOAD_PARENTSESSIONS_SUCCEED,
  LOAD_EVENT_FILTERGROUP_SUCCEED,
  LOAD_PRODUCT_TOPICGROUP_SUCCEED,
  LOAD_RECURRINGEVENTS_SUCCEED,
  LOAD_FEATUREDEVENTS_SUCCEED,
  LOAD_UPCOMINGEVENTS_SUCCEED,
  SET_REGISTRATION_EVENT_INFO_SUCCEED,
  SET_REGISTRATION_EVENT_INFO_FAILED,
  LOAD_EVENT_DETAIL_USER_REGISTRATION_SUCCEED,
  REMOVE_REGISTRATION_FAILED,
  LOAD_ONDEMANDEVENTS_SUCCEED,
} = EventActions;

const {
  INIT_HUB,
  LOAD_HUB_EVENTS_LIST,
  UPDATE_HUB_EVENT_FILTERS,
  UPDATE_HUB_EVENT_FILTERS_SILENT,
  TOGGLE_HUB_PREMIER_EXCLUDE,
} = HubActions;

const { SET_USER_INFO } = UserActions;

let initialState = {
  appConfig: AppConfig,
  eventList: [],
  featuredEvents: [],
  upcomingEvents: [],
  eventDetail: {},
  parentEventDetail: {},
  parentEventSessions: [],
  eventDetailRecurring: [],
  eventDetailNotFound: 0,
  eventFilters: {
    search: "",
    product: [],
    category: [],
    region: [],
    language: [],
    type: [],
    startDate: null,
    endDate: null,
    numPerPage: 24,
    page: 0,
    includeRecorded: true,
    sort: "date",
    dateSpan: "custom",
  },
  excludePremier: false,
  eventDetailUserRegistration: {},
  eventsWithRegistration: [],
  eventsWithFailedRegistration: [],
  eventFilterGroups: {},
  productTopicGroups: {},
  eventListCurrent: "default",
  isLoading: false,
  isLoadingList: false,
  totalPages: 0,
  totalEvents: 0,
  listInit: false,
  onDemandEventsList: [],
};

export default function csEvent(state = initialState, action) {
  switch (action.type) {
    case LOAD_EVENTS_LIST:
      return {
        ...state,
        isLoadingList: true,
        eventListCurrent: "default",
        eventFilters: {
          ...state.eventFilters,
          page: 0,
        },
      };
    case LOAD_HUB_EVENTS_LIST:
      const hubLoadId = action.eventFilters.hubId;
      return {
        ...state,
        eventListCurrent: hubLoadId,
        isLoadingList: true,
      };
    case RENDER_EVENTS_LIST:
      const numEvents = Number(action.totalEvents);
      const numPages = Number(action.totalPages);
      return {
        ...state,
        eventList: action.eventList,
        eventListCurrent: action.eventListCurrent,
        totalEvents: numEvents,
        totalPages: numPages,
        eventFilters: {
          ...state.eventFilters,
          page: 0,
        },
        isLoadingList: false,
        listInit: true,
      };
    case UPDATE_EVENT_FILTERS:
    case UPDATE_EVENT_FILTERS_SILENT:
      const filterParam = action.eventFilters.key;
      const filterValues = action.eventFilters.values;
      const setLoading = action.type === UPDATE_EVENT_FILTERS;
      return {
        ...state,
        isLoadingList: setLoading,
        eventFilters: {
          ...state.eventFilters,
          [filterParam]: filterValues,
          page: 0,
        },
      };
    case UPDATE_HUB_EVENT_FILTERS:
    case UPDATE_HUB_EVENT_FILTERS_SILENT:
      const setLoadingHub = action.type === UPDATE_HUB_EVENT_FILTERS;
      return {
        ...state,
        isLoadingList: setLoadingHub,
      };
    case TOGGLE_PREMIER_EXCLUDE:
    case TOGGLE_HUB_PREMIER_EXCLUDE:
      return {
        ...state,
        isLoadingList: true,
        excludePremier: action.eventFilters.excludePremier,
      };
    case LOAD_MORE_EVENTS:
      return {
        ...state,
        isLoading: true,
        eventFilters: {
          ...state.eventFilters,
          page: state.eventFilters.page + 1,
        },
      };
    case LOAD_MORE_EVENTS_SUCCEED:
      const newEventList = action.newEventList;
      const { eventList } = state;
      return {
        ...state,
        eventList: [...eventList, ...newEventList],
        isLoading: false,
      };
    case LOAD_MORE_EVENTS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOAD_EVENT_DETAIL:
      return {
        ...state,
        isLoading: true,
        eventDetailNotFound: 0,
      };
    case LOAD_EVENT_DETAIL_SUCCEED:
      const newEventDetail = action.singleEvent;
      return {
        ...state,
        eventDetailNotFound: 0,
        eventDetail: newEventDetail,
        parentEventDetail: {},
        parentEventSessions: [],
        isLoading: false,
        // clear registration status to accomodate updated status on re-submit
        eventDetailUserRegistration: {},
        eventsWithRegistration: [],
      };
    case LOAD_EVENT_DETAIL_NOTFOUND:
      return {
        ...state,
        isLoading: false,
        eventDetailNotFound: action.eventId,
      };
    case LOAD_PARENTEVENT_SUCCEED:
      const newParentDetail = action.parentEvent;
      return {
        ...state,
        parentEventDetail: newParentDetail,
      };
    case LOAD_PARENTSESSIONS_SUCCEED:
      return {
        ...state,
        parentEventSessions: action.eventList,
      };
    case LOAD_EVENT_FILTERGROUP_SUCCEED:
      const groupEventList = action.groupEventList;
      const groupKey = action.groupKey;
      return {
        ...state,
        isLoading: false,
        eventFilterGroups: {
          ...state.eventFilterGroups,
          [groupKey]: groupEventList,
        },
      };
    case LOAD_PRODUCT_TOPICGROUP_SUCCEED:
      const productTopicList = action.productTopicList;
      const productKey = action.productKey;
      return {
        ...state,
        isLoading: false,
        productTopicGroups: {
          ...state.productTopicGroups,
          [productKey]: productTopicList,
        },
      };
    case LOAD_FEATUREDEVENTS_SUCCEED:
      return {
        ...state,
        featuredEvents: action.eventList,
      };
    case LOAD_UPCOMINGEVENTS_SUCCEED:
      return {
        ...state,
        upcomingEvents: action.eventList,
      };
    case LOAD_RECURRINGEVENTS_SUCCEED:
      return {
        ...state,
        eventDetailRecurring: action.eventList,
      };
    case LOAD_EVENT_DETAIL_USER_REGISTRATION_SUCCEED:
      return {
        ...state,
        eventDetailUserRegistration: action.registration,
      };
    case SET_REGISTRATION_EVENT_INFO_SUCCEED:
      return {
        ...state,
        eventsWithRegistration: [
          ...state.eventsWithRegistration,
          action.registrationEvent,
        ],
        eventDetailUserRegistration: action.registrationData,
      };
    case SET_REGISTRATION_EVENT_INFO_FAILED:
      return {
        ...state,
        eventsWithFailedRegistration: [
          ...state.eventsWithFailedRegistration,
          action.failedEvent,
        ],
      };
    case REMOVE_REGISTRATION_FAILED:
      return {
        ...state,
        eventsWithFailedRegistration: [],
      };
    // clear user-respective event details
    case SET_USER_INFO:
      return {
        ...state,
        excludePremier: false,
        eventDetailUserRegistration:
          action.payload === null ? {} : state.eventDetailUserRegistration,
        eventsWithRegistration:
          action.payload === null ? [] : state.eventsWithRegistration,
      };
    case INIT_HUB:
      const hubConfigId = action.payload.hubId;
      return {
        ...state,
        eventListCurrent: hubConfigId,
      };
    case LOAD_ONDEMANDEVENTS_SUCCEED:
      return {
        ...state,
        onDemandEventsList: action.eventList,
      };
    default:
      return state;
  }
}
