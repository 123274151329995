import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Event } from "../../components/Event";

import "./FeaturedEvents.scss";

const FeaturedEvents = ({ eventList, tb_id, tb_user_plan, favorites }) => {
  const { t } = useTranslation();

  const favoritesIds = favorites ? favorites : [];
  const checkFavorite = (eventId) => {
    return favoritesIds.includes(eventId);
  };

  return (
    <div className="colPanel">
      <SkeletonTheme color="#ccc" highlightColor="#ddd">
        <div className="featureGroup">
          <Row>
            <Col xs={12}>
              <h2 className="float-left groupHeader">{t("Featured Events")}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Container className="featureCol">
                <Row>
                  <Col
                    className="primaryFeature"
                    xs={12}
                    md={12}
                    lg={4}
                    xl={4}
                    key="primary-feature"
                  >
                    {eventList && eventList.length > 0 ? (
                      eventList
                        .slice(0, 1)
                        .map((event, index) => (
                          <Event
                            event={event}
                            tb_id={tb_id}
                            tb_user_plan={tb_user_plan}
                            primaryFeature={true}
                            key={event.id}
                            {...event}
                            isFavorite={() => checkFavorite(event.id)}
                          />
                        ))
                    ) : (
                      <Skeleton
                        key={"skeleton-card-featured"}
                        className="skeletonCard"
                        height={560}
                        duration={3}
                      />
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={8} xl={8}>
                    <Container className="featureCol">
                      <Row>
                        {eventList && eventList.length > 0
                          ? eventList.slice(1, 5).map((event, index) => (
                              <Col xs={12} md={6} lg={6} xl={6} key={event.id}>
                                <Event
                                  event={event}
                                  key={event.id}
                                  {...event}
                                  tb_id={tb_id}
                                  tb_user_plan={tb_user_plan}
                                  isFavorite={() =>
                                    checkFavorite(event.id, "main")
                                  }
                                />
                              </Col>
                            ))
                          : [1, 2, 3, 4].map((event, index) => (
                              <Col xs={12} md={6} lg={6} xl={6} key={index}>
                                <Skeleton
                                  key={"skeleton-card-" + index}
                                  className="skeletonCard"
                                  height={260}
                                  duration={3}
                                />
                              </Col>
                            ))}
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </SkeletonTheme>
    </div>
  );
};

FeaturedEvents.propTypes = {
  eventList: PropTypes.array,
};

export default FeaturedEvents;
