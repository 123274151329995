import { OtherActions } from "../actions";

const { TOGGLE_SHARE_MODAL, SET_CRITICAL_ERROR, TOGGLE_AUTH_MODAL } =
  OtherActions;

let initialState = {
  shareVisible: false,
  shareUrl: null,
  criticalError: false,
  criticalErrorMessage: "",
  authError: false,
  authErrorMessage: "",
};

export default function csOther(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SHARE_MODAL:
      return {
        ...state,
        shareVisible: action.payload.show,
        shareUrl: action.payload.shareUrl,
      };
    case TOGGLE_AUTH_MODAL:
      return {
        ...state,
        authVisible: action.payload.show,
        authErrorMessage: action.payload.message,
      };
    case SET_CRITICAL_ERROR:
      return {
        ...state,
        criticalError: Boolean(action.message),
        criticalErrorMessage: action.message,
      };
    default:
      return state;
  }
}
