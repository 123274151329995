import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment-timezone";
import Moment from "react-moment";

import Pill from "@salesforce/design-system-react/components/pill";

import "./SearchPills.scss";

export const SearchPills = (props) => {
  const {
    controller,
    searchParametersController,
    timezone,
    locale,
    configFilters,
  } = props;
  const [state, setState] = useState(controller.state);
  const [searchParametersState, setSearchParametersState] = useState(
    searchParametersController.state
  );

  const { t } = useTranslation();

  const filterOptions = [
    {
      facetId: "successeventproduct",
      name: "product",
    },
    {
      facetId: "successeventeventtype",
      name: "eventType",
    },
    {
      facetId: "successeventregion",
      name: "region",
    },
    {
      facetId: "sitemaplanguage",
      name: "eventLanguage",
    },
  ];

  const dateOptions = [
    {
      start: "now",
      end: "hour",
      label: "Today",
    },
    {
      start: "now",
      end: "day",
      label: "This Week",
    },
    {
      start: "next",
      end: "next",
      label: "Next Week",
    },
  ];

  useEffect(() => {
    const pillSubscribe = controller.subscribe(() =>
      setState(controller.state)
    );

    const searchParametersSubcribe = searchParametersController.subscribe(() =>
      setSearchParametersState(searchParametersController.state)
    );

    return () => {
      pillSubscribe();
      searchParametersSubcribe();
    };
  }, [controller, searchParametersController]);

  const translatedFacetValue = (facet, value) => {
    const filterOption = filterOptions.find((f) => f.facetId === facet);

    if (filterOption) {
      const configEntry = configFilters[filterOption.name].options.find(
        (o) => o.value === value
      );

      if (configEntry) {
        return t(configEntry["text"]);
      } else {
        return t(value);
      }
    } else {
      if (facet === "successeventitemtier") {
        return t("Premier Exclude Toggle CTA Text");
      } else {
        if (facet === "successeventcustomerlifecycle") {
          value = `Portrait Phase ${value}`;
        } else if (facet === "successeventbusinessline") {
          value = `Product Line Title ${value}`;
        }
        return t(value);
      }
    }
  };

  const translatedDateFacetValue = (value) => {
    const dateOption = dateOptions.find((d) => {
      return value.start.includes(d.start) && value.end.includes(d.end);
    });

    if (dateOption) {
      return t(dateOption["label"]);
    } else {
      return t(`${value.start} - ${value.end}`);
    }
  };

  if (!state?.hasBreadcrumbs && !searchParametersState.parameters) {
    return null;
  }

  const renderDateRange = () => {
    //format example: @successeventenddate=2021/11/01..2021/11/06
    const dateFormat = "YYYY/MM/DD";
    let searchParams = { ...searchParametersState.parameters };
    let aq = searchParams.aq;
    let regex = /(\d{4}\/\d{2}\/\d{2}\.\.\d{4}\/\d{2}\/\d{2})+/g;
    let dateRange = aq.substring(aq.search(regex)).split("..");

    let currentTz = timezone ? timezone : Moment.globalTimezone;
    let dateSpanStr =
      moment(dateRange[0], dateFormat)
        .tz(currentTz)
        .locale(locale)
        .format("LL") +
      " - " +
      moment(dateRange[1], dateFormat)
        .tz(currentTz)
        .locale(locale)
        .format("LL");

    searchParams.aq = "";

    return (
      <Pill
        key="dateRange"
        labels={{
          label: `${t("Date Range")}: ${dateSpanStr}`,
        }}
        onClick={(e) => {
          e.preventDefault();
        }}
        onRemove={() => {
          searchParametersController.synchronize(searchParams);
        }}
      />
    );
  };

  return (
    <div className="coveo--breadcrumbs pillPanel" key="pillPanel">
      {state.facetBreadcrumbs.map((facet) => (
        <React.Fragment key={facet.facetId}>
          {facet.values.map((breadcrumb) => (
            <Pill
              key={breadcrumb.value.value}
              labels={{
                label: `${translatedFacetValue(
                  facet.facetId,
                  breadcrumb.value.value
                )}`,
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
              onRemove={() => {
                breadcrumb.deselect();
              }}
            />
          ))}
        </React.Fragment>
      ))}
      {state.dateFacetBreadcrumbs.map((facet) => (
        <>
          {facet.values.map((breadcrumb, index) => (
            <Pill
              key={index}
              labels={{
                label: `${translatedDateFacetValue(breadcrumb.value)}`,
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
              onRemove={() => {
                breadcrumb.deselect();
              }}
            />
          ))}
        </>
      ))}
      {searchParametersState.parameters.aq && renderDateRange()}
    </div>
  );
};
