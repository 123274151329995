// ./src/containers/EventDetailContainer.js

import { connect } from "react-redux";
import { EventDetail } from "../pages/EventDetail";

const mapStateToProps = (state, ownProps) => {
  return {
    eventDetail: state.csEvent.eventDetail,
    parentEventDetail: state.csEvent.parentEventDetail,
    parentEventSessions: state.csEvent.parentEventSessions,
    eventDetailRecurring: state.csEvent.eventDetailRecurring,
    isLoading: state.csEvent.isLoading,
    eventId: ownProps.match.params.id,
    timezone: state.csUser.timezone,
    language: state.csUser.language,
    user: state.csUser.user,
    tbidUser: Boolean(state.csUser.user && state.csUser.user.tb_id),
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    favorites: state.csUser.favoritesAll,
    notFoundId: state.csEvent.eventDetailNotFound,
    criticalError: state.csOther.criticalError,
    eventsWithRegistration: state.csEvent.eventsWithRegistration,
    eventsWithFailedRegistration: state.csEvent.eventsWithFailedRegistration,
    eventDetailUserRegistration: state.csEvent.eventDetailUserRegistration,
  };
};

const EventDetailContainer = connect(mapStateToProps)(EventDetail);

export default EventDetailContainer;
