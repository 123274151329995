import { loadUser } from "./user"
import { loadHub } from "./hub"
import { loadEvent } from "./event"
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    loadEvent(),
    loadUser(),
    loadHub()
  ]);
}
