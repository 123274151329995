import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ProductTile } from "../ProductTile";

import "./ProductGrid.scss";

const ProductGrid = ({ title, eccType, childData }) => {
    const { t } = useTranslation();
    const [imageSource, setImageSource] = useState(undefined);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        async function getImages() {
            const img = await import(
              `../../assets/images/producttile-images/ecc-topics-bg.png`
            )
              .then((image) => image.default)
              .catch((error) => {
                console.debug("error", error);
              });
            const img2x = await import(
                `../../assets/images/producttile-images/ecc-topics-bg@2x.png`
            )
              .then((image) => image.default)
              .catch((error) => {
                console.debug("error", error);
              });
      
            // If the image doesnt exist, fail gracefully
            if (!img || !img2x) return;
            setImageSource({
              default: img,
              retina: img2x,
            });
          }

          if (eccType === "expertcoaching") {
            getImages();
          }
    }, [eccType, childData]);

    function imageOnLoad() {
        setImageLoaded(true);
      }

    return (
      <div className="productGrid">
        
            {!imageSource && (
              <div className={`maskedBg ${eccType}mask`}>
              <div className={`mask ${eccType}`}></div>
              </div>
            )}
          
        <div className={`productGridWrapper ${eccType}`}>
        
          <h2 className="groupHeader">{t(title)}</h2>
          <Row xs={1} sm={3} md={4} lg={5} className={`productRow ${eccType} container`}>
            {childData.map((obj, idx) => (
              <Col>
                <ProductTile
                  title={obj.title}
                  icon={obj.icon}
                  href={obj.href}
                  subtitle={obj.subtitle}
                />
              </Col>
            ))}
          </Row>
          {imageSource && (
          <div className={`maskedBg ${eccType}mask`}>
            <div className={`mask ${eccType}`}></div>
            
              <img
                className={`image ${imageLoaded && "image-loaded"}`}
                src={imageSource.default}
                srcSet={`${imageSource.retina} 2x`}
                onLoad={imageOnLoad}
                alt=""
              />
            
          </div>
          )}
        </div>
      </div>
    );
};

ProductGrid.defaultProps = {
    childData: {},
};

ProductGrid.propTypes = {
    childData: PropTypes.object,
};

export default ProductGrid;