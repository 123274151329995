import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Checkbox from "@salesforce/design-system-react/components/checkbox";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import "./Facet.scss";

export const Facet = (props) => {
  const { controller, searchParams, configOptions, stringPrefix, facetId } =
    props;
  const [state, setState] = useState(controller.state);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasUserExpanded, setHasUserExpanded] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const facetSubscribe = controller.subscribe(() =>
      setState(controller.state)
    );

    return () => {
      facetSubscribe();
    };
  }, [controller]);

  if (!state.values.length) {
    return null; // makes facets show dynamically 
  }

  if (searchParams && searchParams.parameters) {
    //const cqState = searchParams.parameters.cq;
    const tbState = searchParams.parameters.tab;
    if (
      ((facetId === "eventTypeFacet") && tbState && (tbState === "Topics" || tbState === "Coaching")) ||
      ((facetId === "customerlifecycleFacet") && tbState && (tbState === "Events"))
    ) {
      return null;
    }
  }

  if (
    !hasUserExpanded &&
    state.values.some((value) => {
      return value.state === "selected";
    })
  ) {
    setIsExpanded(true);
    setHasUserExpanded(true);
  }

  const translatedFacetValue = (value) => {
    const configEntry = configOptions.find((o) => o.value === value);
    if (configEntry) {
      return t(configEntry["text"]);
    } else {
      const tValue = stringPrefix ? `${stringPrefix}${value}` : value;
      return t(tValue);
    }
  };

  const decoratedOnClick = () => {
    setIsExpanded(!isExpanded);
    setHasUserExpanded(true);
  };

  return (
    <ListGroup.Item className="filterBarGroup coveo--facet">
      <Accordion
        defaultActiveKey="0"
        activeKey={isExpanded ? "1" : "0"}
        className={isExpanded ? "expanded" : "collapsed"}
      >
        <Card className="filterCard">
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              onClick={decoratedOnClick}
            >
              {t(props.title)}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {state.values.map((value) => (
                <Checkbox
                  key={value.value}
                  assistiveText={{
                    label: "Default",
                  }}
                  id={value.value}
                  labels={{
                    label: `${
                      configOptions
                        ? translatedFacetValue(value.value)
                        : value.value
                    } (${value.numberOfResults})`,
                  }}
                  disabled={state.isLoading}
                  className={state.isLoading ? "filter-disabled" : ""}
                  checked={controller.isValueSelected(value)}
                  onChange={() => controller.toggleSelect(value)}
                />
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </ListGroup.Item>
  );
};
