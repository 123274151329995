import React, { useState, useEffect } from "react";

import moment from "moment-timezone";
import Moment from "react-moment";

import Pill from "@salesforce/design-system-react/components/pill";

import { useEventTranslation, useEventTranslationBulk } from "../../hooks/";

import "./FilterPills.scss";

const FilterPills = ({
  language,
  timezone,
  locale,
  eventFilters,
  removeCallback,
  removeCallbackDates,
}) => {
  useEffect(() => {
    let currentTz = timezone ? timezone : Moment.globalTimezone;
    if (eventFilters.startDate !== null && eventFilters.endDate !== null) {
      let dateSpanStr =
        moment(eventFilters.startDate)
          .tz(currentTz)
          .locale(locale)
          .format("LL") +
        (eventFilters.dateSpan !== "today"
          ? " - " +
            moment(eventFilters.endDate)
              .tz(currentTz)
              .locale(locale)
              .format("LL z")
          : "");
      setPillLabelDates(dateSpanStr);
    }
  }, [
    locale,
    timezone,
    eventFilters.dateSpan,
    eventFilters.startDate,
    eventFilters.endDate,
  ]);

  const [pillLabelDates, setPillLabelDates] = useState();

  const eventDateSpanTranslated = useEventTranslation(
    "date",
    eventFilters.dateSpan
  );

  const productFiltersTranslated = useEventTranslationBulk(
    "product",
    eventFilters.product
  );

  const categoryFiltersTranslated = useEventTranslationBulk(
    "category",
    eventFilters.category
  );

  const typeFiltersTranslated = useEventTranslationBulk(
    "eventType",
    eventFilters.type
  );

  const languageFiltersTranslated = useEventTranslationBulk(
    "eventLanguage",
    eventFilters.language
  );

  const regionFiltersTranslated = useEventTranslationBulk(
    "region",
    eventFilters.region
  );

  return (
    <div className="pillPanel" key="pillPanel">
      {eventFilters.product &&
        productFiltersTranslated &&
        eventFilters.product.map((pillItem) => (
          <Pill
            key={pillItem}
            labels={{
              label: productFiltersTranslated[pillItem]
                ? productFiltersTranslated[pillItem]
                : "Invalid Product Filter",
              removeTitle: "Remove",
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
            className={
              productFiltersTranslated[pillItem] ? "validPill" : "invalidPill"
            }
            onRemove={() => {
              removeCallback("product", pillItem);
            }}
          />
        ))}
      {eventFilters.category &&
        categoryFiltersTranslated &&
        eventFilters.category.map((pillItem) => (
          <Pill
            key={pillItem}
            labels={{
              label: categoryFiltersTranslated[pillItem]
                ? categoryFiltersTranslated[pillItem]
                : "Invalid Category Filter",
              removeTitle: "Remove",
            }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
            }}
            className={
              categoryFiltersTranslated[pillItem] ? "validPill" : "invalidPill"
            }
            onRemove={() => {
              removeCallback("category", pillItem);
            }}
          />
        ))}
      {eventFilters.type &&
        typeFiltersTranslated &&
        eventFilters.type.map((pillItem) => (
          <Pill
            key={pillItem}
            labels={{
              label: typeFiltersTranslated[pillItem]
                ? typeFiltersTranslated[pillItem]
                : "Invalid Event Type Filter",
              removeTitle: "Remove",
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
            className={
              typeFiltersTranslated[pillItem] ? "validPill" : "invalidPill"
            }
            onRemove={() => {
              removeCallback("type", pillItem);
            }}
          />
        ))}
      {eventFilters.language &&
        languageFiltersTranslated &&
        eventFilters.language.map((pillItem) => (
          <Pill
            key={pillItem}
            labels={{
              label: languageFiltersTranslated[pillItem]
                ? languageFiltersTranslated[pillItem]
                : "Invalid Language Filter",
              removeTitle: "Remove",
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
            className={
              languageFiltersTranslated[pillItem] ? "validPill" : "invalidPill"
            }
            onRemove={() => {
              removeCallback("language", pillItem);
            }}
          />
        ))}
      {eventFilters.region &&
        regionFiltersTranslated &&
        eventFilters.region.map((pillItem) => (
          <Pill
            key={pillItem}
            labels={{
              label: regionFiltersTranslated[pillItem]
                ? regionFiltersTranslated[pillItem]
                : "Invalid Region Filter",
              removeTitle: "Remove",
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
            className={
              regionFiltersTranslated[pillItem] ? "validPill" : "invalidPill"
            }
            onRemove={() => {
              removeCallback("region", pillItem);
            }}
          />
        ))}
      {eventFilters.startDate && eventFilters.endDate && (
        <Pill
          key={eventFilters.endDate}
          labels={{
            label: eventDateSpanTranslated + ": " + pillLabelDates,
            removeTitle: "Remove",
          }}
          onClick={(e) => {
            e.preventDefault();
          }}
          onRemove={() => {
            removeCallbackDates();
          }}
        />
      )}
    </div>
  );
};

export default FilterPills;
