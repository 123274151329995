// ./src/App.js
import "url-search-params-polyfill";
import smoothscroll from "smoothscroll-polyfill";

import "./styles/App.scss";

import React, { Suspense, lazy, useEffect } from "react";
import { UserActions } from "./store/actions";

import { Route, Switch } from "react-router";
import { withCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import ShareEventContainer from "./containers/ShareEventContainer";
import GlobalNavContainer from "./containers/GlobalNavContainer";
import Home from "./containers/HomeContainer";
import Dashboard from "./containers/DashboardContainer";
import List from "./containers/FilterListContainer";
import Detail from "./containers/EventDetailContainer";
import TBIDComplete from "./containers/TBIDComplete";
import CoveoInit from "./containers/CoveoInit";
import { ErrorPage } from "./pages/ErrorPage";
import { Footer } from "./components/Footer";
import { LoginPage } from "./pages/LoginPage";
import ProductLandingPage from "./containers/ProductLandingPageContainer";
import ExpertCoachingPage from "./containers/ExpertCoachingContainer";

const { setUserLanguage, setUserTimezone, getUserInfo } = UserActions;

const HubList = lazy(() => import("./containers/HubListContainer"));
const HubFilterList = lazy(() => import("./containers/HubFilterListContainer"));
const CoveoSearch = lazy(() => import("./containers/CoveoSearchContainer"));

function useInitFromCookies(cookies) {
  const dispatch = useDispatch();

  let lng = cookies.get("language");
  let locale = cookies.get("locale");

  // TODO: Default to preferred_language
  // defaults here for now.
  let setLocale = locale ? locale : "en-na";
  let setLanguage = lng ? lng : "en";

  let filterObject = { language: setLanguage, locale: setLocale };
  dispatch(setUserLanguage(filterObject));
  dispatch(getUserInfo());
  let setTimezone = cookies.get("timezone");
  if (setTimezone) {
    let filterObjectTz = { timezone: setTimezone };
    dispatch(setUserTimezone(filterObjectTz));
  }
}

function decodeAndReplaceURL(url) {
  let decoded = decodeURIComponent(url);
  if (url !== decoded) {
    window.history.replaceState(null, '', decoded);
  }
}

const App = ({ cookies }) => {

  useEffect(() => {
    decodeAndReplaceURL(window.location.href);
  });

  useInitFromCookies(cookies);
  // safari scrollto polyfill
  smoothscroll.polyfill();

  return (
    <div className="csEventsApp">
      <CoveoInit />
      <ShareEventContainer />
      <GlobalNavContainer />
      <div role="main" className="mainContent">
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route
              exact
              key="/product/:id"
              path="/product/:id"
              component={ProductLandingPage}
            />
            <Route
              exact
              key="/events/:id"
              path="/events/:id"
              component={Detail}
            />
            <Route exact key="/search" path="/search" component={CoveoSearch} />
            <Route
              exact
              key="/portraits/:id"
              path="/portraits/:id"
              component={HubFilterList}
            />
            <Route
              exact
              key="/successplanevents/:id"
              path="/successplanevents/:id"
              component={ExpertCoachingPage}
            />
            <Route
              exact
              key="/auth/tbidcomplete"
              path="/auth/tbidcomplete"
              component={TBIDComplete}
            />
            <Route
              exact
              key="/login-page"
              path="/login-page"
              component={LoginPage}
            />
            <Route exact key="/events" path="/events">
              <CSSTransition
                appear
                in
                timeout={500}
                classNames="page"
                unmountOnExit
              >
                <List />
              </CSSTransition>
            </Route>
            <Route exact key="/portraits" path="/portraits">
              <CSSTransition
                appear
                in
                timeout={500}
                classNames="page"
                unmountOnExit
              >
                <HubList />
              </CSSTransition>
            </Route>
            <Route exact key="/dashboard" path="/dashboard">
              <CSSTransition
                appear
                in
                timeout={500}
                classNames="page"
                unmountOnExit
              >
                <Dashboard />
              </CSSTransition>
            </Route>
            <Route exact key="/" path="/">
              <CSSTransition
                appear
                in
                timeout={500}
                classNames="page"
                unmountOnExit
              >
                <Home />
              </CSSTransition>
            </Route>
            <Route render={(props) => <ErrorPage errorType="404" />} />
          </Switch>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default withCookies(App);
