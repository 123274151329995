// ./src/containers/EventFilterGroupContainer.js

import { connect } from "react-redux";
import { FeaturedEvents } from "../pages/FeaturedEvents";

const mapStateToProps = (state, ownProps) => {
  return {
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    favorites: state.csUser.favoritesAll,
    eventList: state.csEvent.featuredEvents,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    timezone: state.csUser.timezone,
    language: state.csUser.language,
  };
};

const FeaturedEventsContainer = connect(mapStateToProps)(FeaturedEvents);

export default FeaturedEventsContainer;
