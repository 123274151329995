// ./src/containers/EventFilterGroupContainer.js

import { connect } from "react-redux";
import { EventFilterGroup } from "../pages/EventFilterGroup";

const mapStateToProps = (state, ownProps) => {
  const mode = ownProps.mode ? ownProps.mode : "default"
  return {
    eventList: ownProps.eventList ? ownProps.eventList : state.csEvent.eventFilterGroups[ownProps.groupKey],
    timezone: state.csUser.timezone,
    language: state.csUser.language,
    title: ownProps.title,
    groupKey: ownProps.groupKey,
    groupConfig: ownProps.groupConfig,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    favorites: state.csUser.favoritesAll,
    mode: mode,
  };
};

const EventFilterGroupContainer = connect(mapStateToProps)(EventFilterGroup);

export default EventFilterGroupContainer;
