import { HubActions, EventActions } from "../actions";

const {
  LOAD_HUB_EVENTS_LIST,
  INIT_HUB,
  UPDATE_HUB_CONFIG,
  UPDATE_HUB_EVENT_FILTERS,
  UPDATE_HUB_EVENT_FILTERS_SILENT,
  CLEAR_HUB_EVENT_FILTERS,
  LOAD_HUB_DETAIL,
  LOAD_HUB_DETAIL_SUCCEED,
  LOAD_HUB_DETAIL_NOTFOUND,
  LOAD_HUBS_SUCCEED,
  LOAD_MORE_HUB_EVENTS,
  LOAD_HUB_FEATURED_EVENT_SUCCEED,
} = HubActions;

const { LOAD_MORE_EVENTS_SUCCEED, RENDER_EVENTS_LIST } = EventActions;

let initialState = {
  eventListCurrent: "default",
  hubDetail: {},
  hubDetailNotFound: "",
  hubList: [],
  eventHubFilters: {},
  eventHubFeatured: {},
  eventHubConfig: {},
  isLoading: false,
  isLoadingList: false,
};

export default function csHub(state = initialState, action) {
  switch (action.type) {
    case INIT_HUB:
      const hubConfigId = action.payload.hubId;
      const hubConfigFilters = action.payload.hubFilterConfig;
      const hubConfigLocks = action.payload.hubFilterLocks;
      const numPerPage = action.payload.numPerPage ? action.payload.numPerPage : 24;

      let hubInitConfig = {
        search: "",
        product: [],
        category: [],
        region: [],
        language: [],
        type: [],
        startDate: null,
        endDate: null,
        numPerPage: numPerPage,
        page: 0,
        includeRecorded: true,
        sort: "date",
        dateSpan: "custom",
      };
      return {
        ...state,
        // eventListCurrent: hubConfigId,
        eventHubFilters: {
          ...state.eventHubFilters,
          [hubConfigId]: hubInitConfig,
        },
        eventHubConfig: {
          ...state.eventHubConfig,
          [hubConfigId]: {
            filterLocks: hubConfigLocks,
            filterConfig: hubConfigFilters,
          },
        },
      };
    case UPDATE_HUB_CONFIG:
      const hubUpdateConfigId = action.payload.hubId;
      const hubUpdateConfigFilters = action.payload.hubFilterConfig;

      return {
        ...state,
        eventHubConfig: {
          ...state.eventHubConfig,
          [hubUpdateConfigId]: {
            filterConfig: hubUpdateConfigFilters,
          },
        },
      };
    case LOAD_HUB_EVENTS_LIST:
      const hubLoadId = action.eventFilters.hubId;
      return {
        ...state,
        isLoadingList: true,
        // eventListCurrent: hubLoadId,
        eventHubFilters: {
          ...state.eventHubFilters,
          [hubLoadId]: {
            ...state.eventHubFilters[hubLoadId],
            page: 0,
          },
        },
      };
    case UPDATE_HUB_EVENT_FILTERS:
    case UPDATE_HUB_EVENT_FILTERS_SILENT:
      const hubId = action.eventFilters.hubId;
      const hubFilterParam = action.eventFilters.key;
      const hubFilterValues = action.eventFilters.values;
      const hubSetLoading = true;
      return {
        ...state,
        isLoadingList: hubSetLoading,
        eventHubFilters: {
          ...state.eventHubFilters,
          [hubId]: {
            ...state.eventHubFilters[hubId],
            [hubFilterParam]: hubFilterValues,
            page: 0,
          },
        },
      };
    case CLEAR_HUB_EVENT_FILTERS:
      const hubClearId = action.eventFilters.hubId;
      const hubClearSetLoading = true;

      const hubResetParams = {
        search: "",
        product: [],
        category: [],
        region: [],
        language: [],
        type: [],
        startDate: null,
        endDate: null,
        numPerPage: 24,
        page: 0,
        includeRecorded: true,
        sort: "date",
        dateSpan: "custom",
      };
      return {
        ...state,
        isLoadingList: hubClearSetLoading,
        eventHubFilters: {
          ...state.eventHubFilters,
          [hubClearId]: {
            ...state.eventHubFilters[hubClearId],
            ...hubResetParams,
          },
        },
      };
    case LOAD_HUB_DETAIL:
      return {
        ...state,
        isLoading: true,
        hubDetailNotFound: "",
      };
    case LOAD_HUB_DETAIL_SUCCEED:
      const newHubDetail = action.singleHub;
      return {
        ...state,
        eventHubNotFound: "",
        hubDetail: newHubDetail,
        isLoading: false,
      };
    case LOAD_HUB_DETAIL_NOTFOUND:
      return {
        ...state,
        isLoading: false,
        hubDetailNotFound: action.hubId,
      };
    case LOAD_HUBS_SUCCEED:
      return {
        ...state,
        hubList: action.hubList,
      };
    case LOAD_MORE_HUB_EVENTS:
      const moreHubId = action.payload.hubId;
      return {
        ...state,
        isLoading: true,
        eventHubFilters: {
          ...state.eventHubFilters,
          [moreHubId]: {
            ...state.eventHubFilters[moreHubId],
            page: state.eventHubFilters[moreHubId].page + 1,
          },
        },
      };
    case LOAD_MORE_EVENTS_SUCCEED:
    case RENDER_EVENTS_LIST:
      return {
        ...state,
        isLoading: false,
        isLoadingList: false,
      };
    case LOAD_HUB_FEATURED_EVENT_SUCCEED:
      const newHubEventDetail = action.singleEvent;
      return {
        ...state,
        eventHubFeatured: newHubEventDetail,
        criticalError: false,
      };
    default:
      return state;
  }
}
