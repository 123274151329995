// use config for languages
import config from "@/config/config.json";

// supported languages
const supportedLanguages = config.filters.language.options.map(
  (option) => option.value
);

// kick off ligin
export function initiateLogin(mock = false, path = null, mode = "manual") {
  // get window location
  const statePath =
    path === null
      ? window.location.pathname + encodeURIComponent(window.location.search)
      : path;
  // call index.html
  window.LoginInitiated();
  // call tbidredirect?state=StartingUrl&mode=manual
  const loginRedirect = mock
    ? `/auth/tbidcomplete?state=${statePath}`
    : `/tbidredirect?state=${statePath}&mode=${mode}`;
  document.location.href = loginRedirect;
}

export function tbidNavLoginInitiated() {
  window.LoginInitiated();
}

export function isAuthenticated() {
  let logged = localStorage.getItem('loginInitiated') === 'true' && 
      // eslint-disable-next-line eqeqeq
      localStorage.getItem('CS_USER_STORAGE_KEY') != undefined && 
      // eslint-disable-next-line eqeqeq
      localStorage.getItem('CS_USER_STORAGE_KEY') != '';
  return logged;
}

export function getPreferredLanguage(language){
  // Default english if language is not provided
  if (!language) {
    return "en";
  }
  // Normalize the language code by removing region codes and converting to lowercase
  const normalizedCode = language.toLowerCase().split(/[_-]/)[0];

  // Check if the normalized language code is supported
  if (supportedLanguages.includes(normalizedCode)) {
    return normalizedCode;
  }

  // catch 'ja' and map to 'jp'
  if (normalizedCode === "ja") {
    return "jp";
  }

  // Default english if language is not supported
  return "en";
}