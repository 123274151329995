import { loadAdvancedSearchQueryActions } from "@coveo/headless";
import { headlessEngine } from "@/configureCoveo";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Checkbox from "@salesforce/design-system-react/components/checkbox";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment-timezone";
import Moment from "react-moment";

import "./Facet.scss";

export const FacetDateRange = (props) => {
  const { controller, searchParams, searchParametersController, timezone, locale, isMobile } =
    props;
  const [state, setState] = useState(controller.state);
  const [searchParametersState, setSearchParametersState] = useState(
    searchParametersController.state
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasUserExpanded, setHasUserExpanded] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState();
  let start_date_id = "start_date_id";
  let end_date_id = "end_date_id";
  const { updateAdvancedSearchQueries } =
    loadAdvancedSearchQueryActions(headlessEngine);

  const { t } = useTranslation();

  const dateLabels = [t("Today"), t("This Week"), t("Next Week")];

  useEffect(() => {
    //console.log("subscribing facet");
    const facetSubscribe = controller.subscribe(() =>
      setState(controller.state)
    );
    const searchParametersSubcribe = searchParametersController.subscribe(() =>
      setSearchParametersState(searchParametersController.state)
    );

    return () => {
      facetSubscribe();
      searchParametersSubcribe();
    };
  }, [controller, searchParametersController]);

  //console.log("search params: ", searchParametersState.parameters);

  const cqState = searchParametersState.parameters.cq;
  const tbState = searchParametersState.parameters.tab;

  /* disable this facet for portraits */
  if (
    !state.values.length ||
    (cqState && (cqState.includes("topic"))) ||
    (tbState && (tbState === "Topics"))
  ) {
    return null;
  }

  if (searchParams && searchParams.parameters) {
    const tbState = searchParams.parameters.tab;
    if ( tbState === "Coaching") {
      return null;
    }
  }

  if (
    !hasUserExpanded &&
    (state.values.some((value) => {
      return value.state === "selected";
    }) ||
      searchParametersState.parameters.aq)
  ) {
    setIsExpanded(true);
    setHasUserExpanded(true);

    if (searchParametersState.parameters.aq) {
      //format example: @successeventenddate=2021/11/01..2021/11/06
      let aq = searchParametersState.parameters.aq;
      let regex = /(\d{4}\/\d{2}\/\d{2}\.\.\d{4}\/\d{2}\/\d{2})+/g;
      let dateRange = aq.substring(aq.search(regex)).split("..");
      setStartDate(moment(dateRange[0]));
      setEndDate(moment(dateRange[1]));
    }
  }

  const decoratedOnClick = () => {
    setIsExpanded(!isExpanded);
    setHasUserExpanded(true);
  };

  const triggerDateQuery = (start, end) => {
    headlessEngine.dispatch(
      updateAdvancedSearchQueries({
        aq: `@successeventenddate=${start}..${end}`,
      })
    );
    controller.deselectAll();
  };

  const clearDateQuery = () => {
    headlessEngine.dispatch(
      updateAdvancedSearchQueries({
        aq: "",
      })
    );
    setStartDate(null);
    setEndDate(null);
  };

  if (
    !searchParametersState.parameters.aq &&
    startDate !== null &&
    endDate !== null
  ) {
    setStartDate(null);
    setEndDate(null);
  }

  return (
    <ListGroup.Item className="filterBarGroup coveo--facet date-facet">
      <Accordion
        defaultActiveKey="0"
        activeKey={isExpanded ? "1" : "0"}
        className={isExpanded ? "expanded" : "collapsed"}
      >
        <Card className="filterCard">
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              onClick={decoratedOnClick}
            >
              {t(props.title)}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <DateRangePicker
                startDatePlaceholderText={t("Start Date")}
                endDatePlaceholderText={t("End Date")}
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId={start_date_id} // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId={end_date_id} // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                  if (startDate && endDate) {
                    let currentTz = timezone ? timezone : Moment.globalTimezone;
                    startDate = startDate.tz(currentTz).clone().startOf("day");
                    endDate = endDate.tz(currentTz).clone().endOf("day");
                  }

                  setStartDate(startDate);
                  setEndDate(endDate);

                  if (startDate && endDate) {
                    triggerDateQuery(
                      startDate.format("YYYY/MM/DD"),
                      endDate.format("YYYY/MM/DD")
                    );
                  }
                }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                minimumNights={0}
                numberOfMonths={1}
                openDirection={isMobile ? "down" : "up"}
                renderMonthElement={({ month }) =>
                  moment(month).locale(locale).format("MMMM YYYY")
                }
              />
              {state.values.map((value, index) => (
                <Checkbox
                  key={index}
                  assistiveText={{
                    label: "Default",
                  }}
                  id={`date-${index}`}
                  labels={{
                    label: `${dateLabels[index]}`,
                  }}
                  disabled={state.isLoading}
                  className={state.isLoading ? "filter-disabled" : ""}
                  checked={controller.isValueSelected(value)}
                  onChange={() => {
                    clearDateQuery();
                    controller.toggleSingleSelect(value);
                  }}
                />
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </ListGroup.Item>
  );
};
