/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ScriptLoader from "react-script-loader-hoc";

const VIDYARD_EMBED_JS_URL = "https://play.vidyard.com/embed/v4.js";

function VidyardPlayer({
  scriptsLoadedSuccessfully,
  maxWidth,
  maxHeight,
  type,
  uuid,
  aspect,
  email,
  onLoadCallback,
}) {
  const containerRef = React.useRef();

  React.useEffect(() => {
    if (scriptsLoadedSuccessfully) {
      window.VidyardV4.api
        .renderPlayer({
          aspect,
          container: containerRef.current,
          height: maxHeight,
          type,
          uuid,
          width: maxWidth,
          vyemail: email,
          /*,
          vyemail:
            "test@test.com" encodeURIComponent(
            JSON.stringify({ location: "here", more: "yes" })
          ),,*/
        })
        .then((player) => {
          if (onLoadCallback) {
            onLoadCallback(player, window.VidyardV4.api);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e.message);
        });
    }
  }, [scriptsLoadedSuccessfully]);
  //console.log(uuid);
  //when we call vidyard I believe theres a 'vyemail=<email>" appended to the end of the URL
  return <div ref={containerRef} />;
}

export default ScriptLoader(VIDYARD_EMBED_JS_URL)(VidyardPlayer);
