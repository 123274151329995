import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Checkbox from "@salesforce/design-system-react/components/checkbox";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const FilterMultiSelect = ({
  inputkey,
  title,
  options,
  selected,
  expanded,
  premierTypes,
  excludePremier,
  changeCallback,
}) => {
  const [isChecked, setIsChecked] = useState(selected);
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [hasUserExpanded, setHasUserExpanded] = useState(false);
  const [isMoreVisible, setIsMoreVisible] = useState(false);

  const visibleCutoff = 5;

  useEffect(() => {
    setIsChecked(selected);

    let selectedPastVisible = false;
    if (selected) {
      selectedPastVisible = options.some((optvalue, optindex) => {
        return selected.includes(optvalue.value) && optindex > visibleCutoff;
      });
    }
    if (inputkey !== "product" || selectedPastVisible) {
      setIsMoreVisible(true);
    }

    if (selected && selected.length > 0 && !hasUserExpanded) {
      setIsExpanded(true);
    }
  }, [selected, inputkey, options, hasUserExpanded]);

  const handleSingleCheck = (value) => {
    const option_value = value;

    if (isChecked.includes(option_value)) {
      setIsChecked(
        isChecked.filter((checked_name) => checked_name !== option_value)
      );
      changeCallback(
        inputkey,
        isChecked.filter((checked_name) => checked_name !== option_value)
      );
      return;
    } else {
      isChecked.push(option_value);
      setIsChecked([...isChecked]);
      changeCallback(inputkey, isChecked);
    }
  };

  const decoratedOnClick = () => {
    setIsExpanded(!isExpanded);
    setHasUserExpanded(true);
  };

  const showMore = () => {
    setIsMoreVisible(true);
  };

  const visibleOptions = !isMoreVisible
    ? options.slice(0, visibleCutoff)
    : options;

  return (
    <div>
      <Accordion
        defaultActiveKey="0"
        activeKey={isExpanded ? "1" : "0"}
        className={isExpanded ? "expanded" : "collapsed"}
      >
        <Card className="filterCard">
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              onClick={decoratedOnClick}
            >
              {t(title)}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {visibleOptions.map((option, index) => (
                <Checkbox
                  key={option.value}
                  assistiveText={{
                    label: "Default",
                  }}
                  id={option.value}
                  labels={{
                    label: `${t(option.text)}${
                      premierTypes && premierTypes.includes(option.value)
                        ? ` (${t("Premier Only filter designation")})`
                        : ""
                    }`,
                  }}
                  disabled={
                    excludePremier &&
                    premierTypes &&
                    premierTypes.includes(option.value)
                  }
                  className={
                    excludePremier &&
                    premierTypes &&
                    premierTypes.includes(option.value)
                      ? "filter-disabled"
                      : ""
                  }
                  checked={isChecked.includes(option.value)}
                  onChange={(e, checked) => {
                    handleSingleCheck(option.value);
                  }}
                />
              ))}
              {!isMoreVisible && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    showMore();
                  }}
                  variant="link"
                  className="inlineLink"
                >
                  {t("See More")}
                </Button>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default FilterMultiSelect;
