// ./src/containers/EventListContainer.js

import { connect } from "react-redux";
import { EventList } from "../pages/EventList";

const mapStateToProps = (state) => {
  return {
    eventList: state.csEvent.eventList,
    timezone: state.csUser.timezone,
    locale: state.csUser.locale,
    language: state.csUser.language,
    eventFilters: state.csEvent.eventFilters,
    hubFilters: state.csHub.eventHubFilters,
    numEvents: state.csEvent.totalEvents,
    numPages: state.csEvent.totalPages,
    isLoading: state.csEvent.isLoading,
    listInit: state.csEvent.listInit,
    listLoading: state.csEvent.isLoadingList,
    hubLoading: state.csHub.isLoading,
    hubListLoading: state.csHub.isLoadingList,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    favorites: state.csUser.favoritesAll,
  };
};

const EventListContainer = connect(mapStateToProps)(EventList);

export default EventListContainer;
