import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./ThreeColComponent.scss";

const ThreeColComponent = ({ title, childData, showBg }) => {
    const { t } = useTranslation();
    const containerClass = showBg ? "showBg" : ""

    useEffect(() => {

    });

    return (
        <Container className={`threeColWrapper ${containerClass}`}>
            {showBg && (
                <div className={`maskedBg`}>
                <div className="mask"></div>
            </div>
            )}
            <h2 className="groupHeader">
                {t(title)}
            </h2>
            <Row className="threeCol-row">
                {childData.map((obj, idx) => (
                    <Col className="threeCol-col">
                    <h3 className="groupHeader">{t(`${obj.title}`)}</h3>
                    <p className="description">{t(`${obj.description}`)}</p>
                    <div className="groupLinkSet">
                        <a className="d-inline-block"
                            href={obj.href}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t(`${obj.cta}`)}
                        </a>
                    </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

ThreeColComponent.defaultProps = {
    childData: {},
    showBg: true,
};

ThreeColComponent.propTypes = {
    childData: PropTypes.object,
};

export default ThreeColComponent;