/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export function loadString(key) {
  return localStorage.getItem(key)
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function saveString(key, value) {
  localStorage.setItem(key, value)
  return true
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export function load(key) {
  const almostThere = localStorage.getItem(key)
  return almostThere && JSON.parse(almostThere)
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function save(key, value) {
  localStorage.setItem(
    key,
    typeof value === "object"
    ? JSON.stringify(value)
    : value
  )
  return true
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key) {
  localStorage.removeItem(key)
}

/**
 * Burn it all to the ground.
 */
export function clear() {
  localStorage.clear()
}
