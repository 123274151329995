// ./src/containers/GlobalNavContainer.js

import { connect } from "react-redux";
import { GlobalNav } from "../pages/GlobalNav";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.csUser.user,
    appConfig: state.csEvent.appConfig,
    search: state.csEvent.eventFilters.search,
    language: state.csUser.language,
    timezone: state.csUser.timezone,
    isUser: Boolean(state.csUser.user),
    tbidUser: Boolean(state.csUser.user && state.csUser.user.tb_id),
    shareVisible: state.csOther.shareVisible,
    shareUrl: state.csOther.shareUrl,
    authVisible: state.csOther.authVisible,
    authErrorMessage: state.csOther.authErrorMessage,
  };
};

const GlobalNavContainer = connect(mapStateToProps)(GlobalNav);

export default GlobalNavContainer;
