import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as LikeOff } from "../../assets/images/heart-icon.svg";
import { ReactComponent as LikeOffDetail } from "../../assets/images/heart-icon-white.svg";
import { ReactComponent as LikeOn } from "../../assets/images/heart-icon-active.svg";
import { ReactComponent as LikeOnDetail } from "../../assets/images/heart-icon-active-white.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserActions } from "../../store/actions";
import { IS_MOCK_TBID_USER } from "../../config/environment";
import { initiateLogin } from "@/utils/userHelper";
import { sendCoveoCustomEvent } from "@/utils/analyticsHelper";
import "./Like.scss";

const { setUserFavorite, removeUserFavorite } = UserActions;

const Like = ({ id, tb_id, event, detail, isFavorite, t }) => {
  const [isLiked, setLiked] = useState(isFavorite);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLiked(isFavorite);
  }, [isFavorite]);

  const toggleLiked = (e) => {
    if (tb_id) {
      setLiked(!isLiked);
      if (!isLiked) {
        // log favorite add event to coveo
        sendCoveoCustomEvent("userEventFavorite", "addFavorite", event);
        dispatch(setUserFavorite({ eventId: id, tbidId: tb_id, event }));
      } else {
        // log favorite remove event to coveo
        sendCoveoCustomEvent("userEventFavorite", "removeFavorite", event);
        dispatch(removeUserFavorite({ eventId: id, tbidId: tb_id }));
      }
    } else {
      setModalShow(true);
    }
  };

  const loginWithTrailblazer = () => {
    setModalShow(false);
    initiateLogin(IS_MOCK_TBID_USER);
  };

  const RenderModal = () => {
    return (
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="settingsModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Login to Favorite")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-like_container">
            <div className="modal-like_title">{t("Howdy, Trailblazer!")}</div>
            <div className="modal-like_description">
              {t(
                "In order to get the most from Customer Success Events you will need to login with Trailblazer."
              )}
            </div>
            <div className="modal-like_description">
              {t(
                "Logging in will allow you to see the events you've favorited as well as access to view your upcoming and past events."
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="mr-auto modal-like_btn"
            onClick={() => loginWithTrailblazer()}
          >
            {t("Login with Trailblazer")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <RenderModal />
      <div className="like-container like-btn" onClick={toggleLiked}>
        {isLiked ? (
          !detail ? (
            <LikeOn />
          ) : (
            <LikeOnDetail />
          )
        ) : !detail ? (
          <LikeOff />
        ) : (
          <LikeOffDetail />
        )}
      </div>
    </>
  );
};

export default Like;
