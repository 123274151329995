export const COUNTRIES_W_DISCLAIMER = ["JP"];
export const COUNTRIES_W_OPT_IN = ["CA", "US"];
export const COUNTRIES_W_STATE = ["JP", "CA", "US"];

export const EMPLOYEES = [
  { value: "1-20 employees", label: "1-20 employees" },
  { value: "21-100 employees", label: "21-100 employees" },
  { value: "101-500 employees", label: "101-500 employees" },
  { value: "501-3500 employees", label: "501-3500 employees" },
  { value: "3501+ employees", label: "3501+ employees" },
];

export const COUNTRIES = [
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX", optIn: true },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD", optIn: true },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Ascension Island", value: "AC" },
  { label: "Australia", value: "AU", optIn: true },
  { label: "Austria", value: "AT", optIn: true },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY", optIn: true },
  { label: "Belgium", value: "BE", optIn: true },
  { label: "Belize", value: "BZ", optIn: true },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO", optIn: true },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG", optIn: true },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA", optIn: true },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN", optIn: true },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO", optIn: true },
  { label: "Comoros", value: "KM" },
  { label: "Congo (Republic)", value: "CG" },
  { label: "Congo (Democratic Republic)", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote D'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR", optIn: true },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY", optIn: true },
  { label: "Czech Republic", value: "CZ", optIn: true },
  { label: "Denmark", value: "DK", optIn: true },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "European Union", value: "EU" },
  { label: "Falkland Islands (Malvinas)", value: "FK", optIn: true },
  { label: "Faroe Islands", value: "FO", optIn: true },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI", optIn: true },
  { label: "France", value: "FR", optIn: true },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF", optIn: true },
  { label: "French Southern Territories", value: "TF", optIn: true },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE", optIn: true },
  { label: "Germany", value: "DE", optIn: true },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR", optIn: true },
  { label: "Greenland", value: "GL", optIn: true },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP", optIn: true },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG", optIn: true },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU", optIn: true },
  { label: "Iceland", value: "IS", optIn: true },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran (Islamic Republic Of)", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM", optIn: true },
  { label: "Israel", value: "IL", optIn: true },
  { label: "Italy", value: "IT", optIn: true },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP", optIn: true },
  { label: "Jersey", value: "JE", optIn: true },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE", optIn: true },
  { label: "Kiribati", value: "KI" },
  { label: "South Korea", value: "KR" },
  { label: "Kuwait", value: "KW", optIn: true },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI", optIn: true },
  { label: "Lithuania", value: "LT", optIn: true },
  { label: "Luxembourg", value: "LU", optIn: true },
  { label: "Macao", value: "MO" },
  {
    label: "Macedonia, The Former Yugoslav Republic of",
    value: "MK",
    optIn: true,
  },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY", optIn: true },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT", optIn: true },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ", optIn: true },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT", optIn: true },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME", optIn: true },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA", optIn: true },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL", optIn: true },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC", optIn: true },
  { label: "New Zealand", value: "NZ", optIn: true },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU", optIn: true },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO", optIn: true },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestine", value: "PS" },
  { label: "Panama", value: "PA", optIn: true },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH", optIn: true },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL", optIn: true },
  { label: "Portugal", value: "PT", optIn: true },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE", optIn: true },
  { label: "Romania", value: "RO", optIn: true },
  { label: "Russia", value: "RU", optIn: true },
  { label: "Rwanda", value: "RW" },
  { label: "Saint Helena", value: "SH", optIn: true },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM", optIn: true },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM", optIn: true },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA", optIn: true },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS", optIn: true },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK", optIn: true },
  { label: "Slovenia", value: "SI", optIn: true },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA", optIn: true },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
    optIn: true,
  },
  { label: "Spain", value: "ES", optIn: true },
  { label: "Sri Lanka", value: "LK", optIn: true },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE", optIn: true },
  { label: "Switzerland", value: "CH", optIn: true },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR", optIn: true },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA", optIn: true },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN", optIn: true },
  { label: "Virgin Islands (British)", value: "VG" },
  { label: "Virgin Islands (U.S.)", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

export const CA_STATES = [
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "New Brunswick", value: "NB" },
  { label: "Newfoundland and Labrador", value: "NL" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Nunavut", value: "NU" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
];

export const JP_STATES = [
  { label: "Aichi", value: "AI" },
  { label: "Akita", value: "AK" },
  { label: "Aomori", value: "AO" },
  { label: "Chiba", value: "CH" },
  { label: "Ehime", value: "EH" },
  { label: "Fukui", value: "FI" },
  { label: "Fukuoka", value: "FO" },
  { label: "Fukushima", value: "FS" },
  { label: "Gifu", value: "GF" },
  { label: "Gunma", value: "10" },
  { label: "Hiroshima", value: "HS" },
  { label: "Hokkaido", value: "HK" },
  { label: "Hyogo", value: "HG" },
  { label: "Ibaraki", value: "IB" },
  { label: "Ishikawa", value: "IS" },
  { label: "Iwate", value: "IW" },
  { label: "Kagawa", value: "KG" },
  { label: "Kagoshima", value: "KS" },
  { label: "Kanagawa", value: "KN" },
  { label: "Kochi", value: "KC" },
  { label: "Kumamoto", value: "KM" },
  { label: "Kyoto", value: "KY" },
  { label: "Mie", value: "ME" },
  { label: "Miyagi", value: "MG" },
  { label: "Miyazaki", value: "MZ" },
  { label: "Nagano", value: "NN" },
  { label: "Nagasaki", value: "NS" },
  { label: "Nara", value: "NR" },
  { label: "Niigata", value: "NI" },
  { label: "Oita", value: "OT" },
  { label: "Okayama", value: "OY" },
  { label: "Okinawa", value: "ON" },
  { label: "Osaka", value: "OS" },
  { label: "Saga", value: "SG" },
  { label: "Saitama", value: "ST" },
  { label: "Shiga", value: "SH" },
  { label: "Shimane", value: "SM" },
  { label: "Shizuoka", value: "SZ" },
  { label: "Tochigi", value: "TC" },
  { label: "Tokushima", value: "TS" },
  { label: "Tokyo", value: "TK" },
  { label: "Tottori", value: "TT" },
  { label: "Toyama", value: "TY" },
  { label: "Wakayama", value: "WK" },
  { label: "Yamagata", value: "YT" },
  { label: "Yamaguchi", value: "YC" },
  { label: "Yamanashi", value: "YN" },
];

export const US_STATES = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];
