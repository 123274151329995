/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "react-router-dom";

const classNameListToRedirect = [
  "card-body",
  "card-header",
  "eventTitle",
  "eventOverview",
  "eventHeaderSub",
  "eyebrowLabel",
  "eventTagWrapper",
  "dateCallout",
  "eventSub",
  "portraitFeatureDetails",
  "eventCard",
  "cardBodyInner",
  "labelCallout",
  "portraitVideoIcon",
];

const EventLink = (props) => {
  const goTo = (e) => {
    let thisTarget = e.target.className
      ? e.target.className
      : e.target.parentElement.className;

    //console.log(thisTarget);
    if (
      !classNameListToRedirect.find((item) => String(thisTarget).includes(item))
    )
      e.preventDefault();
  };

  return props.href ? (
    <a {...props} onClick={(e) => goTo(e)} />
  ) : (
    <Link {...props} onClick={(e) => goTo(e)} />
  );
};

export default EventLink;
