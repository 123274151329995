import { 
  loadAdvancedSearchQueryActions,
  loadSearchActions,
  loadGenericAnalyticsActions,
  loadBreadcrumbActions
} from "@coveo/headless";
import { headlessEngine } from "@/configureCoveo";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

import "./QuerySummary.scss";

export const QuerySummary = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);
  const { updateAdvancedSearchQueries } = loadAdvancedSearchQueryActions(headlessEngine);
  const { logSearchEvent } = loadGenericAnalyticsActions(headlessEngine);
  const { executeSearch } = loadSearchActions(headlessEngine);
  const { deselectAllFacets } = loadBreadcrumbActions(headlessEngine);

  const { t } = useTranslation();

  useEffect(
    () => controller.subscribe(() => setState(controller.state)),
    [controller]
  );

  const clearFilters = () => {
    headlessEngine.dispatch(
      updateAdvancedSearchQueries({
        aq: ""
      })
    );
    headlessEngine.dispatch(deselectAllFacets());
    headlessEngine.dispatch(executeSearch(logSearchEvent({
      evt: "allFiltersClear"
    })));
  }

  return (
    <div className="result-summary">
      <div>
        {state.lastResult > 0 ? (
          <>
            <strong>
            {state.firstResult}
            {" - "}
            {state.lastResult}
            {` ${t("of")} `}
            {state.total}
            </strong>
          </>
        ) : (
          <>{t("No")}</>
        )}

        {state.hasQuery ? (
          <>
            {` ${t("results")} ${t("for")} `}
            &ldquo;{state.query}&rdquo;
          </>
        ) : (
          <>{` ${t("Results")}`}</>
        )}
      </div>
      <Button
        variant="link"
        onClick={clearFilters}
      >
        {t("Clear All Filters")}
      </Button>
    </div>
  );
};
