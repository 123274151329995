import { save, load } from "../../utils/storage";
import { CS_USER_STORAGE_KEY } from "../../utils/storage/keys";

export const SET_USER_TIMEZONE = "SET_USER_TIMEZONE";
export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";
export const SET_USER_SEARCH_TOKEN = "SET_USER_SEARCH_TOKEN";
export const SET_USER_API_TOKEN = "SET_USER_API_TOKEN";
export const SET_USER_INFO = "SET_USER_INFO";
export const GET_USER_INFO = "GET_USER_INFO";
export const USER_FAVORITES = "USER_FAVORITES";
export const USER_FAVORITES_SUCCEED = "USER_FAVORITES_SUCCEED";
export const USER_FAVORITES_ALL = "USER_FAVORITES_ALL";
export const USER_FAVORITES_ALL_SUCCEED = "USER_FAVORITES_ALL_SUCCEED";
export const USER_REGISTRATIONS = "USER_REGISTRATIONS";
export const USER_REGISTRATIONS_SUCCEED = "USER_REGISTRATIONS_SUCCEED";
export const SET_USER_FAVORITE_SUCCEED = "SET_USER_FAVORITE_SUCCEED";
export const DELETE_USER_FAVORITE_SUCCEED = "DELETE_USER_FAVORITE_SUCCEED";
export const DELETE_USER_FAVORITE = "DELETE_USER_FAVORITE";
export const SET_USER_FAVORITE = "SET_USER_FAVORITE";
export const USER_FAVORITES_COUNTER = "USER_FAVORITES_COUNTER";
export const USER_UPCOMING_COUNTER = "USER_UPCOMING_COUNTER";
export const USER_PAST_COUNTER = "USER_PAST_COUNTER";

export function saveUserToLocalStorage(user) {
  save(CS_USER_STORAGE_KEY, user);
}

export function saveUserKeyToLocalStorage(key, value) {
  let currentUser = load(CS_USER_STORAGE_KEY);
  currentUser[key] = value;
  save(CS_USER_STORAGE_KEY, currentUser);
}

export function setUserTimezone(payload) {
  return { type: SET_USER_TIMEZONE, payload };
}

export function setUserLanguage(payload) {
  return { type: SET_USER_LANGUAGE, payload };
}

export function setUserSearchToken(payload) {
  return { type: SET_USER_SEARCH_TOKEN, payload };
}

export function setUserApiToken(payload) {
  console.log("save user key to local storage");
  saveUserKeyToLocalStorage("api_token", payload.token);
  return { type: SET_USER_API_TOKEN, payload };
}

export function setUserInfo(payload) {
  saveUserToLocalStorage(payload);
  return { type: SET_USER_INFO, payload };
}

export function getUserInfo(payload) {
  return { type: GET_USER_INFO, payload };
}

export function setUserFavoritesCounter(payload) {
  return {
    type: USER_FAVORITES_COUNTER,
    payload,
  };
}

export function setUserUpcomingCounter(payload) {
  return {
    type: USER_UPCOMING_COUNTER,
    payload,
  };
}

export function setUserPastCounter(payload) {
  return {
    type: USER_PAST_COUNTER,
    payload,
  };
}

export function getUserFavorites(payload) {
  return {
    type: USER_FAVORITES,
    payload,
  };
}

export function getUserFavoritesAll(payload) {
  return {
    type: USER_FAVORITES_ALL,
    payload,
  };
}

export function getUserRegistrations(payload) {
  return {
    type: USER_REGISTRATIONS,
    payload,
  };
}

export function setUserFavorite(payload) {
  return {
    type: SET_USER_FAVORITE,
    payload,
  };
}

export function removeUserFavorite(payload) {
  return {
    type: DELETE_USER_FAVORITE,
    payload,
  };
}

export function getUserFavoritesSucceed(payload) {
  return { type: USER_FAVORITES_SUCCEED, payload };
}

export function getUserFavoritesAllSucceed(payload) {
  return { type: USER_FAVORITES_ALL_SUCCEED, payload };
}

export function getUserRegistrationsSucceed(payload) {
  return { type: USER_REGISTRATIONS_SUCCEED, payload };
}

export function setUserFavoriteSucceed(payload) {
  return { type: SET_USER_FAVORITE_SUCCEED, payload };
}

export function deleteUserFavoriteSucceed(payload) {
  return { type: DELETE_USER_FAVORITE_SUCCEED, payload };
}
