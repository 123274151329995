import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Checkbox from "@salesforce/design-system-react/components/checkbox";

import "./Facet.scss";

export const FacetToggle = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  const { t } = useTranslation();

  useEffect(
    () => controller.subscribe(() => setState(controller.state)),
    [controller]
  );

  const tb_user_plan = "standard";

  return (
    <div
      className="filterBarGroup coveo--facet list-group-item toggleGroup"
      key="toggleFacetGroup"
    >
      {state.values.map(
        (value) =>
          value.value === "standard" && (
            <div key={`wrapper${value}`}>
              <Checkbox
                labels={{
                  label: t("Premier Exclude Toggle CTA Text"),
                }}
                id="toggle-exclude-premier"
                variant="toggle"
                className="filterToggleExcludePremier"
                checked={controller.isValueSelected(value)}
                onChange={() => controller.toggleSelect(value)}
                size="medium"
              />
              <a
                className="toggleInfoLabel"
                href={`https://successcenter.salesforce.com/s/product?name=success-salesforce_success_plans&utm_source=success_events&utm_medium=${
                  tb_user_plan && tb_user_plan === "standard"
                    ? "standard"
                    : "unkwn"
                }_hideprem_toggle`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Premier Exclude Toggle Info Link Text")}
              </a>
            </div>
          )
      )}
    </div>
  );
};
