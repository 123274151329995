import React from "react";
import PropTypes from "prop-types";
import { useFilterLinkConfig } from "../../hooks/";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Result } from "@/components/Coveo/Result";

import { useEffect, useState } from "react";

import { recommendationController } from "@/controllers/CoveoControllers";

import "./TrendingTopics.scss";

var recomendationsRefreshed = false;

const TrendingTopics = ({ 
    eventList,
    eventExclude,
    groupConfig,
    favorites,
    tb_id,
    tb_user_plan,
    mode, 
}) => {

    const [state, setState] = useState(recommendationController.state);
    if (!recomendationsRefreshed) {
        recomendationsRefreshed = true;
        recommendationController.refresh();
    }

    useEffect(() => recommendationController.subscribe(() => setState(recommendationController.state)), []);

    let filterConfigParams = useFilterLinkConfig(groupConfig.filters);
    const favoritesIds = favorites ? favorites : [];

    const checkFavorite = (eventId) => {
        return favoritesIds.includes(eventId);
    };
    const { t } = useTranslation();

    let groupStyle = "";
    groupStyle = mode === "featured" ? "featureCol" : groupStyle;
    groupStyle = mode === "bottomborder" ? "bottomBorder" : groupStyle;
    groupStyle = mode === "noborder" ? "noBorder" : groupStyle;

    const seeMoreLink = !groupConfig.jumplink
    ? {
        pathname: "/events",
        search: filterConfigParams,
      }
    : {
        pathname: "/search",
        search: groupConfig.jumplink
      };

    const seeMoreTitle = groupConfig.seeAllTitle ? groupConfig.seeAllTitle : groupConfig.title;

    return (
        <div className="trendingTopics">
            <div className={`maskedBg`}>
                <div className="mask"></div>
            </div>
        <Container>
          <Row>
            <Col xs={12} lg={12}>
            <div className="colPanel">
            <SkeletonTheme color="#ccc" highlightColor="#ddd">
                <div className={`filterGroup noBorder ${groupStyle}`}>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                    <h3 className="float-left groupHeader">{t(groupConfig.title)}</h3>
                    {(mode === "default" || groupConfig.jumplink) && (
                        <div className="float-right groupLink">
                        <Link to={seeMoreLink} className="d-none d-md-block">
                            {t(seeMoreTitle)}
                        </Link>
                        </div>
                    )}
                    </Col>
                </Row>
                <Row>
                    {state.recommendations && state.recommendations.length > 0
                    ? state.recommendations.slice(0, 3).map((event, index) => (
                        <Col xs={12} md={6} lg={6} xl={4} key={event.id}>
                            <Result result={event} cardStyle={"expertcoaching"} />
                        </Col>
                        ))
                    : [1, 2, 3].map((event, index) => (
                        <Col
                            xs={12}
                            md={6}
                            lg={4}
                            xl={4}
                            key={index}
                            className={index === 3 ? "d-lg-none" : ""}
                        >
                            <Skeleton height={260} duration={3} />
                        </Col>
                        ))}
                    <Col className="d-md-none">
                    {(mode === "default" || groupConfig.jumplink) && (
                        <Link to={seeMoreLink} className="btn btn-secondary">
                        {t(seeMoreTitle)}
                        </Link>
                    )}
                    </Col>
                </Row>
                </div>
            </SkeletonTheme>
        </div>
            </Col>
          </Row>
        </Container>
      </div>

        
    );
};

TrendingTopics.propTypes = {
    eventList: PropTypes.array,
  };
  
  export default TrendingTopics;