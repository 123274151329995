import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";
import { GOOGLE_RECAPTCHA_SITEKEY } from "../../config/environment";

const ReCaptcha = ({ onChange, onErrored, onExpired }) => {
  return (
    <ReCAPTCHA
      size={window.orientation > -1 ? "compact" : "normal"}
      sitekey={GOOGLE_RECAPTCHA_SITEKEY}
      onChange={onChange}
      onErrored={onErrored}
      onExpired={onExpired}
    />
  );
};

ReCaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
  onErrored: PropTypes.func,
  onExpired: PropTypes.func,
};

export default ReCaptcha;
