import { getStateFromCookies } from "redux-cookies-middleware";
import { UserActions } from "../actions";
import { SET_USER_INFO } from "../actions/user";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";

const {
  SET_USER_LANGUAGE,
  SET_USER_TIMEZONE,
  SET_USER_SEARCH_TOKEN,
  SET_USER_API_TOKEN,
  USER_REGISTRATIONS_SUCCEED,
  USER_FAVORITES_SUCCEED,
  USER_FAVORITES_ALL,
  USER_FAVORITES_ALL_SUCCEED,
  DELETE_USER_FAVORITE_SUCCEED,
  SET_USER_FAVORITE_SUCCEED,
  USER_FAVORITES_COUNTER,
  USER_UPCOMING_COUNTER,
  USER_PAST_COUNTER,
} = UserActions;

let initialState = {
  timezone: "",
  language: "",
  locale: "",
  user: null,
  favorites: [],
  favoritesAll: [],
  favoritesTotalPages: 0,
  upcomingTotalPages: 0,
  pastTotalPages: 0,
  favoritesCounter: 1,
  upcomingCounter: 1,
  pastCounter: 1,
  registrations: {
    upcoming: [],
    previous: [],
  },
};

const paths = {
  timezone: { name: "timezone" },
  language: { name: "language" },
  locale: { name: "locale" },
};

initialState = getStateFromCookies(initialState, paths);

export default function csUser(state = initialState, action) {
  switch (action.type) {
    case SET_USER_TIMEZONE:
      return {
        ...state,
        timezone: action.payload.timezone,
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
        locale: action.payload.locale,
      };
    case SET_USER_SEARCH_TOKEN:
      return {
        ...state,
        searchToken: action.payload.token,
      };
    case SET_USER_API_TOKEN:
      return {
        ...state,
        apiToken: action.payload.token,
      };
    case SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
        language: action.payload === null ? state.language : action.payload.language,
        favoritesAll: action.payload === null ? [] : state.favoritesAll,
      };
    case USER_FAVORITES_COUNTER:
      return {
        ...state,
        favoritesCounter: action.payload,
      };
    case USER_UPCOMING_COUNTER:
      return {
        ...state,
        upcomingCounter: action.payload,
      };
    case USER_PAST_COUNTER:
      return {
        ...state,
        pastCounter: action.payload,
      };
    case USER_REGISTRATIONS_SUCCEED:
      return {
        ...state,
        upcomingTotalPages:
          action.status === "upcoming"
            ? action.total
            : state.upcomingTotalPages,
        pastTotalPages:
          action.status === "previous" ? action.total : state.pastTotalPages,
        registrations: {
          upcoming:
            action.status === "upcoming"
              ? !action.withReset
                ? [...state.registrations.upcoming, ...action.payload]
                : action.payload
              : state.registrations.upcoming,
          previous:
            action.status === "previous"
              ? !action.withReset
                ? [...state.registrations.previous, ...action.payload]
                : action.payload
              : state.registrations.previous,
        },
      };
    case SET_USER_FAVORITE_SUCCEED:
      return {
        ...state,
        favorites: [...state.favorites, action.favorite],
        favoritesAll: [...state.favoritesAll, action.eventId],
      };
    case USER_FAVORITES_SUCCEED:
      return {
        ...state,
        favorites: action.withReset
          ? action.payload
          : uniqBy([...state.favorites, ...action.payload], "id"),
        favoritesTotalPages: action.total,
      };
    case USER_FAVORITES_ALL:
      return {
        ...state,
      };
    case USER_FAVORITES_ALL_SUCCEED:
      return {
        ...state,
        favoritesAll: action.payload,
      };
    case DELETE_USER_FAVORITE_SUCCEED:
      return {
        ...state,
        favorites: filter(
          state.favorites,
          (item) => item.id !== action.favoriteId
        ),
        favoritesAll: filter(
          state.favoritesAll,
          (id) => id !== action.favoriteId
        ),
      };
    default:
      return state;
  }
}
