/* eslint-disable react-hooks/exhaustive-deps */
import { EventActions } from "../../store/actions";

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";

import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import moment from "moment-timezone";
import Moment from "react-moment";

import EventListContainer from "../../containers/EventListContainer";
import FilterBarContainer from "../../containers/FilterBarContainer";

import { ErrorPage } from "../ErrorPage";

const { loadEventsList, updateEventFilters } = EventActions;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FilterList = ({
  listInit,
  listCurrent,
  appConfig,
  timezone,
  history,
  location,
  criticalError,
  dispatch,
}) => {
  const query = useQuery();

  useEffect(() => {
    initLoad();
  }, [location]);

  function initLoad() {

    let popPostInit =
      history.action === "POP" && listInit && listCurrent === "default";

    // special string that forces respective filter reset
    const resetStr = "_null_";

    let filter_load = {};
    filter_load["search"] = query.get("search")
      ? decodeURIComponent(query.get("search"))
      : "";
    filter_load["product"] = query.get("product")
      ? decodeURIComponent(query.get("product"))
      : "";
    filter_load["category"] = query.get("category")
      ? decodeURIComponent(query.get("category"))
      : "";
    filter_load["region"] = query.get("region")
      ? decodeURIComponent(query.get("region"))
      : "";
    filter_load["language"] = query.get("language")
      ? decodeURIComponent(query.get("language"))
      : "";
    filter_load["type"] = query.get("type")
      ? decodeURIComponent(query.get("type"))
      : "";
    filter_load["startDate"] = query.get("startDate");
    filter_load["endDate"] = query.get("endDate");
    filter_load["dateSpan"] = query.get("dateSpan");

    let filter_load_arrays = [
      "product",
      "type",
      "category",
      "region",
      "language",
    ];

    // convert array values to respective array objects
    // handle intentional reset as _null_
    // remove from dispatch if empty
    for (const filter_index in filter_load_arrays) {
      let filter_key = filter_load_arrays[filter_index];
      if (filter_load[filter_key] && filter_load[filter_key] !== resetStr)
        filter_load[filter_key] = filter_load[filter_key].split(",");
      else if (filter_load[filter_key] === resetStr)
        filter_load[filter_key] = [];
      else delete filter_load[filter_key];
    }

    let filter_load_available = Object.values(filter_load).some(
      (x) => x !== null && x !== ""
    );

    if (filter_load_available) {
      if (!popPostInit) {
        //console.log("loading from url filters");
        let currentTz = timezone ? timezone : Moment.globalTimezone;

        switch (filter_load["dateSpan"]) {
          case "today": {
            let start = moment().tz(currentTz).startOf("day");
            let end = start.clone().endOf("day");
            filter_load["startDate"] = start;
            filter_load["endDate"] = end;
            break;
          }
          case "this-week": {
            let start = moment().tz(currentTz).startOf("week");
            let end = start.clone().endOf("week");
            filter_load["startDate"] = start;
            filter_load["endDate"] = end;
            break;
          }
          case "next-week": {
            let start = moment().tz(currentTz).add(1, "weeks").startOf("week");
            let end = start.clone().endOf("week");
            filter_load["startDate"] = start;
            filter_load["endDate"] = end;
            break;
          }
          case "custom": {
            // console.log("loading custom");
            if (filter_load["startDate"] && filter_load["endDate"]) {
              let startTest = moment.unix(filter_load["startDate"]);
              let endTest = moment.unix(filter_load["endDate"]);
              if (startTest.isValid() && endTest.isValid()) {
                let start = startTest.tz(currentTz).startOf("day");
                let end = endTest.tz(currentTz).endOf("day");
                filter_load["startDate"] = start;
                filter_load["endDate"] = end;
              }
            }
            break;
          }
          default: {
            break;
          }
        }

        const filterAliases = appConfig.filterAliases;

        for (const filter in filter_load) {
          if (filter_load[filter] !== "") {
            let filterValue =
              filter_load[filter] !== resetStr ? filter_load[filter] : null;

            // map deprecated slugs to new slugs
            if (filterAliases) {
              if (filterValue && filterValue.constructor === Array) {
                for (var i = 0; i < filterValue.length; i++) {
                  let filterValueSingle = filterValue[i];
                  if (filterAliases[filterValueSingle]) {
                    filterValue[i] = filterAliases[filterValueSingle];
                  }
                }
                filterValue = [...new Set(filterValue)];
              }
            }

            /*console.log(
              "loading filter from url:" + filter + ", value: " + filterValue
            );*/
            let filterObject = { key: filter, values: filterValue };
            dispatch(updateEventFilters(filterObject));
          }
        }
      } else {
        //console.log("filter load skipped");
      }
    } else {
      //console.log("loading from init");
      dispatch(loadEventsList());
    }
  }

  const { t } = useTranslation();

  const pageTitle = t("Events List") + " - " + t("Customer Success Events");

  const metaDescription = t("Default Meta Description");

  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
      </Helmet>
      <div className="insetPage">
        <Container className="fullPanel">
          <Row>
            <Col xs={12} lg={4}>
              <FilterBarContainer />
            </Col>
            <Col xs={12} lg={8} className="listTileContainer">
              <EventListContainer />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(FilterList);
