import { ResultTemplatesHelpers } from "@coveo/headless";

import React, { useEffect, useState } from "react";
import { Result } from "../Result";

import "./ResultList.scss";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const ResultList = (props) => {
  const { controller, templateManager, cardStyle } = props;
  const [state, setState] = useState(controller.state);

  templateManager.registerTemplates(
    {
      conditions: [
        ResultTemplatesHelpers.fieldMustMatch("successeventitemtype", [
          "portrait",
        ]),
      ],
      content: (result) => <Result key={result.uniqueId} result={result} cardStyle={cardStyle} />,
    },
    {
      conditions: [],
      content: (result) => <Result key={result.uniqueId} result={result} cardStyle={cardStyle}/>,
    }
  );

  useEffect(
    () => controller.subscribe(() => setState(controller.state)),
    [controller]
  );

  if (state.isLoading) {
    return <div>Loading Results</div>;
  }

  const listRenderType = cardStyle === '' || cardStyle === undefined;
  const gridRenderType = cardStyle !== '' && cardStyle !== undefined;

  return (
    <>
    {listRenderType && (
      <div className="coveo--result-list">
        {state.results.map((result) => {
          const template = templateManager.selectTemplate(result);
          return template ? template(result) : null;
        })}
      </div>
    )}
    {gridRenderType && (
      <div className="expert-coaching-result-list">
        <Row className="eventGridRow">
        {state.results.map((result, index) => (

          <Col xs={12} md={6} lg={6} xl={6} key={"event-index-" + index}>
            <Result result={result} cardStyle={cardStyle} />
          </Col>
          
        ))
        }
        </Row>
        
      </div>
    )}
    </>
  );
};
