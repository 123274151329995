import Autolinker from "autolinker";
import DOMPurify from "dompurify";

export const slicedText = (text, symbols = 100) => {
  let sliced = text.slice(0, symbols);
  if (sliced.length < text.length) sliced += "...";
  return sliced;
};

export const formatTextContent = (mdText, renderHTML = true) => {
  if (!mdText) return "";
  var derText = mdText;
  //replace the linebreaks with <br>
  derText = derText.split("\\n").join("\n").split("\\r").join("\r");

  if (renderHTML) {
    //check for links with markdown syntax: [text](url)
    let elements = derText.match(/\[.*?\)/g);
    if (elements != null && elements.length > 0) {
      for (let el of elements) {
        let txt = el.match(/\[(.*?)\]/)[1]; //get only the txt
        let url = el.match(/\((.*?)\)/)[1]; //get only the link
        derText = derText.replace(
          el,
          '<a href="' + url + '" target="_blank">' + txt + "</a>"
        );
      }
    }
    // run auto linker
    derText = Autolinker.link(derText);
  }
  // run dom purification for xss prevention
  const cleanText = DOMPurify.sanitize(derText, { ADD_ATTR: ["target"] });
  return cleanText;
};
