export const UPDATE_EVENT_FILTERS = "UPDATE_EVENT_FILTERS";
export const UPDATE_EVENT_FILTERS_SILENT = "UPDATE_EVENT_FILTERS_SILENT";

export const TOGGLE_PREMIER_EXCLUDE = "TOGGLE_PREMIER_EXCLUDE";

export const LOAD_EVENTS_LIST = "LOAD_EVENTS_LIST";
export const RENDER_EVENTS_LIST = "RENDER_EVENTS_LIST";

export const LOAD_MORE_EVENTS = "LOAD_MORE_EVENTS";
export const LOAD_MORE_EVENTS_SUCCEED = "LOAD_MORE_EVENTS_SUCCEED";
export const LOAD_MORE_EVENTS_FAILED = "LOAD_MORE_EVENTS_FAILED";

export const LOAD_EVENT_DETAIL = "LOAD_EVENT_DETAIL";
export const LOAD_EVENT_DETAIL_SUCCEED = "LOAD_EVENT_DETAIL_SUCCEED";
export const LOAD_EVENT_DETAIL_NOTFOUND = "LOAD_EVENT_DETAIL_NOTFOUND";

export const LOAD_EVENT_FILTERGROUP = "LOAD_EVENT_FILTERGROUP";
export const LOAD_EVENT_FILTERGROUP_SUCCEED = "LOAD_EVENT_FILTERGROUP_SUCCEED";
export const LOAD_EVENT_FILTERGROUP_FAILED = "LOAD_EVENT_FILTERGROUP_FAILED";

export const LOAD_PRODUCT_TOPICGROUP = "LOAD_PRODUCT_TOPICGROUP";
export const LOAD_PRODUCT_TOPICGROUP_SUCCEED =
  "LOAD_PRODUCT_TOPICGROUP_SUCCEED";
export const LOAD_PRODUCT_TOPICGROUP_FAILED = "LOAD_PRODUCT_TOPICGROUP_FAILED";

export const LOAD_FEATUREDEVENTS = "LOAD_FEATUREDEVENTS";
export const LOAD_FEATUREDEVENTS_SUCCEED = "LOAD_FEATUREDEVENTS_SUCCEED";
export const LOAD_FEATUREDEVENTS_FAILED = "LOAD_FEATUREDEVENTS_FAILED";

export const LOAD_UPCOMINGEVENTS = "LOAD_UPCOMINGEVENTS";
export const LOAD_UPCOMINGEVENTS_SUCCEED = "LOAD_UPCOMINGEVENTS_SUCCEED";
export const LOAD_UPCOMINGEVENTS_FAILED = "LOAD_UPCOMINGEVENTS_FAILED";

export const LOAD_RECURRINGEVENTS = "LOAD_RECURRINGEVENTS";
export const LOAD_RECURRINGEVENTS_SUCCEED = "LOAD_RECURRINGEVENTS_SUCCEED";
export const LOAD_RECURRINGEVENTS_FAILED = "LOAD_RECURRINGEVENTS_FAILED";

export const LOAD_PARENTEVENT = "LOAD_PARENTEVENT";
export const LOAD_PARENTEVENT_SUCCEED = "LOAD_PARENTEVENT_SUCCEED";
export const LOAD_PARENTEVENT_NOTFOUND = "LOAD_PARENTEVENT_NOTFOUND";

export const LOAD_PARENTSESSIONS = "LOAD_PARENTSESSIONS";
export const LOAD_PARENTSESSIONS_SUCCEED = "LOAD_PARENTSESSIONS_SUCCEED";

export const LOAD_EVENT_DETAIL_USER_REGISTRATION =
  "LOAD_EVENT_DETAIL_USER_REGISTRATION";

export const LOAD_EVENT_DETAIL_USER_REGISTRATION_SUCCEED =
  "LOAD_EVENT_DETAIL_USER_REGISTRATION_SUCCEED";

export const SET_REGISTRATION_EVENT_INFO = "SET_REGISTRATION_EVENT_INFO";
export const SET_REGISTRATION_EVENT_INFO_SUCCEED =
  "SET_REGISTRATION_EVENT_INFO_SUCCEED";
export const SET_REGISTRATION_EVENT_INFO_FAILED =
  "SET_REGISTRATION_EVENT_INFO_FAILED";

export const SET_REGISTRATION_EVENT_CANCEL = "SET_REGISTRATION_EVENT_CANCEL";
export const SET_REGISTRATION_EVENT_CANCEL_SUCCEED =
  "SET_REGISTRATION_EVENT_CANCEL_SUCCEED";

export const REMOVE_REGISTRATION_FAILED = "REMOVE_REGISTRATION_FAILED";

export const LOAD_ONDEMANDEVENTS = "LOAD_ONDEMANDEVENTS";
export const LOAD_ONDEMANDEVENTS_SUCCEED = "LOAD_ONDEMANDEVENTS_SUCCEED";
export const LOAD_ONDEMANDEVENTS_FAILED = "LOAD_ONDEMANDEVENTS_FAILED";

export function loadEventsList() {
  return {
    type: LOAD_EVENTS_LIST,
  };
}

export function updateEventFilters(filterData) {
  return {
    type: UPDATE_EVENT_FILTERS,
    eventFilters: filterData,
  };
}

export function updateEventFiltersSilent(filterData) {
  return {
    type: UPDATE_EVENT_FILTERS_SILENT,
    eventFilters: filterData,
  };
}

export function togglePremierExclude(filterData) {
  return {
    type: TOGGLE_PREMIER_EXCLUDE,
    eventFilters: filterData,
  };
}

export function loadMoreEvents(payload) {
  return { type: LOAD_MORE_EVENTS, payload };
}

export function loadMoreEventsSucceed(payload) {
  return { type: LOAD_MORE_EVENTS_SUCCEED, payload };
}

export function loadMoreEventsFailed(payload) {
  return { type: LOAD_MORE_EVENTS_FAILED, payload };
}

export function loadEventDetail(payload) {
  return { type: LOAD_EVENT_DETAIL, payload };
}

export function loadEventDetailSucceed(payload) {
  return { type: LOAD_EVENT_DETAIL_SUCCEED, payload };
}

export function loadEventDetailNotFound(payload) {
  return { type: LOAD_EVENT_DETAIL_NOTFOUND, payload };
}

export function loadEventFilterGroup(payload) {
  return { type: LOAD_EVENT_FILTERGROUP, payload };
}

export function loadEventFilterGroupSucceed(payload) {
  return { type: LOAD_EVENT_FILTERGROUP_SUCCEED, payload };
}

export function loadEventFilterGroupFailed(payload) {
  return { type: LOAD_EVENT_FILTERGROUP_FAILED, payload };
}

export function loadProductTopicGroup(payload) {
  return { type: LOAD_PRODUCT_TOPICGROUP, payload };
}

export function loadProductTopicGroupSucceed(payload) {
  return { type: LOAD_PRODUCT_TOPICGROUP_SUCCEED, payload };
}

export function loadProductTopicGroupFailed(payload) {
  return { type: LOAD_PRODUCT_TOPICGROUP_FAILED, payload };
}

export function loadFeaturedEvents(payload) {
  return { type: LOAD_FEATUREDEVENTS, payload };
}

export function loadFeaturedEventsSucceed(payload) {
  return { type: LOAD_FEATUREDEVENTS_SUCCEED, payload };
}

export function loadFeaturedEventsFailed(payload) {
  return { type: LOAD_FEATUREDEVENTS_FAILED, payload };
}

export function loadUpcomingEvents(payload) {
  return { type: LOAD_UPCOMINGEVENTS, payload };
}

export function loadUpcomingEventsSucceed(payload) {
  return { type: LOAD_UPCOMINGEVENTS_SUCCEED, payload };
}

export function loadUpcomingEventsFailed(payload) {
  return { type: LOAD_UPCOMINGEVENTS_FAILED, payload };
}

export function loadRecurringEvents(payload) {
  return { type: LOAD_RECURRINGEVENTS, payload };
}

export function loadRecurringEventsSucceed(payload) {
  return { type: LOAD_RECURRINGEVENTS_SUCCEED, payload };
}

export function loadRecurringEventsFailed(payload) {
  return { type: LOAD_RECURRINGEVENTS_FAILED, payload };
}

export function loadParentEvent(payload) {
  return { type: LOAD_PARENTEVENT, payload };
}

export function loadParentEventSucceed(payload) {
  return { type: LOAD_PARENTEVENT_SUCCEED, payload };
}

export function loadParentSessions(payload) {
  return { type: LOAD_PARENTSESSIONS, payload };
}

export function loadParentSessionsSucceed(payload) {
  return { type: LOAD_PARENTSESSIONS_SUCCEED, payload };
}

export function loadParentEventNotFound(payload) {
  return { type: LOAD_PARENTEVENT_NOTFOUND, payload };
}

export function setRegistrationEventInfo(payload) {
  return { type: SET_REGISTRATION_EVENT_INFO, payload };
}

export function setRegistrationEventInfoSucceed(payload) {
  return { type: SET_REGISTRATION_EVENT_INFO_SUCCEED, payload };
}

export function setRegistrationEventInfoFailed(payload) {
  return { type: SET_REGISTRATION_EVENT_INFO_FAILED, payload };
}

export function removeRegistrationFailed(payload) {
  return { type: REMOVE_REGISTRATION_FAILED, payload };
}

export function loadEventDetailUserRegistration(payload) {
  return { type: LOAD_EVENT_DETAIL_USER_REGISTRATION, payload };
}

export function setRegistrationEventCancel(payload) {
  return { type: SET_REGISTRATION_EVENT_CANCEL, payload };
}

export function setRegistrationEventCancelSucceed(payload) {
  return { type: SET_REGISTRATION_EVENT_CANCEL_SUCCEED, payload };
}

export function loadOnDemandEvents(payload) {
  return { type: LOAD_ONDEMANDEVENTS, payload };
}

export function loadOnDemandEventsSucceed(payload) {
  return { type: LOAD_ONDEMANDEVENTS_SUCCEED, payload };
}

export function loadOnDemandEventsFailed(payload) {
  return { type: LOAD_ONDEMANDEVENTS_FAILED, payload };
}

export function updateEventLanguageFilter(language) {
  return {
    type: UPDATE_EVENT_FILTERS,
    eventFilters: { key: 'language', values: [language] }
  };
}
