/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { UserActions } from "@/store/actions";
import { connect } from "react-redux";

import { headlessEngine } from "@/configureCoveo";
import { loadConfigurationActions, buildContext } from "@coveo/headless";
import { getSearchToken } from "@/utils/tokenServices";

const { setUserSearchToken } = UserActions;

const CoveoInit = ({ dispatch, user }) => {
  useEffect(() => {
    async function initSearchToken() {
      const userEmail =
        user && user.tb_id && user.email ? user.email : "anonymous";
      const userTier =
        user && user.tb_id && user.success_plan ? user.success_plan : "";
      // TODO: accept user language coveo context
      // const userLanguage =
      //   user && user.tb_id && user.language ? user.language : "";
      let token = await getSearchToken(userEmail);
      let searchToken = "";
      if (token && token.token) {
        // configure headless engine with new token
        searchToken = token.token;
        const { updateBasicConfiguration } =
          loadConfigurationActions(headlessEngine);
        headlessEngine.dispatch(
          updateBasicConfiguration({ accessToken: searchToken })
        );
      } else {
        //console.log("setting stub token, failed api call");
        searchToken = "stubtoken";
      }

      var ctx = buildContext(headlessEngine);
      ctx.add("user_tier", userTier);
      ctx.add("user_email", userEmail);
      // add language to context
      // ctx.add("user_language", userLanguage)

      //console.log("initialized coveo search");
      let tokenObject = { token: searchToken };
      dispatch(setUserSearchToken(tokenObject));
    }
    // initialize an authenticated/anonymous search token
    // for this session
    initSearchToken();
  }, [user]);
  return null;
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.csUser.user,
  };
};

const CoveoInitContainer = connect(mapStateToProps)(CoveoInit);

export default CoveoInitContainer;
