import { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment-timezone";

export const useEventTranslation = (key, value, raw = false) => {
  const valueList = useSelector(
    (state) => state.csEvent.appConfig.filters[key].options
  );

  const { t } = useTranslation();

  let mappedFilter = value;

  if (value && valueList) {
    let convertedValue = valueList.filter((item) => item.value === value);

    if (
      convertedValue &&
      Array.isArray(convertedValue) &&
      convertedValue.length > 0
    ) {
      mappedFilter = convertedValue[0].text;
    }
  }

  let returnValue = !raw ? t(mappedFilter) : mappedFilter;

  return returnValue;
};

export const useEventTranslationBulk = (key, filterValues, raw = false) => {
  const valueList = useSelector(
    (state) => state.csEvent.appConfig.filters[key].options
  );

  const { t } = useTranslation();
  let convertedFilters = [];

  if (filterValues) {
    filterValues.map((filterValue) => {
      let convertedValue = valueList.filter(
        (item) => item.value === filterValue
      );
      if (
        convertedValue &&
        Array.isArray(convertedValue) &&
        convertedValue.length > 0
      ) {
        convertedFilters[filterValue] = !raw
          ? t(convertedValue[0].text)
          : convertedValue[0].text;
      }
      return true;
    });
  }
  return convertedFilters;
};

export const useHubFieldTranslation = (hubFields, field, language) => {
  let fieldValue = "";

  if (hubFields) {
    if (hubFields[language]) {
      if (hubFields[language][field]) {
        fieldValue = hubFields[language][field];
      }
    } else if (hubFields["en"]) {
      if (hubFields.en[field]) {
        fieldValue = hubFields.en[field];
      }
    }
  }

  return fieldValue;
};

export const useFilterLinkConfig = (filter) => {
  // these are the safe reset values
  const resetStr = "_null_";

  const groupConfigReset = {
    search: resetStr,
    product: resetStr,
    category: resetStr,
    region: resetStr,
    language: resetStr,
    type: resetStr,
    startDate: resetStr,
    endDate: resetStr,
    clearFilters: resetStr,
    dateSpan: "custom",
  };

  let linkFilters = { ...groupConfigReset };

  if (filter) {
    linkFilters = {
      ...groupConfigReset,
      ...filter,
    };
  }

  let filterUrl = "?filter=true";
  for (const filter_key in linkFilters) {
    filterUrl +=
      "&" + filter_key + "=" + encodeURIComponent(linkFilters[filter_key]);
  }

  return filterUrl;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const defaultTruncateSettings = {
  length: 40,
  ending: "...",
};

export const useTextTruncate = (
  text,
  settings = defaultTruncateSettings,
  stripBreaks = false
) => {
  let truncatedText = text;

  if (text && text.length > settings.length) {
    truncatedText =
      text.substring(0, settings.length - settings.ending.length) +
      settings.ending;
  }

  if (stripBreaks && truncatedText) {
    truncatedText = truncatedText.split("\\n").join(" ").split("\\r").join(" ");
  }

  return truncatedText;
};

export const useDurationFormat = (sessionLength, formatLength = "long") => {
  const { t } = useTranslation();

  const duration = moment.duration({ minutes: sessionLength });
  const durationMinutes = duration.asMinutes();

  let durationStr = null;
  if (durationMinutes >= 60) {
    let durationStrHours = moment.utc(duration.asMilliseconds()).format("H");
    let hourLabel = t("hour");
    if (durationStrHours > 1) {
      hourLabel = t("hours");
    }
    let durationStrMins = moment.utc(duration.asMilliseconds()).format("mm");
    if (durationStrMins > 0) {
      durationStr =
        durationStrHours +
        " " +
        hourLabel +
        ", " +
        durationStrMins +
        " " +
        (formatLength === "long" ? t("minutes") : t("min"));
    } else {
      durationStr = durationStrHours + " " + hourLabel;
    }
  } else {
    durationStr =
      moment.utc(duration.asMilliseconds()).format("mm") +
      (formatLength === "long" ? " minutes" : " min");
  }
  return durationStr;
};

export const useSearchLinkConfig = (config) => {
  let url = "commerce"
  return url;
}
