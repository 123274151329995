import React, { useState, useEffect } from "react";

//import { useTranslation } from "react-i18next";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { ReactComponent as ExpandDown } from "../../assets/images/icon_expand_arrow_down.svg";

import "./ExpandPanel.scss";

const ExpandPanel = ({ title, text, expanded }) => {
  //const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  //const [hasUserExpanded, setHasUserExpanded] = useState(false);

  useEffect(() => {}, []);

  const decoratedOnClick = () => {
    setIsExpanded(!isExpanded);
    //setHasUserExpanded(true);
  };

  return (
    <div>
      <Accordion
        defaultActiveKey="0"
        activeKey={isExpanded ? "1" : "0"}
        className={isExpanded ? "expanded" : "collapsed"}
      >
        <Card className="expandPanelCard">
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              onClick={decoratedOnClick}
            >
              {title}
              <ExpandDown />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <p
                className="richText"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default ExpandPanel;
