import { connect } from "react-redux";
import { ExpertCoaching } from "../pages/ExpertCoaching";

const mapStateToProps = (state, ownProps) => {
  return {
    filterGroups: state.csEvent.appConfig.filterGroups,
    criticalError: state.csOther.criticalError,
    user: state.csUser.user,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    eccType: ownProps.match.params.id,
    appConfig: state.csEvent.appConfig,
    trendingTopicsList: state.csEvent.trendingTopicsList,
    onDemandEventsList: state.csEvent.onDemandEventsList,
    timezone: state.csUser.timezone,
    language: state.csUser.language,
    isLoading: state.csHub.isLoading,
    favorites: state.csUser.favoritesAll,
    userSearchToken: state.csUser.searchToken,
    locale: state.csUser.locale,
  };
};

const ExpertCoachingContainer = connect(mapStateToProps)(ExpertCoaching);

export default ExpertCoachingContainer;