import React, { useState, useEffect, useMemo, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { SFCheckbox } from "@/components/form/SFCheckbox";
import { FormSelect } from "@/components/FormSelect";
import { ReCaptcha } from "@/components/ReCaptcha";
import { SFInput } from "@/components/form/SFInput";
import { getStateOptions } from "./helpers";
import {
  EMPLOYEES,
  COUNTRIES,
  COUNTRIES_W_DISCLAIMER,
  COUNTRIES_W_STATE,
} from "./constants";

export const EventForm = ({
  onChangeFields,
  selectedEmployees,
  selectedCountry,
  selectedState,
  handleSubmit,
  onSubmit,
  onSubmitError,
  onEmployeeSelect,
  onCountrySelect,
  onStateSelect,
  validateRecaptcha,
  trailblazerLogin,
  formState,
  register,
  user,
  tbidUser,
  errorsFields,
  inputHandled,
  t,
}) => {
  const [showFullDisclaimer, setShowFullDisclaimer] = useState(false);
  const [hasFormDefaultsInit, setHasFormDefaultsInit] = useState(false);
  const [formDefaults, setFormDefaults] = useState({});

  const stateOptions = useMemo(
    () => getStateOptions(selectedCountry),
    [selectedCountry]
  );
  const needsState = COUNTRIES_W_STATE.includes(selectedCountry?.value);
  const isCA = selectedCountry && selectedCountry.value === "CA";

  const selectedEmployeesOption = useMemo(() => {
    if (!selectedEmployees) return null;

    return EMPLOYEES.find((option) => option.value === selectedEmployees.value);
  }, [selectedEmployees]);

  const selectedCountryOption = useMemo(() => {
    if (!selectedCountry) return null;

    return COUNTRIES.find((option) => option.value === selectedCountry.value);
  }, [selectedCountry]);

  const selectedStateOption = useMemo(() => {
    if (!stateOptions || !selectedState || !selectedState.value) return null;

    return stateOptions.find((option) => option.value === selectedState.value);
  }, [selectedState, stateOptions]);

  useEffect(() => {
    // set defaults on init, not after user input changes
    if (user && user.first_name && !inputHandled && !hasFormDefaultsInit) {
      setFormDefaults(user);
      setHasFormDefaultsInit(true);
    }
    // if there is no user and we have initialized one, clear out
    if (!user && hasFormDefaultsInit) {
      setFormDefaults({});
    }
  }, [user, inputHandled, hasFormDefaultsInit]);

  const handleFullDisclaimerClick = useCallback(() => {
    if (!showFullDisclaimer) setShowFullDisclaimer(true);
  }, [showFullDisclaimer]);

  const disclaimer = useMemo(() => {
    if (!selectedCountry) return null;

    if (COUNTRIES_W_DISCLAIMER.includes(selectedCountry.value)) {
      const label1 = t("Event Disclaimer 1");
      const label2 = t("Event Disclaimer 2");

      return showFullDisclaimer ? `${label1}<br/><br/>${label2}` : `${label1}`;
    }

    return null;
  }, [selectedCountry, showFullDisclaimer, t]);

  const optInMessage = useMemo(() => {
    if (!selectedCountryOption) return null;

    if (
      selectedCountryOption.hasOwnProperty("optIn") &&
      selectedCountryOption.optIn
    )
      return t("Registration form Opt in");

    return null;
  }, [selectedCountryOption, t]);

  const signupStatus = useMemo(() => {
    if (!tbidUser) {
      return (
        <>
          <p>{t("Sign Up with Trailblazer.me Description")}</p>
          <div className="signin-button" onClick={trailblazerLogin}>
            {t("Login with Trailblazer")}
          </div>
        </>
      );
    } else {
      return (
        user && (
          <>
            <p className="trailblazerUserInfo">
              {t(
                "This form has been pre-populated with information from your trailblazer.me profile."
              )}
            </p>
            <p className="trailblazerUserInfo">
              {t(
                "Feel free to update any details such as your preferred contact email address, etc."
              )}
            </p>
          </>
        )
      );
    }
  }, [tbidUser, user, trailblazerLogin, t]);

  return (
    <Form
      noValidate
      validated={formState.isSubmitted}
      onSubmit={handleSubmit(onSubmit, onSubmitError)}
      onChange={onChangeFields}
      className="registration-form"
      autoComplete="nope"
    >
      {signupStatus}

      <p className="validation-rules-text">
        {t("Registration form Validation Rules")}
      </p>

      <SFInput
        defaultValue={formDefaults.first_name}
        key={`fname${formDefaults.first_name}`}
        name="first_name"
        label={t("Registration form First Name")}
        placeholder={t("Registration form First Name")}
        register={register}
        isDirty={formState.dirtyFields["first_name"]}
        isTouched={formState.touched["first_name"]}
        isError={Boolean(errorsFields["first_name"])}
        required
        errorMessage={t("Registration form First Name Error")}
      />

      <SFInput
        defaultValue={formDefaults.last_name}
        key={`lname${formDefaults.last_name}`}
        name="last_name"
        label={t("Registration form Last Name")}
        placeholder={t("Registration form Last Name")}
        register={register}
        isDirty={formState.dirtyFields["last_name"]}
        isTouched={formState.touched["last_name"]}
        isError={Boolean(errorsFields["last_name"])}
        required
        errorMessage={t("Registration form Last Name Error")}
      />

      <SFInput
        defaultValue={formDefaults.job_title}
        key={`jtitle${formDefaults.job_title}`}
        name="job_title"
        label={t("Registration form Job Title")}
        placeholder={t("Registration form Job Title")}
        register={register}
        isDirty={formState.dirtyFields["job_title"]}
        isTouched={formState.touched["job_title"]}
        isError={Boolean(errorsFields["job_title"])}
        required
        errorMessage={t("Registration form Job Title Error")}
      />

      <SFInput
        defaultValue={formDefaults.email}
        key={`email${formDefaults.email}`}
        name="email"
        label={t("Registration form Business Email")}
        placeholder={t("Registration form Business Email")}
        register={register}
        isDirty={formState.dirtyFields["email"]}
        isTouched={formState.touched["email"]}
        isError={Boolean(errorsFields["email"])}
        type="email"
        required
        registerSet={{ required: true, pattern: /^\S+@\S+$/i }}
        errorMessage={t("Registration form Business Email Error")}
      />

      <SFInput
        defaultValue={formDefaults.phone}
        key={`phone${formDefaults.phone}`}
        name="phone"
        label={t("Registration form Phone")}
        placeholder={t("Registration form Phone")}
        type="tel"
        register={register}
        isDirty={formState.dirtyFields["phone"]}
        isTouched={formState.touched["phone"]}
        isError={Boolean(errorsFields["phone"])}
        required
        errorMessage={t("Registration form Phone Error")}
      />

      <SFInput
        defaultValue={formDefaults.company}
        key={`company${formDefaults.company}`}
        name="company"
        label={t("Registration form Company")}
        placeholder={t("Registration form Company")}
        register={register}
        isDirty={formState.dirtyFields["company"]}
        isTouched={formState.touched["company"]}
        isError={Boolean(errorsFields["company"])}
        required
        errorMessage={t("Registration form Company Error")}
      />

      <FormSelect
        name="employees"
        isError={selectedEmployees?.isError}
        selected={selectedEmployeesOption}
        title={t("Registration form Employees")}
        placeholder={t("Registration form Employees")}
        onChange={onEmployeeSelect}
        options={EMPLOYEES}
        errorMessage={t("Registration form Employees Error")}
      />

      <FormSelect
        name="countries"
        isError={selectedCountry?.isError}
        selected={selectedCountryOption}
        title={t("Registration form Country")}
        placeholder={t("Registration form Country")}
        onChange={onCountrySelect}
        options={COUNTRIES}
        errorMessage={t("Registration form Country Error")}
      />

      {needsState && stateOptions ? (
        <FormSelect
          name="state"
          isError={selectedState?.isError}
          selected={selectedStateOption}
          title={t(`Registration form State${isCA ? " CA" : ""}`)}
          placeholder={t(`Registration form State${isCA ? " CA" : ""}`)}
          onChange={onStateSelect}
          options={stateOptions}
          errorMessage={t(`Registration form State${isCA ? " CA" : ""} Error`)}
        />
      ) : null}

      {optInMessage ? (
        <div className="checkbox-container">
          <SFCheckbox name="marketing_opt_in" register={register}>
            <span dangerouslySetInnerHTML={{ __html: optInMessage }} />
          </SFCheckbox>
        </div>
      ) : false}

      {disclaimer ? (
        <div className="checkbox-container">
          <SFCheckbox name="disclaimer" register={register} required>
            <span
              id="disclaimer-text"
              dangerouslySetInnerHTML={{ __html: disclaimer }}
            />
            {showFullDisclaimer ? null : (
              <>
                {" "}
                <span
                  role="button"
                  tabIndex={0}
                  onClick={handleFullDisclaimerClick}
                  style={{
                    cursor: "pointer",
                    color: "#0056b3",
                    whiteSpace: "nowrap",
                  }}
                >
                  Learn More
                </span>
              </>
            )}
          </SFCheckbox>
        </div>
      ) : (
        <div
          className="checkbox-container"
          dangerouslySetInnerHTML={{ __html: t("Event TOS") }}
        />
      )}

      {!tbidUser && <ReCaptcha onChange={validateRecaptcha} />}
      <Button type="submit" block>
        {t("Registration form Button Submit")}
      </Button>
    </Form>
  );
};
