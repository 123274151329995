import React, { useState } from "react";
import { CLIENT_DOMAIN } from "../../config/environment";
import { Link } from "react-router-dom";
import AddToCalendar from "react-add-to-calendar";
import Button from "react-bootstrap/Button";
import { formatTextContent } from "@/utils/stringHelpers";
import { ReactComponent as ShareIcon } from "../../assets/images/icon_share.svg";

export const EventComplete = ({
  share,
  filterConfigParams,
  eventDetail,
  registrationStatus,
  tbidUser,
  onCancel,
  inLaunchWindow,
  premierPriorReg,
  registeredFullEvent,
  t,
}) => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [isPreCancelled, setIsPreCancelled] = useState(false);

  const cancelRegistration = () => {
    setIsCancelled(true);
    onCancel();
  };

  const defaultStatus = {
    title: t("Registration status Thank you for signing up"),
    text: t("Registration status Registration In Progress"),
  };

  let registrationStatusTitle = defaultStatus.title;
  let registrationStatusText = defaultStatus.text;
  let showCancel = false;
  let showCalendar = true;
  let showShare = true;
  let showBackBtn = false;
  let showTryAgain = false;
  let showRegisterAgain = false;
  let showLaunchButton = false;

  if (registrationStatus && registrationStatus.registration_status) {
    switch (registrationStatus.registration_status) {
      case "Duplicate Registration":
      case "Registered": {
        registrationStatusTitle = t("Registration status Registered");
        registrationStatusText = "";
        showCancel = true;
        showCalendar = true;
        showShare = true;
        showTryAgain = false;
        showRegisterAgain = false;
        showLaunchButton =
          inLaunchWindow && registrationStatus.registration_launch_url;
        break;
      }
      case "Failed": {
        registrationStatusTitle = t("Registration status Failed");
        registrationStatusText = t("Registration status Failed Description");
        showCancel = false;
        showCalendar = false;
        showShare = false;
        showTryAgain = true;
        showRegisterAgain = false;
        break;
      }
      case "In Progress":
      default: {
        showCancel = true;
        showCalendar = true;
        showShare = true;
        showTryAgain = false;
        showRegisterAgain = false;
        break;
      }
    }
  }
  if (isCancelled) {
    registrationStatusTitle = t("Registration status Cancelled");
    registrationStatusText =
      !premierPriorReg && !registeredFullEvent
        ? t("Registration status Cancelled Description")
        : "";
    showCancel = false;
    showCalendar = false;
    showShare = false;
    showTryAgain = false;
    showRegisterAgain = !premierPriorReg && !registeredFullEvent;
  }

  // only tb id users can cancel from status
  showCancel = tbidUser /*&& eventTypeKey !== "in-person-event" */
    ? showCancel
    : false;

  const addressFormatted = formatTextContent(
    eventDetail.address && eventDetail.address.street
      ? eventDetail.address.street
      : "",
    false
  );

  const renderCalendarBtn = () => {
    let overviewCalendar = formatTextContent(eventDetail.overview, false);

    if (addressFormatted) {
      overviewCalendar = overviewCalendar + "\n\n" + addressFormatted;
    }

    let launchLink =
      registrationStatus && registrationStatus.registration_launch_url
        ? registrationStatus.registration_launch_url
        : eventDetail.launchUrl;

    if (launchLink) {
      // replace /w/ with /s/
      launchLink = launchLink.replace(
        "salesforce.zoom.us/s/",
        "salesforce.zoom.us/w/"
      );
      overviewCalendar = `${overviewCalendar}\n\n${t(
        "Launch Event"
      )}:\n\n${launchLink}`;
    }

    const addEventLink = `https://${CLIENT_DOMAIN}/events/${eventDetail.id}`;

    overviewCalendar = `${overviewCalendar}\n\n${t(
      "Event Detail"
    )}:\n\n${addEventLink}`;

    const addLocation = addressFormatted
      ? addressFormatted
      : launchLink
      ? launchLink
      : eventDetail.region;

    return (
      <AddToCalendar
        buttonLabel={t("Registration status Add to Calendar")}
        event={{
          title: eventDetail.title,
          description: overviewCalendar,
          location: addLocation,
          startTime: eventDetail.startDateTime,
          endTime: eventDetail.endDateTime,
        }}
      />
    );
  };

  const renderBackBtn = () => {
    return (
      <Link
        className="complete-back-button"
        to={{
          pathname: "/events",
          search: filterConfigParams,
        }}
      >
        {t("Registration status Back to Listing")}
      </Link>
    );
  };

  const renderShareBtn = () => {
    return (
      <Button
        variant="secondary"
        className="complete-share-button"
        onClick={share.action}
      >
        <span className="complete-share-title">{share.title}</span>
        <ShareIcon />
      </Button>
    );
  };

  const renderLaunchBtn = () => {
    return (
      <a
        className="complete-launch-button btn btn-primary"
        href={registrationStatus.registration_launch_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="complete-share-title">{t("Launch Event")}</span>
      </a>
    );
  };

  const preCancelRegistration = () => {
    setIsPreCancelled(true);
  };

  const renderCancelBtn = () => {
    return (
      <>
        {premierPriorReg && !isPreCancelled && (
          <Button
            onClick={preCancelRegistration}
            variant="link"
            className="inlineLink complete-text"
          >
            {t("Registration status Cancel CTA")}
          </Button>
        )}
        {premierPriorReg && isPreCancelled && (
          <>
            <span
              className="complete-text top-margin"
              dangerouslySetInnerHTML={{
                __html: t("Premier prior reg cancel disclaimer"),
              }}
            ></span>
            <Button onClick={cancelRegistration} variant="secondary">
              {t("Premier prior reg cancel CTA")}
            </Button>
          </>
        )}
        {registeredFullEvent && !premierPriorReg && !isPreCancelled && (
          <Button
            onClick={preCancelRegistration}
            variant="link"
            className="inlineLink complete-text"
          >
            {t("Registration status Cancel CTA")}
          </Button>
        )}
        {registeredFullEvent && !premierPriorReg && isPreCancelled && (
          <>
            <span
              className="complete-text top-margin"
              dangerouslySetInnerHTML={{
                __html: t("Full event prior reg cancel disclaimer"),
              }}
            ></span>
            <Button onClick={cancelRegistration} variant="secondary">
              {t("Full event prior reg cancel CTA")}
            </Button>
          </>
        )}

        {!premierPriorReg && !registeredFullEvent && (
          <Button
            onClick={cancelRegistration}
            variant="link"
            className="inlineLink complete-text"
          >
            {t("Registration status Cancel CTA")}
          </Button>
        )}
      </>
    );
  };

  const renderTryAgain = () => {
    return (
      <Button
        onClick={() => window.location.reload()}
        className="complete-try-button"
      >
        {t("Registration status Try Again CTA")}
      </Button>
    );
  };

  const renderRegisterAgain = () => {
    return (
      <Button
        onClick={() => window.location.reload()}
        className="complete-try-button"
      >
        {t("Register Again")}
      </Button>
    );
  };

  return (
    <div className="complete-wrapper">
      <span className="complete-title">{registrationStatusTitle}</span>
      {registrationStatusText ? (
        <span className="complete-text">{registrationStatusText}</span>
      ) : null}
      {showCalendar && !showLaunchButton && renderCalendarBtn()}
      {showLaunchButton && renderLaunchBtn()}
      {showBackBtn && renderBackBtn()}
      {showShare && renderShareBtn()}
      {showCancel && renderCancelBtn()}
      {showTryAgain && renderTryAgain()}
      {showRegisterAgain && renderRegisterAgain()}
    </div>
  );
};
