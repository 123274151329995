// ./src/containers/DashboardContainer.js

import { connect } from "react-redux";
import { FilterList } from "../pages/FilterList";

const mapStateToProps = (state, ownProps) => {
  return {
    listInit: state.csEvent.listInit,
    listCurrent: state.csEvent.eventListCurrent,
    appConfig: state.csEvent.appConfig,
    timezone: state.csUser.timezone,
    criticalError: state.csOther.criticalError,
  };
};

const FilterListContainer = connect(mapStateToProps)(FilterList);

export default FilterListContainer;
