// configureStore.js
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
// import { createMiddleware } from "redux-beacon";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./store/reducers/index";
import sagas from "./store/sagas";

import reduxCookiesMiddleware from "redux-cookies-middleware";

/*import GoogleAnalytics, {
  trackEvent,
} from "@redux-beacon/google-analytics-gtag";*/

/*import amplitude from "amplitude-js";
import { logEvent } from "@redux-beacon/amplitude";
import Amplitude from "@redux-beacon/amplitude";

const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init("52843c4f5cf2112017c671e234d148b2");

const ampTarget = Amplitude({ instance: amplitudeInstance });

const ampeEventsMap = {
  UPDATE_EVENT_FILTERS: logEvent((action, prevState, nextState) => {
    return {
      type: "FilterUpdate",
      properties: action.eventFilters,
    };
  }),
};
const amplitudeMiddleware = createMiddleware(ampeEventsMap, ampTarget);*/

// analytics middleware
/*const ga = GoogleAnalytics();
const eventsMap = {
  UPDATE_EVENT_FILTERS: trackEvent((action) => ({
    category: "Filters",
    action: "Update",
    label:
      action.eventFilters.key +
      ":" +
      Array(action.eventFilters.values).join(","),
    value: 0,
  })),
};
const analyticsMiddleware = createMiddleware(eventsMap, ga);*/

// saga middleware
const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // state to persist in cookies
  const paths = {
    "csUser.timezone": { name: "timezone" },
    "csUser.language": { name: "language" },
    "csUser.locale": { name: "locale" },
  };

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
        reduxCookiesMiddleware(paths)
        //analyticsMiddleware,
        //amplitudeMiddleware
      )
    )
  );

  sagaMiddleware.run(sagas);

  return store;
}
