import { connect } from "react-redux";
import { Events } from "../pages/Events";

const mapStateToProps = (state, ownProps) => {
  return {
    eventList: state.csUser.registrations.upcoming,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    favorites: state.csUser.favoritesAll,
    title: "User dashboard Your Upcoming Events",
    showBtns: true,
    showLikes: false,
    showPremierTag: false,
    isShowing: true,
    upcomingTotalPages: state.csUser.upcomingTotalPages,
    currentUpcomingTotal: state.csUser.upcomingCounter,
    type: "upcoming",
    timezone: state.csUser.timezone,
    language: state.csUser.language,
  };
};

const UpcomingEventsContainer = connect(mapStateToProps)(Events);

export default UpcomingEventsContainer;
