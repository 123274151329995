import React from "react";
import Form from "react-bootstrap/Form";

import "./SFCheckbox.scss";

export const SFCheckbox = ({
  id,
  name,
  label,
  checked,
  defaultChecked,
  required,
  register,
  onChange,
  children,
}) => (
  <Form.Group controlId={name}>
    <Form.Check className="sf-checkbox-control" checked={checked} label={label}>
      <Form.Check.Input
        name={name}
        type={"checkbox"}
        isValid
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        required={required}
        ref={register({ required })}
      />
      <div id={id} className="form-text">
        {label ?? children}
      </div>
    </Form.Check>
  </Form.Group>
);
