/* eslint-disable react-hooks/exhaustive-deps */
import { HubActions, UserActions } from "../../store/actions";

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ExpandPanel } from "@/components/ExpandPanel";

import moment from "moment-timezone";
import Moment from "react-moment";

import EventListContainer from "../../containers/EventListContainer";
import FilterBarContainer from "../../containers/FilterBarContainer";

import { Event } from "./../../components/Event";

import { ErrorPage } from "../ErrorPage";

/*import { ReactComponent as TopicIconDefault } from "../../assets/images/icon_topic_default_invert.svg";
import { ReactComponent as TopicIconSalesCloud } from "../../assets/images/icon_topic_sales_cloud_invert.svg";
import { ReactComponent as TopicIconServiceCloud } from "../../assets/images/icon_topic_service_cloud_invert.svg";
import { ReactComponent as TopicIconCommerceCloud } from "../../assets/images/icon_topic_commerce_cloud_invert.svg";
import { ReactComponent as TopicIconWork } from "../../assets/images/icon_topic_work_cloud_invert.svg";
import { ReactComponent as TopicIconMarketing } from "../../assets/images/icon_topic_marketing_cloud_invert.svg";*/

import { ReactComponent as BreadcrumbBack } from "../../assets/images/icon_back.svg";

import { formatTextContent } from "@/utils/stringHelpers";

import "./HubFilterList.scss";

const {
  loadHubEventsList,
  updateHubEventFilters,
  initHub,
  loadHubDetail,
  loadHubFeaturedEvent,
} = HubActions;

const { getUserFavoritesAll } = UserActions;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HubFilterList = ({
  listInit,
  listCurrent,
  timezone,
  hubDetail,
  hubId,
  hubConfig,
  businessLineId,
  favorites,
  tb_id,
  user,
  hubFeaturedEvent,
  notFoundId,
  history,
  language,
  isLoading,
  location,
  appConfig,
  criticalError,
  dispatch,
  listMode,
  numPerPage,
}) => {
  const query = useQuery();

  useEffect(() => {
    let popPostInit =
      history.action === "POP" && listInit && listCurrent === hubId;

    // back button assume everything is loaded appropriately.
    if (popPostInit && hubDetail) {
      // once the hub record has loaded, we can fire the inits for the list view.
      setHubFilterLocks(Object.keys(hubDetail.filter_config));
    } else if (
      !hubDetail ||
      (hubDetail.slug !== hubId && hubId !== notFoundId)
    ) {
      setHasInit(true);
      setHasHubInit(false);
      let hubObject = { id: hubId };
      dispatch(loadHubDetail(hubObject));
    } else if (hubDetail && hubDetail.slug === hubId) {
      setHasInit(true);
      // do not run the hub init if same hub
      //setHasHubInit(true);
      setNotFound(false);
      // re-initialization on clear when in hub state
      initLoad(Object.keys(hubDetail.filter_config));
    }

    if (tb_id && !hasFavoritesInit) {
      dispatch(getUserFavoritesAll({ tb_id }));
      setHasFavoritesInit(true);
    }
  }, [location, hubId]);

  useEffect(() => {
    if (
      hubConfig &&
      hubConfig.filterConfig &&
      hubConfig.filterConfig.userFiltersActive !== undefined
    ) {
      setUserFiltersActive(hubConfig.filterConfig.userFiltersActive);
    }
  }, [hubConfig]);

  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);

  const [hasInit, setHasInit] = useState(false);
  const [hasHubInit, setHasHubInit] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [hubFilterLocks, setHubFilterLocks] = useState([]);
  const favoritesIds = favorites ? favorites : [];

  const [userFiltersActive, setUserFiltersActive] = useState(false);

  const checkFavorite = (eventId) => {
    //console.log("calling cgeck favorite for hub featured video");
    //console.log(favoritesIds);
    //console.log(eventId);
    return favoritesIds.includes(eventId);
  };

  // filter group init
  if (!isLoading && hasInit && !hasHubInit && !notFound && !criticalError) {
    if (notFoundId === hubId) {
      setNotFound(true);
    } else if (hubDetail && !criticalError) {
      // once the hub record has loaded, we can fire the inits for the list view.

      let hubLocks = [];
      if (listMode === "topic") {
        let filterConfig = Object.keys(hubDetail.filter_config);
        filterConfig = filterConfig.filter(itm => itm !== "product")
        filterConfig = filterConfig.filter(itm => itm !== "type")
        filterConfig = filterConfig.filter(itm => itm !== "language")
        setHubFilterLocks(filterConfig);
        hubLocks = filterConfig;
      } else {
        setHubFilterLocks(Object.keys(hubDetail.filter_config));
        hubLocks = Object.keys(hubDetail.filter_config);
      }
      
      // console.log("filter locks");
      // console.log(Object.keys(hubDetail.filter_config));
      // console.log(hubDetail);
      // init in state

      dispatch(
        initHub({
          hubId: hubDetail.slug,
          hubFilterLocks: hubFilterLocks,
          hubFilterConfig: hubDetail.filter_config,
          numPerPage: numPerPage,
        })
      );

      if (hubDetail.featuredEvent) {
        dispatch(loadHubFeaturedEvent({ id: hubDetail.featuredEvent }));
      }
      setHasHubInit(true);
      // console.log("firing init load");
      // console.log(hubDetail.featuredEvent);
      initLoad(hubLocks);
    }
  }

  function initLoad(hubLocks) {
    // console.log("FIRING INIT LOAD");
    let popPostInit =
      history.action === "POP" && listInit && listCurrent === hubId;

    // special string that forces respective filter reset
    const resetStr = "_null_";

    let filter_load = {};
    filter_load["search"] = query.get("search")
      ? decodeURIComponent(query.get("search"))
      : "";
    filter_load["product"] = query.get("product")
      ? decodeURIComponent(query.get("product"))
      : "";
    filter_load["category"] = query.get("category")
      ? decodeURIComponent(query.get("category"))
      : "";
    filter_load["region"] = query.get("region")
      ? decodeURIComponent(query.get("region"))
      : "";
    filter_load["language"] = query.get("language")
      ? decodeURIComponent(query.get("language"))
      : "";
    filter_load["type"] = query.get("type")
      ? decodeURIComponent(query.get("type"))
      : "";
    filter_load["startDate"] = query.get("startDate");
    filter_load["endDate"] = query.get("endDate");
    filter_load["dateSpan"] = query.get("dateSpan");

    let filter_load_arrays = [
      "product",
      "type",
      "category",
      "region",
      "language",
    ];

    // convert array values to respective array objects
    // handle intentional reset as _null_
    // remove from dispatch if empty
    for (const filter_index in filter_load_arrays) {
      let filter_key = filter_load_arrays[filter_index];
      if (filter_load[filter_key] && filter_load[filter_key] !== resetStr)
        filter_load[filter_key] = filter_load[filter_key].split(",");
      else if (filter_load[filter_key] === resetStr)
        filter_load[filter_key] = [];
      else delete filter_load[filter_key];
    }

    let filter_load_available = Object.values(filter_load).some(
      (x) => x !== null && x !== ""
    );

    if (filter_load_available) {
      if (!popPostInit) {
        // console.log("loading from url filters");
        let currentTz = timezone ? timezone : Moment.globalTimezone;

        switch (filter_load["dateSpan"]) {
          case "today": {
            let start = moment().tz(currentTz).startOf("day");
            let end = start.clone().endOf("day");
            filter_load["startDate"] = start;
            filter_load["endDate"] = end;
            break;
          }
          case "this-week": {
            let start = moment().tz(currentTz).startOf("week");
            let end = start.clone().endOf("week");
            filter_load["startDate"] = start;
            filter_load["endDate"] = end;
            break;
          }
          case "next-week": {
            let start = moment().tz(currentTz).add(1, "weeks").startOf("week");
            let end = start.clone().endOf("week");
            filter_load["startDate"] = start;
            filter_load["endDate"] = end;
            break;
          }
          case "custom": {
            // console.log("loading custom");
            if (filter_load["startDate"] && filter_load["endDate"]) {
              let startTest = moment.unix(filter_load["startDate"]);
              let endTest = moment.unix(filter_load["endDate"]);
              if (startTest.isValid() && endTest.isValid()) {
                let start = startTest.tz(currentTz).startOf("day");
                let end = endTest.tz(currentTz).endOf("day");
                filter_load["startDate"] = start;
                filter_load["endDate"] = end;
              }
            }
            break;
          }
          default: {
            break;
          }
        }

        const filterAliases = appConfig.filterAliases;

        for (const filter in filter_load) {
          if (filter_load[filter] !== "") {
            let filterValue =
              filter_load[filter] !== resetStr ? filter_load[filter] : null;

            // map deprecated slugs to new slugs
            if (filterAliases) {
              if (filterValue && filterValue.constructor === Array) {
                for (var i = 0; i < filterValue.length; i++) {
                  let filterValueSingle = filterValue[i];
                  if (filterAliases[filterValueSingle]) {
                    filterValue[i] = filterAliases[filterValueSingle];
                  }
                }
                filterValue = [...new Set(filterValue)];
              }
            }

            /*console.log(
              "loading filter from url:" + filter + ", value: " + filterValue
            );*/

            if (hubLocks && !hubLocks.includes(filter)) {
              //console.log(hubLocks);
              //console.log(filter);
              let filterObject = {
                key: filter,
                values: filterValue,
                hubId: hubId,
              };
              dispatch(updateHubEventFilters(filterObject));
            } else {
              // console.log("locks contains: " + filter);
            }
          }
        }
      } else {
        // console.log("filter load skipped");
      }
    } else {
      // console.log("loading from init");
      let filterObject = { hubId: hubId };
      // console.log(filterObject);
      dispatch(loadHubEventsList(filterObject));
    }
  }

  const popPostInit = history.action === "POP" && listInit;

  const { t } = useTranslation();
  const hubTitle = hubDetail.title;
  const hubDescription = hubDetail.shortDescription;

  let userIsPremier = false;

  if (
    user &&
    (user.success_plan === "signature" || user.success_plan === "premier")
  ) {
    userIsPremier = true;
  }

  const pageTitle = hubTitle
    ? hubTitle + " - " + t("Customer Success Events")
    : "";

  // error handling
  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  if (!isLoading && notFound) {
    return <ErrorPage errorType="404" />;
  }

  const isPortraitVideo = hubFeaturedEvent && 
    hubFeaturedEvent.eventType &&
    hubFeaturedEvent.eventType.some((typeItem, typeIndex) => {
    return typeItem === "video";
  });

  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="og:title" content={pageTitle} />
          <meta name="description" content={hubDescription} />
          <meta name="og:description" content={hubDescription} />
        </Helmet>
      )}
      <div className="insetPage hubContainer">
        {((hasInit && hasHubInit && !notFound) || popPostInit) && (
          <Container className="fullPanel">
            {listMode === "product" && (
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h3
                    className="pt-0 float-left groupHeader"
                    id="hubGroupHeader"
                  >
                    {!userFiltersActive
                      ? t(`All Events ${hubId}`)
                      : t("Filtered Results")}
                  </h3>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} lg={4}>
                {listMode === "topic" && (
                  <div className="hubHeader">
                    <span className="hubBreadCrumb">
                      <Link
                        className={`hubLink-${hubDetail.businessLine}`}
                        to={`/product/${hubDetail.businessLine}`}
                      >
                        <BreadcrumbBack />
                        {t(`Product Line Title ${hubDetail.businessLine}`)}
                      </Link>{" "}
                      | {t(`Portrait Phase ${hubDetail.subPhase}`)}
                    </span>
                    <h2>{hubDetail.title}</h2>
                    <p
                      className="richText"
                      dangerouslySetInnerHTML={{
                        __html: formatTextContent(hubDetail.longDescription),
                      }}
                    ></p>
                    {hubDetail.recommendedPrerequisites && (
                      <ExpandPanel
                        title={t(
                          "Portrait Panel Title - Recommended Prerequisites"
                        )}
                        text={formatTextContent(
                          hubDetail.recommendedPrerequisites
                        )}
                      />
                    )}
                    {hubDetail.additionalResources && (
                      <ExpandPanel
                        title={t("Portrait Panel Title - Additional Resources")}
                        text={formatTextContent(hubDetail.additionalResources)}
                      />
                    )}
                    {hubDetail.goals && (
                      <ExpandPanel
                        title={t("Portrait Panel Title - Goals")}
                        text={formatTextContent(hubDetail.goals)}
                      />
                    )}
                  </div>
                )}
                <FilterBarContainer
                  filterMode="hub"
                  hubId={hubId}
                  hubFilterLocks={hubFilterLocks}
                  isPlp={businessLineId ? true : false}
                />
              </Col>
              <Col xs={12} lg={8} className="listTileContainer">
                {hubDetail.featuredEvent &&
                  hubFeaturedEvent &&
                  hubFeaturedEvent.id &&
                  hubFeaturedEvent.id === hubDetail.featuredEvent && (
                    <div className="hubFeaturedEvent">
                      <Event
                        event={hubFeaturedEvent}
                        key={hubFeaturedEvent.id}
                        {...hubFeaturedEvent}
                        isFavorite={() => checkFavorite(hubFeaturedEvent.id)}
                        tb_id={tb_id}
                        user={user}
                        portraitFeature={true}
                        portraitFeatureBackground={
                          hubDetail.featuredEventThumbnail
                        }
                      />
                    </div>
                  )}
                {hubDetail.oneOOSessionAvailable && (
                  <div className="hubListPromoPanel">
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h3 className="groupHeader">
                          {t("Request An Individual Session")}
                        </h3>
                        <p>{t("Request An Individual Session Description")}</p>
                        <a
                          className="btn btn-primary"
                          href={hubDetail.oneOOSessionAvailable}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("Get Started")}
                        </a>
                      </Col>
                    </Row>
                  </div>
                )}
                {!(hubDetail.oneOOSessionAvailable || isPortraitVideo) && (
                  <EventListContainer
                  filterMode="hub"
                  hubId={hubId}
                  hubFilterLocks={hubFilterLocks}
                  filtersActive={userFiltersActive}
                />
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

export default withRouter(HubFilterList);
