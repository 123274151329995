import i18n from "../../i18n";
import moment from "moment";
import "moment-timezone";
import Moment from "react-moment";
import { takeEvery, put } from "redux-saga/effects";
import { UserActions } from "../actions";
import { load } from "../../utils/storage";
import { CS_USER_STORAGE_KEY } from "../../utils/storage/keys";
import csFetch from "./api";

const {
  SET_USER_LANGUAGE,
  SET_USER_TIMEZONE,
  SET_USER_INFO,
  GET_USER_INFO,
  SET_USER_API_TOKEN,
  USER_REGISTRATIONS_SUCCEED,
  USER_FAVORITES_SUCCEED,
  SET_USER_FAVORITE_SUCCEED,
  DELETE_USER_FAVORITE_SUCCEED,
  DELETE_USER_FAVORITE,
  SET_USER_FAVORITE,
  USER_REGISTRATIONS,
  USER_FAVORITES,
  USER_FAVORITES_COUNTER,
  USER_FAVORITES_ALL,
  USER_FAVORITES_ALL_SUCCEED,
  USER_PAST_COUNTER,
  USER_UPCOMING_COUNTER,
} = UserActions;

export function setUserLanguage(action) {
  try {
    i18n.changeLanguage(action.payload.language);
    Moment.globalLocale = action.payload.locale;
    moment.locale(action.payload.locale);
  } catch (error) {
    console.log(error);
  }
}

export function setUserTimezone(action) {
  try {
    Moment.globalTimezone = action.payload.timezone;
  } catch (error) {
    console.log(error);
  }
}

export function* getUserInfo(action) {
  const user = load(CS_USER_STORAGE_KEY);
  yield put({ type: SET_USER_INFO, payload: user ?? null });
  // update api token outside of discrete user identity
  if (user && user.api_token)
    yield put({ type: SET_USER_API_TOKEN, payload: { token: user.api_token } });
}

export function* getUserRegistrations(action) {
  let tbidId = action.payload.tbidId;
  let eventStatus = action.payload.status;
  let pageNumber = action.payload.pageNumber || 0;
  let currentCounter = action.payload.currentCounter;
  let pageSize = 4;
  try {
    const { status, data } = yield csFetch(
      `users/${tbidId}/registrations/?eventStatus=${eventStatus}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
      "GET"
    );
    // console.log(data);
    if (status === 200) {
      yield put({
        type: USER_REGISTRATIONS_SUCCEED,
        payload: data.items,
        status: eventStatus,
        total: data.totalPages,
        withReset: pageNumber === 0,
      });
      if (eventStatus === "previous") {
        yield put({
          type: USER_PAST_COUNTER,
          payload: currentCounter || 1,
        });
      }
      if (eventStatus === "upcoming") {
        yield put({
          type: USER_UPCOMING_COUNTER,
          payload: currentCounter || 1,
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getUserFavorites(action) {
  let pageSize = 4;
  let tbidId = action.payload.tb_id;
  let pageNumber = action.payload.pageNumber || 0;
  let currentCounter = action.payload.currentCounter;
  try {
    const { status, data } = yield csFetch(
      `users/${tbidId}/favorites?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      "GET"
    );
    if (status === 200) {
      yield put({
        type: USER_FAVORITES_SUCCEED,
        payload: data.items,
        total: data.totalPages,
        withReset: currentCounter === 0,
      });
      // if (currentCounter)
      yield put({
        type: USER_FAVORITES_COUNTER,
        payload: currentCounter || 1,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getUserFavoritesAll(action) {
  let tbidId = action.payload.tb_id;
  try {
    const { status, data } = yield csFetch(
      `users/${tbidId}/favoritesEventIds`,
      "GET"
    );
    if (status === 200) {
      yield put({
        type: USER_FAVORITES_ALL_SUCCEED,
        payload: data,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* setUserFavorite(action) {
  let { tbidId, eventId, event } = action.payload;
  try {
    const { status } = yield csFetch(
      `users/${tbidId}/favorites/${eventId}`,
      "POST"
    );
    if (status === 200) {
      yield put({
        type: SET_USER_FAVORITE_SUCCEED,
        favorite: event,
        eventId,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* removeUserFavorite(action) {
  let { tbidId, eventId } = action.payload;
  try {
    const { status } = yield csFetch(
      `users/${tbidId}/favorites/${eventId}`,
      "DELETE"
    );
    if (status === 200) {
      yield put({
        type: DELETE_USER_FAVORITE_SUCCEED,
        favoriteId: eventId,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* loadUser() {
  yield takeEvery(SET_USER_LANGUAGE, setUserLanguage);
  yield takeEvery(SET_USER_TIMEZONE, setUserTimezone);
  yield takeEvery(GET_USER_INFO, getUserInfo);
  yield takeEvery(USER_REGISTRATIONS, getUserRegistrations);
  yield takeEvery(USER_FAVORITES, getUserFavorites);
  yield takeEvery(USER_FAVORITES_ALL, getUserFavoritesAll);
  yield takeEvery(DELETE_USER_FAVORITE, removeUserFavorite);
  yield takeEvery(SET_USER_FAVORITE, setUserFavorite);
}
