import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./translations/en";
import translationJP from "./translations/jp";
import translationDE from "./translations/de";
import translationES from "./translations/es";
import translationFR from "./translations/fr";
import translationIT from "./translations/it";
import translationPT from "./translations/pt";
import translationZH from "./translations/zh";
import translationKO from "./translations/ko";
import translationNL from "./translations/nl";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  jp: {
    translation: translationJP,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
  pt: {
    translation: translationPT,
  },
  zh: {
    translation: translationZH,
  },
  ko: {
    translation: translationKO,
  },
  nl: {
    translation: translationNL,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    whitelist: ["en", "zh", "nl", "fr", "de", "it", "jp", "es", "pt", "ko"],
    resources,
    nonExplicitWhitelist: true,
    load: "languageOnly",
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
