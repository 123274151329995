import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "@/text-encoding-polyfill.js";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { CookiesProvider } from "react-cookie";

import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import configureStore from "./configureStore";
import configureMoment from "./configureMoment";

// import needed locales
import "moment/locale/ja";
import "moment/locale/zh-cn";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/ko";

const store = configureStore();

// initialize globals
configureMoment();

ReactDOM.render(
  <React.StrictMode>
    <IconSettings iconPath="/assets/icons">
      <Provider store={store}>
        <CookiesProvider>
          <BrowserRouter>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </BrowserRouter>
        </CookiesProvider>
      </Provider>
    </IconSettings>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
