export const LOAD_HUB_DETAIL = "LOAD_HUB_DETAIL";
export const LOAD_HUB_DETAIL_SUCCEED = "LOAD_HUB_DETAIL_SUCCEED";
export const LOAD_HUB_DETAIL_NOTFOUND = "LOAD_HUB_DETAIL_NOTFOUND";

export const UPDATE_HUB_EVENT_FILTERS = "UPDATE_HUB_EVENT_FILTERS";
export const UPDATE_HUB_EVENT_FILTERS_SILENT =
  "UPDATE_HUB_EVENT_FILTERS_SILENT";
export const CLEAR_HUB_EVENT_FILTERS = "CLEAR_HUB_EVENT_FILTERS";

export const TOGGLE_HUB_PREMIER_EXCLUDE = "TOGGLE_HUB_PREMIER_EXCLUDE";

export const LOAD_HUBS = "LOAD_HUBS";
export const LOAD_HUBS_SUCCEED = "LOAD_HUBS_SUCCEED";

export const INIT_HUB = "INIT_HUB";
export const UPDATE_HUB_CONFIG = "UPDATE_HUB_CONFIG";
export const LOAD_HUB_EVENTS_LIST = "LOAD_HUB_EVENTS_LIST";

export const LOAD_MORE_HUB_EVENTS = "LOAD_MORE_HUB_EVENTS";
export const LOAD_MORE_HUB_EVENTS_SUCCEED = "LOAD_MORE_HUB_EVENTS_SUCCEED";
export const LOAD_MORE_HUB_EVENTS_FAILED = "LOAD_MORE_HUB_EVENTS_FAILED";

export const LOAD_HUB_FEATURED_EVENT = "LOAD_HUB_FEATURED_EVENT";
export const LOAD_HUB_FEATURED_EVENT_SUCCEED =
  "LOAD_HUB_FEATURED_EVENT_SUCCEED";

export function loadHubEventsList(filterData) {
  return {
    type: LOAD_HUB_EVENTS_LIST,
    eventFilters: filterData,
  };
}

export function initHub(payload) {
  return {
    type: INIT_HUB,
    payload,
  };
}

export function updateHubConfig(payload) {
  return {
    type: UPDATE_HUB_CONFIG,
    payload,
  };
}

export function updateHubEventFilters(filterData) {
  return {
    type: UPDATE_HUB_EVENT_FILTERS,
    eventFilters: filterData,
  };
}

export function updateHubEventFiltersSilent(filterData) {
  return {
    type: UPDATE_HUB_EVENT_FILTERS_SILENT,
    eventFilters: filterData,
  };
}

export function clearHubEventFilters(filterData) {
  return {
    type: CLEAR_HUB_EVENT_FILTERS,
    eventFilters: filterData,
  };
}

export function toggleHubPremierExclude(filterData) {
  return {
    type: TOGGLE_HUB_PREMIER_EXCLUDE,
    eventFilters: filterData,
  };
}

export function loadHubDetail(payload) {
  return { type: LOAD_HUB_DETAIL, payload };
}

export function loadHubDetailSucceed(payload) {
  return { type: LOAD_HUB_DETAIL_SUCCEED, payload };
}

export function loadHubDetailNotFound(payload) {
  return { type: LOAD_HUB_DETAIL_NOTFOUND, payload };
}

export function loadHubs(payload) {
  return { type: LOAD_HUBS, payload };
}

export function loadHubsSucceed(payload) {
  return { type: LOAD_HUBS_SUCCEED, payload };
}

export function loadMoreHubEvents(payload) {
  return { type: LOAD_MORE_HUB_EVENTS, payload };
}

export function loadMoreHubEventsSucceed(payload) {
  return { type: LOAD_MORE_HUB_EVENTS_SUCCEED, payload };
}

export function loadMoreHubEventsFailed(payload) {
  return { type: LOAD_MORE_HUB_EVENTS_FAILED, payload };
}

export function loadHubFeaturedEvent(payload) {
  return { type: LOAD_HUB_FEATURED_EVENT, payload };
}

export function updateHubLanguageFilter(language) {
  return {
    type: UPDATE_HUB_EVENT_FILTERS,
    eventFilters: { hubId: 'default', key: 'language', values: [language] }
  };
}