import { HubActions, EventActions } from "../actions";
import { put, takeEvery, takeLatest, select, delay } from "redux-saga/effects";
import csFetch from "./api";
import { replace } from "connected-react-router";
const {
  LOAD_HUB_EVENTS_LIST,
  UPDATE_HUB_EVENT_FILTERS,
  CLEAR_HUB_EVENT_FILTERS,
  TOGGLE_HUB_PREMIER_EXCLUDE,
  LOAD_HUB_DETAIL,
  LOAD_HUB_DETAIL_SUCCEED,
  LOAD_HUB_DETAIL_NOTFOUND,
  LOAD_HUBS,
  LOAD_HUBS_SUCCEED,
  LOAD_MORE_HUB_EVENTS,
  LOAD_HUB_FEATURED_EVENT,
  LOAD_HUB_FEATURED_EVENT_SUCCEED,
  UPDATE_HUB_CONFIG,
} = HubActions;

const { RENDER_EVENTS_LIST, LOAD_MORE_EVENTS_SUCCEED } = EventActions;

export const getTotalPages = (state) => state.csEvent.totalPages;
export const getHubFilters = (state) => state.csHub.eventHubFilters;
export const getHubConfig = (state) => state.csHub.eventHubConfig;

export const getExcludePremier = (state) => state.csEvent.excludePremier;

export function* fetchHubEventsList(action) {
  // delay to allow fetchLatest / debouncing
  yield delay(300);

  let eventHubFilters = yield select(getHubFilters);
  let excludePremierFlag = yield select(getExcludePremier);
  let eventHubConfig = yield select(getHubConfig);
  let eventFilters = eventHubFilters[action.eventFilters.hubId];
  let hubConfig = eventHubConfig[action.eventFilters.hubId];

  let filterUrl = "";

  filterUrl +=
    "product=" +
    (eventFilters.product ? encodeURIComponent(eventFilters.product) : "");
  filterUrl +=
    "&category=" +
    (eventFilters.category ? encodeURIComponent(eventFilters.category) : "");
  filterUrl +=
    "&region=" +
    (eventFilters.region ? encodeURIComponent(eventFilters.region) : "");
  filterUrl +=
    "&language=" +
    (eventFilters.language ? encodeURIComponent(eventFilters.language) : "");
  filterUrl +=
    "&type=" + (eventFilters.type ? encodeURIComponent(eventFilters.type) : "");
  filterUrl +=
    "&search=" +
    (eventFilters.search ? encodeURIComponent(eventFilters.search) : "");
  filterUrl +=
    "&startDate=" +
    (eventFilters.startDate ? eventFilters.startDate.unix() : "");
  filterUrl +=
    "&endDate=" + (eventFilters.endDate ? eventFilters.endDate.unix() : "");
  filterUrl += "&dateSpan=" + eventFilters.dateSpan;

  let filters = filterUrl.split("&");
  let filtersSelected = filters.slice(0, -1);

  let filtersUserSelected = filtersSelected.some(
    (x) => x.indexOf("=") !== x.length - 1
  );

  let filterCount = 0;
  filtersSelected.forEach(function (x) {
    if (x.indexOf("=") !== x.length - 1) {
      filterCount = filterCount + 1;
    }
    let addtlFilters = decodeURIComponent(x).split(",").length - 1;
    filterCount = filterCount + addtlFilters;
  });

  if (filtersUserSelected) {
    filterUrl = "?filter=true&" + filterUrl;
  } else {
    filterUrl = "?filter=false&" + filterUrl;
  }

  let eventFiltersMerged = {
    ...eventFilters,
    ...hubConfig.filterConfig,
    excludePremier: excludePremierFlag,
  };

  ///////// ADDED TO FIX EVENTS NOT COMING BACK FOR PORTRAITS - TO BE REMOVED - ///////
  // eventFiltersMerged.product = [];
  // eventFiltersMerged.type = [];
  eventFiltersMerged.language = [];
  ///////// ADDED TO FIX EVENTS NOT COMING BACK FOR PORTRAITS - TO BE REMOVED - ///////

  // replace history with bookmark link per filter set
  if (
    hubConfig.filterConfig.mode &&
    hubConfig.filterConfig.mode === "product"
  ) {
    yield put(replace("/product/" + action.eventFilters.hubId + filterUrl));
  } else {
    yield put(replace("/portraits/" + action.eventFilters.hubId + filterUrl));
  }
  yield put({
    type: UPDATE_HUB_CONFIG,
    payload: {
      hubId: action.eventFilters.hubId,
      hubFilterConfig: {
        ...hubConfig.filterConfig,
        userFiltersActive: filtersUserSelected,
        userFiltersActiveNum: filterCount,
      },
    },
  });
  const { status, data } = yield csFetch("events", "POST", eventFiltersMerged);
  if (status === 200) {
    yield put({
      type: RENDER_EVENTS_LIST,
      eventListCurrent: action.eventFilters.hubId,
      eventList: data.events,
      totalEvents: data.totalEvents,
      totalPages: data.totalPages,
    });
  }
}

export function* loadMoreHubEventsList(action) {
  let eventHubFilters = yield select(getHubFilters);
  let excludePremierFlag = yield select(getExcludePremier);
  let eventHubConfig = yield select(getHubConfig);

  let eventFilters = eventHubFilters[action.payload.hubId];
  let hubConfig = eventHubConfig[action.payload.hubId];

  let eventFiltersMerged = {
    ...eventFilters,
    ...hubConfig.filterConfig,
    excludePremier: excludePremierFlag,
  };

  let totalPages = yield select(getTotalPages);

  if (totalPages >= eventFilters.page) {
    const { status, data } = yield csFetch(
      "events",
      "POST",
      eventFiltersMerged
    );
    if (status === 200) {
      yield put({
        type: LOAD_MORE_EVENTS_SUCCEED,
        newEventList: data.events,
        totalEvents: data.totalEvents,
      });
    }
  }
}

export function* loadHubDetail(action) {
  const { status, data } = yield csFetch(
    `portrait/${action.payload.id}`,
    "GET"
  );
  if (status === 200) {
    yield data;
    let modifiedData = data;

    delete modifiedData.filter_config.product;
    delete modifiedData.filter_config.type;
    yield modifiedData;

    yield put({ type: LOAD_HUB_DETAIL_SUCCEED, singleHub: modifiedData });
  } else if (status === 404) {
    yield put({
      type: LOAD_HUB_DETAIL_NOTFOUND,
      hubId: action.payload.id,
    });
  }
}

export function* loadHubs(action) {
  let hubFilter = {}; //{ parentEventId: action.payload.parentEventId };
  const { status, data } = yield csFetch("portraits", "POST", hubFilter);
  if (status === 200) {
    yield put({
      type: LOAD_HUBS_SUCCEED,
      hubList: data.portraits,
    });
  }
}

export function* loadHubFeaturedEventDetail(action) {
  const { status, data } = yield csFetch(`event/${action.payload.id}`, "GET");
  if (status === 200) {
    yield put({ type: LOAD_HUB_FEATURED_EVENT_SUCCEED, singleEvent: data });
  } else if (status === 404) {
    /*yield put({
      type: LOAD_EVENT_DETAIL_NOTFOUND,
      eventId: action.payload.id,
    });*/
  }
}

export function* loadHub() {
  yield takeLatest(LOAD_HUB_EVENTS_LIST, fetchHubEventsList);
  yield takeEvery(LOAD_MORE_HUB_EVENTS, loadMoreHubEventsList);
  yield takeEvery(LOAD_HUB_DETAIL, loadHubDetail);
  yield takeEvery(LOAD_HUBS, loadHubs);
  yield takeLatest(UPDATE_HUB_EVENT_FILTERS, fetchHubEventsList);
  yield takeLatest(CLEAR_HUB_EVENT_FILTERS, fetchHubEventsList);
  yield takeLatest(TOGGLE_HUB_PREMIER_EXCLUDE, fetchHubEventsList);
  yield takeEvery(LOAD_HUB_FEATURED_EVENT, loadHubFeaturedEventDetail);
}
