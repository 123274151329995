import { connect } from "react-redux";
import { FavoritedEvents } from "../pages/FavoritedEvents";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.csUser.user,
    criticalError: state.csOther.criticalError,
    favorites: state.csUser.favorites,
    favoritesTotalPages: state.csUser.favoritesTotalPages,
    currentFavoritesTotal: state.csUser.favoritesCounter,
    timezone: state.csUser.timezone,
    language: state.csUser.language,
  };
};

const FavoritedEventsContainer = connect(mapStateToProps)(FavoritedEvents);

export default FavoritedEventsContainer;
