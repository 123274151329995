import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { UserActions } from "../../store/actions";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Event } from "../../components/Event";

import "./Events.scss";
import { useDispatch } from "react-redux";

const { getUserRegistrations } = UserActions;

const Events = ({
  eventList,
  title,
  type,
  showBtns,
  showLikes,
  showPremierTag,
  tb_id,
  tb_user_plan,
  favorites,
  upcomingTotalPages,
  currentUpcomingTotal,
  pastTotalPages,
  currentPastTotal,
  isShowing,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const checkFavorite = (eventId) => {
    return favorites && favorites.includes(eventId);
  };

  const loadMore = () => {
    if (type === "past") {
      dispatch(
        getUserRegistrations({
          tbidId: tb_id,
          status: "previous",
          pageNumber: currentPastTotal,
          currentCounter: currentPastTotal + 1,
        })
      );
    }
    if (type === "upcoming") {
      dispatch(
        getUserRegistrations({
          tbidId: tb_id,
          status: "upcoming",
          pageNumber: currentUpcomingTotal,
          currentCounter: currentUpcomingTotal + 1,
        })
      );
    }
  };

  const RenderLoadMoreBtn = ({ condition }) => {
    return (
      <div className={condition ? "events_load-more" : "events_load-more hide"}>
        <Button className="loadMoreButton" onClick={loadMore}>
          {t("Load More Events")}
        </Button>
      </div>
    );
  };

  const RenderNoUpcomingEvents = () => {
    return (
      <div className="no-upcoming-event_wrapper">
        <div className="no-upcoming-event_title">
          {t("User dashboard No Upcoming Events")}
        </div>
        <div className="no-upcoming-event_description">
          {t("User dashboard Upcoming Instructions")}
        </div>
        <Link
          className="no-upcoming-event_link"
          to={{
            pathname: "/events",
          }}
        >
          {t("User dashboard Find your next event")}
        </Link>
      </div>
    );
  };

  const RenderContentByType = () => {
    if (type === "upcoming" && !eventList.length) {
      return <RenderNoUpcomingEvents />;
    } else {
      return (
        <Row>
          {eventList && eventList.length > 0
            ? eventList.map((info, index) => (
                <Col xs={12} md={6} lg={4} xl={3} key={info.registration.id}>
                  <Event
                    key={info.registration.id}
                    {...info.event}
                    event={info.event}
                    showBtns={showBtns}
                    showLikes={showLikes}
                    showPremierTag={showPremierTag}
                    tb_id={tb_id}
                    tb_user_plan={tb_user_plan}
                    launchLink={
                      info.registration.registration_launch_url
                        ? info.registration.registration_launch_url
                        : info.event.launchUrl
                    }
                    isFavorite={() => checkFavorite(info.event.id)}
                  />
                </Col>
              ))
            : [1, 2, 3, 4].map((event, index) => (
                <Col xs={12} md={6} lg={4} xl={3} key={index}>
                  <Skeleton
                    key={"skeleton-card-" + index}
                    className="skeletonCard"
                    height={260}
                    duration={3}
                  />
                </Col>
              ))}
          <RenderLoadMoreBtn
            condition={
              type === "past"
                ? pastTotalPages > currentPastTotal
                : upcomingTotalPages > currentUpcomingTotal
            }
          />
        </Row>
      );
    }
  };

  return isShowing ? (
    <div className="colPanel">
      <SkeletonTheme color="#ccc" highlightColor="#ddd">
        <div className="featureGroup">
          <Row>
            <Col xs={12}>
              <h2 className="float-left groupHeader">{t(title)}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Container className="upcomingCol">
                <RenderContentByType />
              </Container>
            </Col>
          </Row>
        </div>
      </SkeletonTheme>
    </div>
  ) : null;
};

Events.propTypes = {
  eventList: PropTypes.array,
};

export default Events;
