import React from "react";
import { useTranslation } from "react-i18next";

import Card from "react-bootstrap/Card";

import moment from "moment-timezone";
import Moment from "react-moment";

import {
  useDurationFormat,
  useEventTranslation,
  useEventTranslationBulk,
  useTextTruncate,
} from "../../../hooks";

import { InteractiveResult } from "../InteractiveResult";
import { ReactComponent as VideoPlayIcon } from "../../../assets/images/icon_video_play_outline.svg";

import "./Result.scss";

export const Result = (props) => {
  const { result, cardStyle } = props;
  const { t } = useTranslation();

  const isExpertCoachingCard1 =
    cardStyle === "officehours" ||
    cardStyle === "overview" ||
    cardStyle === "adminfundamentals";
  const isExpertCoachingCard2 = cardStyle === "expertcoaching";
  const isPortrait = result.raw.successeventitemtype === "portrait";
  const portraitTypeValue = result.raw.successeventdeliverytypesubtype;
  const portraitType = useEventTranslation(
    "deliveryTypeSubType",
    portraitTypeValue
  );
  const isVideo = result.raw.successeventeventtype === "video";
  const eventTypeKey = result.raw.successeventeventtype;
  const eventTypeTranslated = useEventTranslation("eventType", eventTypeKey);
  const eventTier = result.raw.successeventitemtier;
  const product = result.raw.successeventproduct;
  const eventProductKey = product ? product[0] : null;
  const eventProductTranslated = useEventTranslation(
    "product",
    eventProductKey
  );
  const productTagsTranslated = useEventTranslationBulk("product", product);
  /* const language = result.raw.language; */

  const start = moment(result.raw.successeventstartdate);
  const durationStr = useDurationFormat(
    result.raw.successeventvideoduration,
    "short"
  );

  const newTitle = result.title.replace(/&amp;/g, "&");

  const titleTruncated = useTextTruncate(newTitle, {
    length: 80,
    ending: "...",
  });

  let cardClass = "coveo--result eventCard";

  cardClass = isPortrait ? cardClass + " portraitCard" : cardClass;
  cardClass = cardClass + " " + cardStyle;

  let showDescription = isExpertCoachingCard1 || isExpertCoachingCard2;

  const overviewTruncated = useTextTruncate(
    result.raw.successeventdescription !== "null"
      ? result.raw.successeventdescription
      : "",
    {
      length:
        cardStyle === "overview" ||
        cardStyle === "expertcoaching" ||
        cardStyle === "adminfundamentals" ||
        cardStyle === "officehours"
          ? 320
          : 105,
      ending: "...",
    },
    true
  );

  const topicValue = result.raw.successeventitemsubcategory? result.raw.successeventitemsubcategory[0] : null;
  const topic = useEventTranslation("subCategory", topicValue);

  const levelOfExpertiseValue = result.raw.successeventlevelofexpertise;
  const levelOfExpertise = useEventTranslation("deliveryRoleExpertise", levelOfExpertiseValue);
  
  const roleValue = result.raw.successeventtargetcustomerrole;
  const role = useEventTranslation("targetCustomerRole", roleValue);

  const language = result.raw.language;

  const metadataConcat = [topic, role, levelOfExpertise, language]
    .filter((str) => str)
    .join(", ");
  // const metadataConcat = "User Training & Development, Admin, Beginner, English";
  const renderCallout = () => {
    return !isVideo ? (
      start.isSame(moment().clone().startOf("day"), "d") ? (
        <div className="labelCallout">
          <span className="labelCallout--text">{t("Today")}</span>
          <span className="labelCallout--time">
            <Moment date={result.raw.successeventstartdate} format="h:mm A z" />
          </span>
        </div>
      ) : (
        <div className="dateCallout">
          <span className="dateCallout--month">
            <Moment date={result.raw.successeventstartdate} format="MMM" />
          </span>
          <span className="dateCallout--day">
            <Moment date={result.raw.successeventstartdate} format="D" />
            {/* {isBucketParent && !sameDayFlag && (
              <span className="dateSup">+</span>
            )} */}
          </span>
          <span className="dateCallout--time">
            <Moment date={result.raw.successeventstartdate} format="h:mm A z" />
          </span>
        </div>
      )
    ) : (
      <div className="vidCallout">
        <span className="vidCallout--icon">
          <VideoPlayIcon />
        </span>
        <span className="vidCallout--time">{durationStr}</span>
      </div>
    );
  };

  const renderEventTags = () => {
    return (
      <div className="eventTagWrapper">
        {portraitType ? (
          <span className="eyebrowLabel sub-tag" key={portraitType}>
            {t(`${portraitType}`)}
          </span>
        ) : (
          <span className="eyebrowLabel sub-tag" key={eventTypeTranslated}>
            {eventTypeTranslated}
          </span>
        )}
        {eventProductKey && (
          <span className={"eyebrowLabel " + eventProductKey}>
            {eventProductTranslated && eventProductTranslated}
          </span>
        )}
        {metadataConcat && (
          <span className="eccmetadata">{metadataConcat}</span>
        )}
        {product &&
          productTagsTranslated &&
          product.slice(1, product.length).map(
            (productItem) =>
              productTagsTranslated[productItem] && (
                <span className="eyebrowLabel sub-tag" key={productItem}>
                  {productTagsTranslated[productItem]}
                </span>
              )
          )}
        {/* {language &&
          language.map((languageItem) => (
            <span className="eyebrowLabel sub-tag" key={languageItem}>
              {t(languageItem)}
            </span>
          ))} */}
      </div>
    );
  };

  const renderPremierTag = () => {
    return (
      <>
        {eventTier &&
          (eventTier === "premier" || eventTier === "signature") &&
          (isExpertCoachingCard1 || isExpertCoachingCard2) && (
            <div className={"eyebrowLabel tier-tag tier-tag_left"}>premier</div>
          )}
        {eventTier &&
          (eventTier === "premier" || eventTier === "signature") &&
          !isExpertCoachingCard1 &&
          !isExpertCoachingCard2 && (
            <span className={"eyebrowLabel tier-tag tier-tag_right"}>
              premier
            </span>
          )}
      </>
    );
  };

  const renderCardBody = () => {
    return (
      <Card.Body>
        {!isPortrait && !isExpertCoachingCard2 && renderCallout()}
        {!isExpertCoachingCard2 && renderEventTags()}

        <div className="cardBodyInner">
          <span className="eventTitle">{titleTruncated}</span>
        </div>
        {showDescription && (
          <div className="expertCoachingOverview">{overviewTruncated}</div>
        )}
        {isExpertCoachingCard2 && renderEventTags()}
        {renderPremierTag()}
      </Card.Body>
    );
  };

  return (
    <InteractiveResult result={result}>
      <Card className={cardClass}>{renderCardBody()}</Card>
    </InteractiveResult>
  );
};
