/* eslint-disable react-hooks/exhaustive-deps */
import { OtherActions } from "../../store/actions";

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";

import "./ShareEvent.scss";

const { toggleShareModal } = OtherActions;

const ShareEvent = ({
  appConfig,
  search,
  language,
  isUser,
  tbidUser,
  timezone,
  shareVisible,
  shareUrl,
  authVisible,
  authErrorMessage,
  dispatch,
  location,
  user,
}) => {
  useEffect(() => {
    setShareModalShow(shareVisible);
 
    setShareLink(shareUrl || window.location.href);
    
  }, [shareVisible, authVisible, location, search]);

  const [shareLink, setShareLink] = useState(window.location.href);

  const [shareModalShow, setShareModalShow] = useState(false);

  const handleShareModalClose = () => {
    setShareModalShow(false);
    let modalPayload = { show: false };
    dispatch(toggleShareModal(modalPayload));
  };

  const { t } = useTranslation();

  const linkInput = React.useRef();

  const copyShareLink = () => {
    linkInput.current.select();
    document.execCommand("copy");
  };

  return (
    <>
      {/* share modal */}
      <Modal
        show={shareModalShow}
        onHide={handleShareModalClose}
        className="settingsModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Share Link")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item>
              <input
                type="text"
                readonly="true"
                ref={linkInput}
                className="form-control"
                value={shareLink}
              />
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="mr-auto" onClick={copyShareLink}>
            {t("Copy Link")}
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
};

export default withRouter(ShareEvent);
