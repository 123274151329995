import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import VideoController from "../../controllers/VideoController";

import { ReactComponent as VideoLoadingIcon } from "../../assets/images/icon_video_loading.svg";

import "./HeroBanner.scss";

export const HeroBanner = ({
  children,
  eventProductKey,
  minHeight,
  customStyles,
  eventDetail,
  eventTypeKey,
  user,
  layoutType,
  uniqueImageStyle = {},
}) => {
  const [imageSource, setImageSource] = useState(undefined);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const { t } = useTranslation();

  const cssVars = {
    "--minHeight": minHeight,
    ...customStyles,
  };

  useEffect(() => {
    async function getImages() {
      const img = await import(
        `../../assets/images/hero-banners/${eventTypeKey}.png`
      )
        .then((image) => image.default)
        .catch((error) => {
          console.debug("error", error);
        });
      const img2x = await import(
        `../../assets/images/hero-banners/${eventTypeKey}@2x.png`
      )
        .then((image) => image.default)
        .catch((error) => {
          console.debug("error", error);
        });

      // If the image doesnt exist, fail gracefully
      if (!img || !img2x) return;
      setImageSource({
        default: img,
        retina: img2x,
      });
    }

    if (eventTypeKey) {
      getImages();
    }
  }, [eventTypeKey]);

  function imageOnLoad() {
    setImageLoaded(true);
  }

  function videoOnLoad() {
    setVideoLoaded(true);
  }

  let eventTypeClass = "";
  if (eventDetail) {
    eventTypeClass =
      eventDetail.eventGroupType &&
      eventDetail.eventGroupType === "Bucket Parent"
        ? "bucket-parent"
        : "";
    if (
      eventDetail.eventTier === "signature" ||
      eventDetail.eventTier === "premier"
    ) {
      eventTypeClass = eventTypeClass + " premier-event";
    }
  }
  // incorporate type key into wrapper class
  eventTypeClass = eventTypeClass + " " + eventTypeKey;
  eventTypeClass = layoutType
    ? eventTypeClass + " layout-" + layoutType
    : eventTypeClass;

  if (
    eventTypeKey === "hero-panel" &&
    user &&
    (user.success_plan === "signature" || user.success_plan === "premier")
  ) {
    eventTypeClass = eventTypeClass + " premier-event";
  }

  return (
    <div className={"heroBanner " + eventTypeClass} style={cssVars}>
      <div className={"maskedBg " + eventProductKey} style={cssVars}>
        <div className="mask"></div>
        {imageSource && (
          <img
            className={`image ${imageLoaded && "image-loaded"}`}
            style={uniqueImageStyle}
            src={imageSource.default}
            srcSet={`${imageSource.retina} 2x`}
            onLoad={imageOnLoad}
            alt={eventProductKey}
          />
        )}
      </div>
      <Container className="heroContainer">
        {eventDetail &&
        eventDetail.eventType &&
        eventDetail.eventType[0] === "video" &&
        eventDetail.recordingType &&
        eventDetail.recordingID ? (
          <Row>
            <Col xs={12} lg={12}>
              <div className={`videoContainer ${videoLoaded && "loaded"}`}>
                <div className="videoContainer--inside">
                  <VideoController
                    embed_id={eventDetail.recordingID}
                    embed_type={eventDetail.recordingType}
                    onVideoLoad={videoOnLoad}
                    user={user}
                  />
                </div>
                {!videoLoaded && (
                  <div className="videoContainer--loader">
                    <VideoLoadingIcon />
                    <div className="videoContainer--loaderLabel">
                      {t("Video Loading...")}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {children}
      </Container>
    </div>
  );
};

HeroBanner.defaultProps = {
  eventProductKey: "default",
};

HeroBanner.propTypes = {
  eventProductKey: PropTypes.string, // OPTIONS: default | sales-cloud | marketing-cloud | service-cloud
  minHeight: PropTypes.string,
  eventTypeKey: PropTypes.string,
  eventDetail: PropTypes.object,
};
