import { CA_STATES, JP_STATES, US_STATES } from "./constants";

export const getStateOptions = (selectedCountry) => {
  if (!selectedCountry) return null;

  if (selectedCountry.value === "CA") return CA_STATES;
  if (selectedCountry.value === "JP") return JP_STATES;
  if (selectedCountry.value === "US") return US_STATES;

  return null;
};
