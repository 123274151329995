import React from "react";

// for page not found
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Jumbotron from "react-bootstrap/Jumbotron";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { HeroBanner } from "../../components/HeroBanner";

import { useTranslation } from "react-i18next";

import "./ErrorPage.scss";

export const ErrorPage = ({ errorType }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        {errorType === "404" && <title>{t("Page Not Found")}</title>}
        {/*errorType === "500" && <title>{t("Application Error")}</title>*/}
      </Helmet>
      <div>
        <div className="errorPageWrapper">
          <HeroBanner
            minHeight="80vh"
            eventProductKey="default"
            eventTypeKey="page-not-found"
          >
            <Row>
              <Col xs={7} lg={6}>
                <Jumbotron>
                  {errorType === "404" && (
                    <>
                      <h1>{t("Still looking...")}</h1>
                      <p>{t("This page was not found.")}</p>
                      <Link
                        to={{
                          pathname: "/",
                        }}
                        className="btn btn-primary"
                      >
                        {t("Explore Events")}
                      </Link>
                    </>
                  )}
                  {errorType === "500" && (
                    <>
                      <h1>{t("Oops!")}</h1>
                      <p>
                        {t(
                          "A system error occured. We are looking into this issue. Please try again soon."
                        )}
                      </p>
                    </>
                  )}
                </Jumbotron>
              </Col>
            </Row>
          </HeroBanner>
        </div>
      </div>
    </>
  );
};
