import { connect } from "react-redux";
import { TrendingTopics } from "../pages/TrendingTopics";

const mapStateToProps = (state, ownProps) => {
    const mode = ownProps.mode ? ownProps.mode : "default"
  return {
    eventList: ownProps.eventList,
    timezone: state.csUser.timezone,
    language: state.csUser.language,
    title: ownProps.title,
    seeAllTitle: ownProps.seeAllTitle,
    groupKey: ownProps.groupKey,
    groupConfig: ownProps.groupConfig,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    favorites: state.csUser.favoritesAll,
    mode: mode,
  };
};

const TrendingTopicsContainer = connect(mapStateToProps)(TrendingTopics);

export default TrendingTopicsContainer;