import React from 'react';
import PropTypes from "prop-types";

import { ReactComponent as SpinnerIcon } from '../../assets/images/spinner.svg';

import './styles.scss';

class LoadingText extends React.PureComponent {
  state = {
    nextLabel: '',
    currentLabel: '',
    animationIndex: 2,
    labelIndex: 0,
    labelInterval: null,
    dotInterval: null,
    dots: 0,
  };

  componentDidMount() {
    const { labels } = this.props;

    const labelInterval = labels.length > 1 ? setInterval(this.labelTimer, 1500) : null;
    const dotInterval = setInterval(this.dotTimer, 500);

    this.setState({ dotInterval, labelInterval, nextLabel: labels[0], currentLabel: '' }, () => {
      this.labelTimer();
      this.dotTimer();
    });
  }

  componentWillUnmount() {
    const { labelInterval, dotInterval } = this.state;
    
    if (labelInterval) clearInterval(labelInterval);
    if (dotInterval) clearInterval(dotInterval);
  }

  dotTimer = () => {
    let { dots } = this.state;

    dots++;
    if (dots > 4) dots = 1;

    this.setState({ dots });
  };

  labelTimer = () => {
    const { labels } = this.props;
    let { nextLabel, currentLabel, labelIndex, animationIndex } = this.state;

    animationIndex++;
    if (animationIndex > 2) animationIndex = 0;

    if (animationIndex % 3 === 0) {
      labelIndex++;
      if (labelIndex > labels.length - 1) labelIndex = 0;
      
      currentLabel = nextLabel || '';
      nextLabel = labels[labelIndex] || '';
    }
    
    this.setState({
      nextLabel,
      currentLabel,
      labelIndex,
      animationIndex,
    })
  };

  render() {
    const { textClass, textStyle } = this.props;
    const { nextLabel, currentLabel, animationIndex } = this.state;
    
    return (
      <span className="loadingTextContainer">
        <span className="loadingTextInner">
          <span className="loadingTextSpinner">
            <SpinnerIcon alt="spinner" />
          </span>
          <div className="loadingTextText">
            <div className="loadingTextProxy">
              {nextLabel.length > currentLabel.length ? nextLabel : currentLabel}
            </div>

            <div
              className={`loadingTextNext animating-${animationIndex} ${textClass || ''}`}
              style={textStyle}
            >
              {nextLabel}
              <span className="loadingTextDots">
                {Array(this.state.dots).join('.')}
              </span>
            </div>

            <div
              className={`loadingTextCurrent animating-${animationIndex} ${textClass || ''}`}
              style={textStyle}
            >
              {currentLabel}
              <span className="loadingTextDots">
                {Array(this.state.dots).join('.')}
              </span>
            </div>
          </div>
        </span>
      </span>
    );
  }
}

LoadingText.propTypes = {
  textClass: PropTypes.string,
  textStyle: PropTypes.object,
}

export default LoadingText;
