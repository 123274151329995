/* eslint-disable react-hooks/exhaustive-deps */
import { OtherActions, HubActions, EventActions } from "../../store/actions";
import { useWindowSize } from "../../hooks";
import { scrollToElem } from "@/utils/interfaceHelpers.js";

import React, { useState, useEffect, useRef } from "react";
import Sticky from "react-stickynode";

import { useTranslation } from "react-i18next";

import ListGroup from "react-bootstrap/ListGroup";

import { FilterMultiSelect } from "../../components/FilterMultiSelect";
import { FilterDateRange } from "../../components/FilterDateRange";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";

import Checkbox from "@salesforce/design-system-react/components/checkbox";

import ResizeObserver from "resize-observer-polyfill";

import "./FilterBar.scss";

import { ReactComponent as ShareIcon } from "../../assets/images/icon_share.svg";

const { toggleShareModal } = OtherActions;
const { updateHubEventFilters, toggleHubPremierExclude } = HubActions;
const { updateEventFilters, togglePremierExclude } = EventActions;

const FilterBar = ({
  appConfig,
  eventFilters,
  hubFilters,
  hubFilterLocks,
  excludePremier,
  tb_user_plan,
  filterMode,
  isPlp,
  hubId,
  language,
  locale,
  timezone,
  dispatch,
}) => {
  /* sidebar resize */
  const sidebarDomRef = useRef(null); // needed for resize observer
  const stickyRef = useRef(null); // needed to access sticky instance
  const sidebarResizeObserverRef = useRef({}); // needed to remove observer when component is unloaded

  const [offset, setOffset] = useState(false);

  //  update sticky instance when height changes
  const updateSticky = () => {
    const stickyInstance = stickyRef.current;

    if (stickyInstance === null) {
      return;
    }

    stickyInstance.updateInitialDimension();
    stickyInstance.update();
  };

  const updateOffset = () => {
    if (window.pageYOffset > 0) {
      setOffset(true);
      window.removeEventListener("scroll", updateOffset);
    } else {
      setOffset(false);
    }
  };

  // observe sidebar height changes
  useEffect(() => {
    const sidebarDomElement = sidebarDomRef.current;

    window.addEventListener("scroll", updateOffset, false);

    sidebarResizeObserverRef.current = new ResizeObserver((entries) => {
      // ignore other sidebar observers (not strictly neccessary)
      const sidebarObserverResults = entries.find(
        ({ target }) => target === sidebarDomElement
      );

      if (typeof sidebarObserverResults === "undefined") {
        return;
      }

      updateSticky();
    });

    if (sidebarDomElement === null) {
      return;
    }

    sidebarResizeObserverRef.current.observe(sidebarDomElement);

    // cleanup when components is unloaded
    return () => {
      sidebarResizeObserverRef.current.disconnect();
      window.removeEventListener("scroll", updateOffset);
    };
  }, [filterMode]);

  const mobileBreak = 991;
  const tabletBreak = 1200;

  const [isExpanded, setIsExpanded] = useState(false);
  const decoratedOnClick = () => {
    if (windowWidth <= mobileBreak) {
      setIsExpanded(!isExpanded);
    }
  };

  const filterChange = (inputkey, value) => {
    // console.log(inputkey)
    // console.log(value)
    if (filterMode === "hub") {
      let filterObject = { key: inputkey, values: value, hubId: hubId };
      dispatch(updateHubEventFilters(filterObject));
    } else {
      let filterObject = { key: inputkey, values: value };
      dispatch(updateEventFilters(filterObject));
    }

    if (isPlp) scrollToElem("filterContentStart");
    if (windowWidth > mobileBreak) {
      if (!isPlp) scrollToElem("resultsHeader");
    }
  };

  const showShareModal = () => {
    let modalPayload = { show: true };
    dispatch(toggleShareModal(modalPayload));
  };

  // display and disable event type filters that are premier only
  // for standard/guest users
  const premierTypes =
    tb_user_plan && (tb_user_plan === "signature" || tb_user_plan === "premier")
      ? []
      : appConfig.premierTypes;

  const filters = filterMode === "hub" ? hubFilters[hubId] : eventFilters;

  const premierExcludeChange = (event, checked) => {
    if (filterMode === "hub") {
      let filterObject = {
        excludePremier: !excludePremier,
        hubId: hubId,
      };
      dispatch(toggleHubPremierExclude(filterObject));
    } else {
      let filterObject = { excludePremier: !excludePremier };
      dispatch(togglePremierExclude(filterObject));
    }

    // if there are type filters and we are excluding premier events
    if (filters.type.length && !excludePremier) {
      //console.log("removing premier types");
      if (premierTypes.length) {
        let eventFilterTypeArr = [...filters.type];
        let eventFilterUpdated = eventFilterTypeArr.filter(
          (typeFilter) => !premierTypes.includes(typeFilter)
        );
        //console.log("updated filters");
        //console.log(eventFilterUpdated);

        if (filterMode === "hub") {
          let filterObject = {
            key: "type",
            values: eventFilterUpdated,
            hubId: hubId,
          };
          dispatch(updateHubEventFilters(filterObject));
        } else {
          let filterObject = { key: "type", values: eventFilterUpdated };
          dispatch(updateEventFilters(filterObject));
        }
      }
      //console.log("original filters");
      //console.log(filters.type);
    }

    if (windowWidth > mobileBreak) scrollToElem("resultsHeader");
  };

  const { t } = useTranslation();

  const [windowWidth /*, windowHeight*/] = useWindowSize();

  // Preselect filters based on user language
  // useEffect(() => {
  //   if (language && filterMode === "hub" && hubId) {
  //     // TODO: filter is applied immediately and changes product behavior
  //     dispatch(updateHubEventFilters({ key: "language", values: [language], hubId }));
  //   } else if (language && filterMode !== "hub") {
  //     // TODO: Always applied
  //     dispatch(updateEventFilters({ key: "language", values: [language] }));
  //   }
  // }, [language, filterMode, hubId, dispatch]);

  return (
    <>
      <Sticky
        enabled={windowWidth > mobileBreak && offset}
        top={windowWidth > tabletBreak ? 175 : 130}
        ref={stickyRef}
        bottomBoundary="#filterListEnd"
      >
        <div className="colPanel filterBar" ref={sidebarDomRef}>
          <Accordion
            activeKey={isExpanded || windowWidth > mobileBreak ? "1" : "0"}
            className={
              isExpanded || windowWidth > mobileBreak ? "expanded" : "collapsed"
            }
          >
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              onClick={decoratedOnClick}
              className="filterBarToggleBtn"
            >
              <h4>{t("Filter Results")}</h4>
            </Accordion.Toggle>
            <Button
              variant="secondary"
              className="shareButton "
              onClick={showShareModal}
            >
              {t("Share this View")} <ShareIcon />
            </Button>
            <Button
              onClick={showShareModal}
              variant="link"
              className="shareButtonInline"
            >
              {t("Share this View")}
            </Button>
            <Accordion.Collapse eventKey="1">
              <ListGroup>
                {((hubFilters[hubId] && !hubFilterLocks.includes("product")) ||
                  filterMode !== "hub") && (
                  <ListGroup.Item className="filterBarGroup">
                    <FilterMultiSelect
                      key="product-select"
                      inputkey="product"
                      title={appConfig.filters.product.title}
                      options={appConfig.filters.product.options}
                      selected={filters.product}
                      language={language}
                      changeCallback={filterChange}
                    />
                  </ListGroup.Item>
                )}
                {/*
                {((hubFilters[hubId] && !hubFilterLocks.includes("category")) ||
                  filterMode !== "hub") && (
                  <ListGroup.Item className="filterBarGroup">
                    <FilterMultiSelect
                      key="category-select"
                      inputkey="category"
                      title={appConfig.filters.category.title}
                      options={appConfig.filters.category.options}
                      selected={filters.category}
                      language={language}
                      changeCallback={filterChange}
                    />
                  </ListGroup.Item>
                  )}*/}
                {((hubFilters[hubId] && !hubFilterLocks.includes("type")) ||
                  filterMode !== "hub") && (
                  <ListGroup.Item className="filterBarGroup">
                    <FilterMultiSelect
                      key="type-select"
                      inputkey="type"
                      title={appConfig.filters.eventType.title}
                      options={appConfig.filters.eventType.options}
                      selected={filters.type}
                      language={language}
                      premierTypes={premierTypes}
                      excludePremier={excludePremier}
                      changeCallback={filterChange}
                    />
                  </ListGroup.Item>
                )}
                <ListGroup.Item className="filterBarGroup">
                  {(hubFilters[hubId] || filterMode !== "hub") && (
                    <FilterDateRange
                      title="Date Range"
                      expanded={false}
                      selectedStartDate={filters.startDate}
                      selectedEndDate={filters.endDate}
                      language={language}
                      locale={locale}
                      timezone={timezone}
                      changeCallback={filterChange}
                      isMobile={windowWidth <= mobileBreak}
                    />
                  )}
                </ListGroup.Item>
                {((hubFilters[hubId] && !hubFilterLocks.includes("language")) || filterMode !== "hub") && (
                  <ListGroup.Item className="filterBarGroup">
                    <FilterMultiSelect
                      inputkey="language"
                      title={appConfig.filters.eventLanguage.title}
                      options={appConfig.filters.eventLanguage.options}
                      selected={filters.language}
                      language={language}
                      changeCallback={filterChange}
                    />
                  </ListGroup.Item>
                )}
                {((hubFilters[hubId] && !hubFilterLocks.includes("region")) ||
                    filterMode !== "hub") && (
                  <ListGroup.Item className="filterBarGroup lastGroup">
                    <FilterMultiSelect
                      inputkey="region"
                      title={appConfig.filters.region.title}
                      options={appConfig.filters.region.options}
                      selected={filters.region}
                      language={language}
                      changeCallback={filterChange}
                    />
                  </ListGroup.Item>
                )}
                {(!tb_user_plan ||
                  (tb_user_plan && tb_user_plan === "standard")) && (
                  <ListGroup.Item className="filterBarGroup toggleGroup">
                    <Checkbox
                      labels={{
                        label: t("Premier Exclude Toggle CTA Text"),
                      }}
                      id="toggle-exclude-premier"
                      variant="toggle"
                      className="filterToggleExcludePremier"
                      onChange={premierExcludeChange}
                      checked={excludePremier}
                      size="medium"
                    />
                    <a
                      className="toggleInfoLabel"
                      href={`https://successcenter.salesforce.com/s/product?name=success-salesforce_success_plans&utm_source=success_events&utm_medium=${
                        tb_user_plan && tb_user_plan === "standard"
                          ? "standard"
                          : "unkwn"
                      }_hideprem_toggle`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Premier Exclude Toggle Info Link Text")}
                    </a>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </Sticky>
    </>
  );
};

export default FilterBar;
