import { connect } from "react-redux";
import { Events } from "../pages/Events";

const mapStateToProps = (state, ownProps) => {
  return {
    eventList: state.csUser.registrations.previous,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    title: "User dashboard Your Past Attended Events",
    showBtns: false,
    showLikes: false,
    isShowing: Boolean(state.csUser.registrations.previous.length),
    pastTotalPages: state.csUser.pastTotalPages,
    currentPastTotal: state.csUser.pastCounter,
    type: "past",
    timezone: state.csUser.timezone,
    language: state.csUser.language,
  };
};

const PastEventsContainer = connect(mapStateToProps)(Events);

export default PastEventsContainer;
