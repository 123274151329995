/* eslint-disable react-hooks/exhaustive-deps */
import { HubActions, EventActions, UserActions } from "../../store/actions";
import { useFilterLinkConfig, useTextTruncate } from "../../hooks/";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { Event } from "../../components/Event";
import { FilterPills } from "../../components/FilterPills";

import Spinner from "@salesforce/design-system-react/components/spinner";

import "./EventList.scss";

const { loadMoreEvents, updateEventFilters, updateEventFiltersSilent } =
  EventActions;

const {
  loadMoreHubEvents,
  updateHubEventFilters,
  updateHubEventFiltersSilent,
  clearHubEventFilters,
} = HubActions;
const { getUserFavoritesAll } = UserActions;

const EventList = ({
  eventList,
  eventFilters,
  hubFilters,
  hubId,
  filterMode,
  language,
  timezone,
  locale,
  isLoading,
  numEvents,
  numPages,
  tb_id,
  tb_user_plan,
  favorites,
  listLoading,
  hubListLoading,
  hubLoading,
  filtersActive,
  dispatch,
}) => {
  const filterConfigParams = useFilterLinkConfig();

  useEffect(() => {
    if (tb_id && !hasFavoritesInit) {
      dispatch(getUserFavoritesAll({ tb_id }));
      setHasFavoritesInit(true);
    }
  });

  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);

  const { t } = useTranslation();
  const favoritesIds = favorites ? favorites : [];

  const checkFavorite = (eventId) => {
    return favoritesIds.includes(eventId);
  };
  const loadNext = () => {
    if (!isLoading) {
      if (filterMode === "hub") {
        dispatch(loadMoreHubEvents({ hubId: hubId }));
      } else {
        dispatch(loadMoreEvents());
      }
    }
  };

  const clearAllHandler = (key, value) => {
    //console.log("running clear all");
    if (filterMode === "hub") {
      let filterObject = { hubId: hubId };
      //console.log(filterObject);
      dispatch(clearHubEventFilters(filterObject));
    }
  };

  const pillRemoveHandler = (key, value) => {
    const updatedFilter = filters[key].filter((item) => item !== value);

    if (filterMode === "hub") {
      let filterObject = { key: key, values: updatedFilter, hubId: hubId };
      dispatch(updateHubEventFilters(filterObject));
    } else {
      let filterObject = { key: key, values: updatedFilter };
      dispatch(updateEventFilters(filterObject));
    }
  };

  const pillRemoveHandlerDates = () => {
    if (filterMode === "hub") {
      console.log("removing hub event");
      const filterObjectStart = {
        key: "startDate",
        values: null,
        hubId: hubId,
      };
      dispatch(updateHubEventFiltersSilent(filterObjectStart));
      const filterObjectEnd = { key: "endDate", values: null, hubId: hubId };
      dispatch(updateHubEventFiltersSilent(filterObjectEnd));
      const filterObjectSpan = {
        key: "dateSpan",
        values: "custom",
        hubId: hubId,
      };
      dispatch(updateHubEventFilters(filterObjectSpan));
    } else {
      const filterObjectStart = { key: "startDate", values: null };
      dispatch(updateEventFiltersSilent(filterObjectStart));
      const filterObjectEnd = { key: "endDate", values: null };
      dispatch(updateEventFiltersSilent(filterObjectEnd));
      const filterObjectSpan = { key: "dateSpan", values: "custom" };
      dispatch(updateEventFilters(filterObjectSpan));
    }
  };

  const searchTruncated = useTextTruncate(eventFilters.search);

  const filters = filterMode === "hub" ? hubFilters[hubId] : eventFilters;

  //console.log(`list loading ${hubListLoading}`);

  return (
    <div className="colPanel" id="resultsHeader">
      <div>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <div className="resultsLabel">
              {!listLoading ? (
                <>
                  <span className="resultsCount">
                    {numEvents} {numEvents !== 1 ? t("Results") : t("Result")}
                  </span>
                  {filterMode !== "hub" &&
                    filters.search &&
                    " " + t("for") + ' "' + searchTruncated + '"'}
                </>
              ) : (
                <>{t("Loading Events...")}</>
              )}
              {filtersActive && (
                <button
                  type="button"
                  className="subLink btn btn-link"
                  onClick={() => {
                    clearAllHandler();
                  }}
                >
                  {t("Clear All Filters")}
                </button>
              )}
              {!hubId && (
                <Link
                  to={{
                    pathname: "/events",
                    search: filterConfigParams,
                  }}
                >
                  <span className="subLink">{t("Clear All Filters")}</span>
                </Link>
              )}
            </div>
            {filters && (
              <FilterPills
                eventFilters={filters}
                language={language}
                locale={locale}
                timezone={timezone}
                removeCallback={pillRemoveHandler}
                removeCallbackDates={pillRemoveHandlerDates}
              />
            )}
          </Col>
        </Row>
        <Row className="eventGridRow">
          {listLoading && (
            <div className="spinnerContainer">
              <Spinner
                variant="brand"
                size="medium"
                assistiveText={{ label: "Event List Loading..." }}
              />
            </div>
          )}
          {eventList &&
            eventList.map((event, index) => (
              <Col xs={12} md={6} lg={6} xl={6} key={"event-index-" + index}>
                <Event
                  event={event}
                  key={event.id}
                  {...event}
                  tb_id={tb_id}
                  tb_user_plan={tb_user_plan}
                  isFavorite={() => checkFavorite(event.id)}
                />
              </Col>
            ))}
        </Row>
        {numEvents > 0 &&
          !isLoading &&
          !listLoading &&
          !hubListLoading &&
          !hubLoading && (
            <>
              {numEvents > eventList.length ? (
                <Row>
                  <Col
                    xs={12}
                    md={{ span: 4, offset: 4 }}
                    className="text-center"
                  >
                    <Button className="loadMoreButton" onClick={loadNext}>
                      {t("Load More Events")}
                    </Button>
                  </Col>
                  <Col
                    xs={12}
                    md={{ span: 4 }}
                    lg={12}
                    xl={{ span: 4 }}
                    className="text-right"
                  >
                    <p className="loadStatus">
                      {t("Events Loaded")}: <strong>{eventList.length}</strong>{" "}
                      of <strong>{numEvents}</strong>
                    </p>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col
                    xs={12}
                    md={{ span: 6, offset: 6 }}
                    lg={{ span: 12, offset: 0 }}
                    className="text-right"
                  >
                    <p className="loadStatus">
                      {t("All Events Loaded")}:{" "}
                      <strong>{eventList.length}</strong> {t("of")}{" "}
                      <strong>{numEvents}</strong>
                    </p>
                  </Col>
                </Row>
              )}
            </>
          )}
        {numPages > 1 &&
          (isLoading || listLoading || hubListLoading || hubLoading) && (
            <Row>
              <Col xs={12} md={{ span: 4, offset: 4 }} className="text-center">
                <Button className="loadMoreButton" disabled>
                  {t("Loading...")}{" "}
                </Button>
              </Col>
              <Col
                xs={12}
                md={{ span: 4 }}
                lg={12}
                xl={{ span: 4 }}
                className="text-center"
              >
                <p className="loadStatus">
                  {t("Events Loaded")}: <strong>{eventList.length}</strong>{" "}
                  {t("of")} <strong>{numEvents}</strong>
                </p>
              </Col>
            </Row>
          )}
      </div>
      <div id="filterListEnd"></div>
    </div>
  );
};

EventList.propTypes = {
  eventList: PropTypes.array,
  eventFilters: PropTypes.object,
  isLoading: PropTypes.bool,
  numEvents: PropTypes.number,
};

export default EventList;
