import React from "react";
import { Jumbotron } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import LoadingText from "../../components/LoadingText";
import LoginBanner from "../../components/LoginBanner";

import "./LoginPage.scss";

export const LoginPage = ({ errorType }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("Signing In")}</title>
      </Helmet>
      <div>
        <div className="loginPageWrapper">
          <LoginBanner>
            <Jumbotron>
              <>
                <h1>{t("Login Page loading Title")}</h1>
                <p className="loginPageP">
                  {t("Login Page loading Description")}
                </p>
                <div style={{ marginTop: 30 }}>
                  <LoadingText
                    labels={[
                      t("Login Page loading Label 1"),
                      t("Login Page loading Label 2"),
                      t("Login Page loading Label 3"),
                      t("Login Page loading Label 4"),
                      t("Login Page loading Label 5"),
                    ]}
                  />
                </div>
              </>
            </Jumbotron>
          </LoginBanner>
        </div>
      </div>
    </>
  );
};
