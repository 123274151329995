import { call, put, select } from "redux-saga/effects";
import { API_BASE_DOMAIN, IS_LOCAL } from "../../config/environment";
import { OtherActions, UserActions } from "../actions";

import { clear, load, save } from "../../utils/storage";
import { CS_USER_STORAGE_KEY } from "../../utils/storage/keys";

const { SET_CRITICAL_ERROR, TOGGLE_AUTH_MODAL } = OtherActions;

const { SET_USER_INFO, SET_USER_API_TOKEN } = UserActions;

export const getUserData = (state) => state.csUser;

let api_base_endpoint = IS_LOCAL
  ? "https://localhost:5000"
  : `https://${API_BASE_DOMAIN}`;

const getEndpointByPath = (path) => {
  return api_base_endpoint + `/v1/${path}`;
};

const getFetchParams = (method = "POST", token, data) => {
  const contentType = { "Content-Type": "application/json" };
  const cacheControl = { "Cache-Control": "no-cache, no-store" };
  const authHeader = { Authorization: token };
  const headers = token
    ? {
        ...contentType,
        ...cacheControl,
        ...authHeader,
      }
    : { ...contentType };
  const body = data && JSON.stringify(data);
  const params = body ? { method, headers, body } : { method, headers };
  return params;
};

// warning - providing anything other than POST will fail miserably
export function* csFetch(path, method, data, silentServerError = false) {
  let userData = yield select(getUserData);
  let token =
    userData && userData.apiToken ? `Bearer ${userData.apiToken}` : null;
  //console.log(`setting token in request for user: ${token}`);
  try {
    const response = yield call(
      fetch,
      getEndpointByPath(path),
      getFetchParams(method, token, data)
    );
    const status = yield response.status;
    const headers = yield response.headers;
    const respData = yield response.json();
    if (status === 200) {
      yield put({ type: SET_CRITICAL_ERROR, message: "" });
      // check for token refresh
      const refreshToken = headers.get("x-cal-api-token-refresh");
      if (refreshToken) {
        //console.log(`received refresh token ${refreshToken}`);
        yield put({
          type: SET_USER_API_TOKEN,
          payload: {
            token: refreshToken,
          },
        });
        // set local storage
        let currentUser = yield call(load, CS_USER_STORAGE_KEY);
        currentUser["api_token"] = refreshToken;
        yield call(save, CS_USER_STORAGE_KEY, currentUser);
      }
    } else if (status === 500) {
      if (!silentServerError)
        yield put({ type: SET_CRITICAL_ERROR, message: respData.error });
    } else if (status === 401) {
      //console.log("returning 401");
      //console.log(respData);
      yield put({
        type: TOGGLE_AUTH_MODAL,
        payload: {
          show: true,
          message: respData.error,
        },
      });
      // force logout test
      console.log("401 logout");
      yield put({
        type: SET_USER_INFO,
      });
      clear();
    } else if (status !== 404 && status !== 400) {
      throw yield response.json();
    }
    return {
      status,
      data: respData,
    };
  } catch (error) {
    console.log(error);
    yield put({ type: SET_CRITICAL_ERROR, message: "Fetch error." });
  }
}

export default csFetch;
