/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EventActions, UserActions } from "@/store/actions";
import EventForm from "@/components/EventForm";
import { getStateOptions } from "@/components/EventForm/helpers";
import EventComplete from "@/components/EventComplete";
import {
  EMPLOYEES,
  COUNTRIES,
  COUNTRIES_W_STATE,
  COUNTRIES_W_DISCLAIMER,
} from "@/components/EventForm/constants";
import { useFilterLinkConfig } from "@/hooks";
import { changeRecaptchaError, scrollToElement } from "./helpers";
import { sendCoveoCustomEvent } from "@/utils/analyticsHelper";
import "./EventRegistration.scss";

const { setRegistrationEventInfo } = EventActions;
const { setUserInfo } = UserActions;

export const EventRegistration = ({
  share,
  user,
  tbidUser,
  eventDetail,
  isRegistrationComplete,
  isRegistrationFailed,
  trailblazerLogin,
  dispatch,
}) => {
  const filterConfigParams = useFilterLinkConfig();
  const { t } = useTranslation();

  const { register, handleSubmit, formState, reset, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: false,
  });
  const [selectedEmployees, setSelectedEmployeeValue] = useState(null);
  const [selectedCountry, setSelectedCountryValue] = useState(null);
  const [selectedState, setSelectedStateValue] = useState(null);
  const [isRecaptchaValid, setRecaptchaValidate] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [userData, setUserData] = useState({});
  const [inputHandled, setInputHandled] = useState(false);
  const [eventFormKey, setEventFormKey] = useState("event-form");

  useEffect(() => {
    if (user && Object.entries(user).length > 0) {
      const employeeOption = EMPLOYEES.find((c) => user.employees === c.value);
      const countryOption = COUNTRIES.find((c) => user.country === c.value);
      const stateOptions = getStateOptions({ value: user.country });
      const stateOption = stateOptions
        ? stateOptions.find((c) => user.state === c.value)
        : null;

      const selects = [
        {
          type: "employees",
          el: document.querySelector(".select-employees"),
          value: (user && user.employees) || "Employees",
        },
        {
          type: "countries",
          el: document.querySelector(".select-countries"),
          value: (user && user.country) || "Country",
        },
      ];

      // Copy initial user data
      setSelectedEmployeeValue({
        value: employeeOption ? employeeOption.value : null,
        isError: !employeeOption ? true : false,
      });
      setSelectedCountryValue({
        value: countryOption ? countryOption.value : null,
        isError: !countryOption ? true : false,
      });
      setSelectedStateValue({
        value: user.state,
        isError: stateOptions && !stateOption ? true : false,
      });
      setUserData({
        ...user,
        employees: employeeOption ? employeeOption.value : null,
        country: countryOption ? countryOption.value : null,
        state: stateOption ? stateOption.value : null,
      });

      if (employeeOption || countryOption) {
        presetSelectValues(selects, false, stateOptions);
      } else {
        presetSelectValues(selects, true, stateOptions);
      }
    } else {
      resetForm();
    }
  }, [user]);

  useEffect(() => {
    if (isRegistrationComplete || isRegistrationFailed)
      scrollToElement(".eventDetailCard");
    if (isRegistrationComplete || isRegistrationFailed)
      setSubmitDisabled(false);

    // Test initial validation
    if (
      user &&
      Object.entries(user).length > 0 &&
      !isEditable &&
      !isRegistrationComplete
    ) {
      setTimeout(() => {
        handleSubmit(() => console.log("ok"))();
      });
    }
  }, [user, isRegistrationComplete, isRegistrationFailed, isEditable]);

  const presetSelectValues = (parents, isClean, stateOptions) => {
    const children = document.querySelectorAll("[class$=placeholder]");

    children.forEach((child) => {
      parents.forEach((parent) => {
        if (parent.el.contains(child)) {
          let input = parent.el.firstChild;
          let text = input.children[0].children[0];
          let value;
          if (parent.type === "countries") {
            text.style.color = "#222222";
            /*input.style.backgroundColor =
              isClean || !user.country ? "white" : "#eaf5fe";*/
            const countryOption = COUNTRIES.find(
              (c) => user?.country === c.value
            );

            value = isClean || !user.country ? "Country" : countryOption?.label;
          } else if (parent.type === "employees") {
            text.style.color = "#222222";
            /*input.style.backgroundColor =
              isClean || !user.employees ? "white" : "#eaf5fe";*/
            const employeesOption = EMPLOYEES.find(
              (c) => user?.employees === c.value
            );

            value =
              isClean || !user.employees ? "Employees" : employeesOption?.label;
          } else if (parent.type === "state") {
            text.style.color = "#222222";
            /*input.style.backgroundColor =
              isClean || !user.state ? "white" : "#eaf5fe";*/
            const stateOption = stateOptions.find(
              (c) => user?.state === c.value
            );

            value = isClean || !user.state ? "State" : stateOption?.label;
          }

          child.innerHTML = value;
        }
      });
    });
  };

  const resetForm = () => {
    reset();
    setUserData({});
    setSelectedEmployeeValue(null);
    setSelectedCountryValue(null);
    changeRecaptchaError("remove");
    setRecaptchaValidate(false);
    setEventFormKey("event-form-refresh");
  };

  const isSelectValid = useMemo(() => {
    return selectedCountry &&
      selectedEmployees &&
      !selectedCountry.isError &&
      !selectedState?.isError &&
      !selectedEmployees.isError &&
      selectedCountry.value &&
      selectedEmployees.value
      ? true
      : false;
  }, [selectedCountry, selectedState, selectedEmployees]);

  const handleSubmitSuccess = (data) => {
    const hasErrors = checkAdditionalErrorFields(!tbidUser);

    console.log("handle submit success");

    const countryOption = COUNTRIES.find(
      (c) => c.value === selectedCountry.value
    );
    const hasOptIn =
      countryOption &&
      countryOption.hasOwnProperty("optIn") &&
      countryOption.optIn;
    const needsDisclaimer =
      selectedCountry && COUNTRIES_W_DISCLAIMER.includes(selectedCountry.value);

    /*console.log("hasErrors");
    console.log(hasErrors);
    console.log("submitDisabled");
    console.log(submitDisabled);
    console.log("isSelectValid");
    console.log(isSelectValid);
    console.log("tbidUser");
    console.log(tbidUser);*/
    if (
      !hasErrors &&
      !submitDisabled &&
      isSelectValid &&
      (isRecaptchaValid || tbidUser)
    ) {
      const registrationData = {
        ...data,
        employees: selectedEmployees?.value,
        country: selectedCountry?.value,
        state: selectedState?.value,
        disclaimer: needsDisclaimer ? data.disclaimer : null,
        marketing_opt_in: hasOptIn ? data.marketing_opt_in : null,
        tb_id: userData.tb_id ? userData.tb_id : null,
      };

      // log registration event to coveo
      sendCoveoCustomEvent(
        "userEventRegistration",
        "createRegistration",
        eventDetail
      );

      setSubmitDisabled(true);
      dispatch(
        setRegistrationEventInfo({
          registrationData,
          id: eventDetail.id,
        })
      );
      dispatch(setUserInfo(userData));
    }
  };

  const handleSubmitError = (errors) => {
    if (errors.disclaimer) {
      const el = document.querySelector("#disclaimer-text");
      el.classList.add("error-text");
    }
    checkAdditionalErrorFields();
  };

  const checkAdditionalErrorFields = (userSubmit = true) => {
    let hasError = false;

    if (!isRecaptchaValid && userSubmit) {
      changeRecaptchaError("add");
      hasError = true;
    }
    if (!userData.employees) {
      setSelectedEmployeeValue({
        ...selectedEmployees,
        isError: true,
      });
      hasError = true;
    }
    if (!userData.country) {
      setSelectedCountryValue({
        ...selectedCountry,
        isError: true,
      });
      hasError = true;
    }
    if (
      selectedCountry &&
      COUNTRIES_W_STATE.includes(selectedCountry.value) &&
      !userData.state
    ) {
      setSelectedStateValue({
        ...selectedState,
        isError: true,
      });
      hasError = true;
    }

    if (
      selectedCountry &&
      COUNTRIES_W_DISCLAIMER.includes(selectedCountry.value) &&
      !userData.disclaimer
    ) {
      hasError = true;
    }

    return hasError;
  };

  const onEmployeeSelect = useCallback(
    (value) => {
      setSelectedEmployeeValue({ isError: false, value });
      setUserData({ ...userData, employees: value });
    },
    [userData]
  );

  const onCountrySelect = useCallback(
    (value) => {
      setSelectedCountryValue({ isError: false, value });
      setSelectedStateValue(null);
      setUserData({ ...userData, country: value, state: null });
    },
    [userData]
  );

  const onStateSelect = useCallback(
    (value) => {
      setSelectedStateValue({ isError: false, value });
      setUserData({ ...userData, state: value });
    },
    [userData]
  );

  const validateRecaptcha = useCallback((token) => {
    if (token) {
      changeRecaptchaError("remove");
      setRecaptchaValidate(true);
    } else {
      changeRecaptchaError("add");
      setRecaptchaValidate(false);
    }
  }, []);

  const onChangeFields = useCallback(
    (e) => {
      setInputHandled(true);
      if (!isEditable) setEditable(true);

      if (e.target.name === "disclaimer") {
        const el = document.querySelector(".form-text");
        e.target.value && el.classList.remove("error-text");
      }

      if (e.target.name) {
        setUserData({ ...userData, [e.target.name]: e.target.value });
      }
    },
    [userData, isEditable]
  );

  const registrationStatus = useMemo(() => {
    if (isRegistrationFailed) return { registration_status: "Failed" };

    return null;
  }, [isRegistrationFailed]);

  return isRegistrationComplete || isRegistrationFailed ? (
    <EventComplete
      share={share}
      registrationStatus={registrationStatus}
      filterConfigParams={filterConfigParams}
      eventDetail={eventDetail}
      tbidUser={tbidUser}
      t={t}
    />
  ) : (
    <EventForm
      key={eventFormKey}
      onChangeFields={onChangeFields}
      user={userData}
      storeUser={user}
      tbidUser={tbidUser}
      selectedEmployees={selectedEmployees}
      selectedCountry={selectedCountry}
      selectedState={selectedState}
      handleSubmit={handleSubmit}
      onSubmit={handleSubmitSuccess}
      onSubmitError={handleSubmitError}
      trailblazerLogin={trailblazerLogin}
      onEmployeeSelect={onEmployeeSelect}
      onCountrySelect={onCountrySelect}
      onStateSelect={onStateSelect}
      validateRecaptcha={validateRecaptcha}
      inputHandled={inputHandled}
      formState={formState}
      errorsFields={errors}
      register={register}
      t={t}
    />
  );
};
