import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Moment from "react-moment";

import { useTranslation } from "react-i18next";
import { ReactComponent as PrivacyOpt } from "../../assets/images/privacy-options.svg";

import "./Footer.scss";

const Footer = ({ eventFilters, removeCallback }) => {
  const { t } = useTranslation();

  return (
    <div className="appFooter">
      <Container>
        <Row>
          <Col>
            {"\u00A9"} {t("Copyright")} <Moment format="YYYY" />{" "}
            {t("Salesforce, Inc.")}{" "}
            <a
              href="https://www.salesforce.com/company/legal/intellectual/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("All Rights Reserved")}.{" "}
            </a>
            {t(
              "Various trademarks held by their respective owners. Salesforce, Inc. Salesforce Tower, 415 Mission Street, 3rd Floor, San Francisco, CA 94105, United States"
            )}
            <nav aria-label="Footer" className="page-footer_links mobile-display">
              <ul className="page-footer_links_list">
                <li className="page-footer_links_item">
                  <a
                    className="page-footer_link "
                    href="https://www.salesforce.com/company/legal/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Legal")}
                  </a>
                </li>
                <li className="page-footer_links_item">
                  <a
                    className="page-footer_link "
                    href="https://www.salesforce.com/company/legal/sfdc-website-terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Terms of Service")}
                  </a>
                </li>
                <li className="page-footer_links_item">
                  <a
                    className="page-footer_link "
                    href="https://www.salesforce.com/company/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Privacy Information")}
                  </a>
                </li>
                <li className="page-footer_links_item">
                  <a
                    className="page-footer_link "
                    href="https://www.salesforce.com/company/disclosure/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Responsible Disclosure")}
                  </a>
                </li>
                <li className="page-footer_links_item">
                  <a
                    className="page-footer_link "
                    href="https://trust.salesforce.com/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Trust")}
                  </a>
                </li>
                <li className="page-footer_links_item">
                  <a
                    className="page-footer_link "
                    href="https://www.salesforce.com/company/contact-us/?d=cta-glob-footer-11"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Contact")}
                  </a>
                </li>
                <li className="page-footer_links_item">
                  <a className="page-footer_link optanon-toggle-display" href="/#"
                      data-ignore-geolocation="true">Cookie Preferences</a>
                </li>
                <li className="page-footer_links_item">
                        
                      <a className="page-footer_link" href="https://www.salesforce.com/form/other/privacy-request/" 
                        target="_blank" rel="noopener noreferrer">
                        <div className="footer-link-icon">
                          <PrivacyOpt />
                        </div>
                        Your Privacy Choices
                      </a>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
