export const TOGGLE_SHARE_MODAL = "TOGGLE_SHARE_MODAL";
export const SET_CRITICAL_ERROR = "SET_CRITICAL_ERROR";
export const TOGGLE_AUTH_MODAL = "TOGGLE_AUTH_MODAL";

export function toggleShareModal(payload) {
  return { type: TOGGLE_SHARE_MODAL, payload };
}

export function toggleAuthModal(payload) {
  return { type: TOGGLE_AUTH_MODAL, payload };
}

export function setCriticalError(payload) {
  return { type: SET_CRITICAL_ERROR, payload };
}
