import React from "react";
import { VidyardPlayer } from "../components/Video";

const VideoController = ({ embed_id, embed_type, user, onVideoLoad }) => {
  let VideoComponent = null;
  const user_email = user && user.email ? user.email : "";
  switch (embed_type) {
    case "vidyard": {
      VideoComponent = (
        <VidyardPlayer
          uuid={embed_id}
          aspect="16:9"
          email={user_email}
          onLoadCallback={onVideoLoad}
        />
      );
      break;
    }
    default: {
      VideoComponent = (
        <VidyardPlayer
          uuid={embed_id}
          aspect="16:9"
          email={user_email}
          onLoadCallback={onVideoLoad}
        />
      );
      break;
    }
  }
  return VideoComponent;
};

export default VideoController;
